import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import $cs from '../../services.js'
import { IGInput } from "../../ignitor-react";

const InputCourse = (props) => {
    const { value, setValue, mode, skipCourse, evente, label, isRequired, error, maxSelections, selected=false, preSelections=[] } = props;
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [preCheck, setPreCheck] = useState(false);
    const [visibleList, setVisibleList] = useState(false);
    const [singleSelected, setSingleSelected] = useState(selected);
    const [courses, setCourses] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const [searchText, setSearchText] = useState(value?value.title:'');
    const [selectionList, setSelectionList] = useState([]);
    const [selectionID, setSelectionIDs] = useState([]);

    useEffect(() => {
        if(!preCheck && preSelections && preSelections.length > 0) {
            setPresetCourses();
        }
        if(!isLoading && searchResults === null && value===null) {
            setLoading(true);
            searchCourse();
        }
    });

    const setPresetCourses = () => {
        setPreCheck(true);
        $cs.course.create({
            resource: `${$cs.course.api}multiple/`,
            data: {
                ids: preSelections
            }
        }).then(res => {
            if(res.data && res.data.length>0) {
                if(mode==='INPUT') {
                    setValue(res.data[0]);
                    setSingleSelected(true);
                    setSearchText(`${res.data[0].parent_detail?res.data[0].parent_detail.topic+' - ':''}${res.data[0].title}`);
                } else {
                    const selectionIds = res.data.map(item => item.id);
                    setSelectionList(res.data);
                    setSelectionIDs(selectionIds);
                }
            }
        }, err => {
        });
    }

    useEffect(()=>{
        if(!singleSelected) {
            if(searchText && searchText.length > 0) {
                searchCourse();
                setVisibleList(true);
            } else {
                setVisibleList(false);
            }
        }
    }, [searchText]);

    useEffect(() => {
        if(evente === 'reset') {
            setSelectionList([]);
            setSelectionIDs([]);
            setSearchText('');
            setSearchResults(null);
            setValue([]);
        }
    }, [evente]);

    const searchCourse = () => {
        $cs.course.create({
            resource: `${$cs.course.api}search/`,
            data: {
                action: 'search',
                text: searchText
            }
        }).then(res => {
            setSearchResults(res.data);
            setLoading(false);
        }, err => {
        });
    };

    const setSelectionCourse = (course) => {
        setSelectionList([...selectionList, course]);
        setSelectionIDs([...selectionID, course.id]);
        setValue([...selectionID, course.id]);
        if(mode !== 'tags') {
            setSearchText('');
        }
    };

    const removeSelectionCourse = (course) => {
        let dupSelectionList = selectionList;
        let dupSelectionIDs = selectionID;
        let dupSelectionID = selectionID;
        dupSelectionList.splice(dupSelectionID.indexOf(course), 1);
        dupSelectionIDs.splice(dupSelectionID.indexOf(course.id), 1);
        setSelectionList(dupSelectionList);
        setSelectionIDs(dupSelectionIDs);
        setValue(dupSelectionID);
    };

    const renderCats = () => {
        if(isLoading && searchCourse === null) {
            return (
                <span>Loading...</span>
            )
        }
        return (
            <div className="form-group margin-0">
                <div className='ig-input-course' style={{position: 'relative'}}>
                    <IGInput type="search" label={label} disabled={maxSelections && selectionID.length>=maxSelections} value={searchText} isRequired={isRequired} error={error} setValue={(val) => {
                        setSingleSelected(false);
                        setSearchText(val);
                    }} placeholder="Search Course, Topic, Domain etc" style={{maxWidth: '100%'}} />
                    {
                        visibleList && (
                            <div className="search-results">
                                {
                                    mode==='INPUT' && <ul className="inline-list">
                                        {
                                            searchResults && searchResults.length > 0 && searchResults.map((course, index) => (
                                                <li key={index}>
                                                    <span onClick={()=>{
                                                        setVisibleList(false);
                                                        setValue(course);
                                                        setSingleSelected(true);
                                                        setSearchText(`${course.parent_detail?course.parent_detail.topic+' - ':''}${course.title}`);
                                                    }}>{course.parent_detail?course.parent_detail.topic+' - ':''}{course.title}</span>
                                                </li>
                                            ))
                                        }
                                        {
                                            searchResults && searchResults.length === 0 && (
                                                <li>
                                                    <span>No matching results</span>
                                                </li>
                                            )
                                        }
                                    </ul>
                                }
                                {
                                    mode === 'multiselect' && <ul className="inline-list">
                                        {
                                            searchResults && searchResults.length > 0 && searchResults.filter(course => selectionID.indexOf(course.id)===-1).map((course, index) => (
                                                <li key={index}>
                                                    <span onClick={()=>{
                                                        setSelectionCourse(course);
                                                        setVisibleList(false);
                                                    }}>{course.parent_detail?course.parent_detail.topic+' - ':''}{course.title}</span>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                }
                            </div>
                        )
                    }
                </div>
                {
                    mode==='multiselect' && selectionList && selectionList.length > 0 && (
                        <div className="search-results-tags margin-bottom-5">
                            <ul className="inline-list tags-list" style={{display: 'inline-block'}}>
                                {
                                    selectionList.map((course, index) => (
                                        <li className='selected' key={index}> <span className="fa fa-check"></span>
                                            &nbsp;{course.parent_detail?course.parent_detail.topic+' - ':''}{course.title} <span className="fa fa-times text-danger" onClick={()=>{
                                                removeSelectionCourse(course);
                                            }}></span>
                                        </li>
                                    ))
                                }
                            </ul>
                            <br />
                        </div>
                    )
                }
                {/* only for skill set */}
                {
                    mode === 'tags' && (
                        <div className="search-results-tags">
                            <div className="row margin-0">
                                <div className="col-12 pad-0">
                                    <ul className="inline-list tags-list">
                                        {
                                            selectionList.map((course, index) => (
                                                <li className='selected' key={index}> <span className="fa fa-check"></span>
                                                    <span onClick={()=>{
                                                        // setSelectionCourse(course);
                                                    }}>{course.parent_detail?course.parent_detail.topic+' - ':''}{course.title}</span>
                                                    <span className="fa fa-times text-danger margin-left-5" onClick={()=>{
                                                        removeSelectionCourse(course);
                                                    }}></span>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                {
                                    visibleList && (
                                        <div className="col-12 pad-0">
                                            <ul className="inline-list tags-list">
                                                {
                                                    searchResults && searchResults.length > 0 && searchResults.filter(course => (selectionID.indexOf(course.id)===-1 && skipCourse.indexOf(course.id)===-1)).map((course, index) => (
                                                        <li key={index}>+
                                                            <span onClick={()=>{
                                                                setSelectionCourse(course);
                                                            }}>{course.parent_detail?course.parent_detail.topic+' - ':''}{course.title}</span>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    )
                                }
                                {
                                    visibleList && searchResults && searchResults.length === 0 && (
                                        <div className="col-12 pad-top-10">
                                            <span>Didn't found the course? Ask admin to add the course by using <b>Request</b> option.</span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }

    return (
        <div style={{display: 'block'}}>
            {renderCats()}
        </div>
    )
};

export default InputCourse;
