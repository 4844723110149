import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import InputCourse from "./components/InputCourse";
import { IGInput } from "../ignitor-react";
import $cs from "../services.js";
import { useHistory, useParams } from "react-router-dom";
import { lookupsSelector, appSettingsSelector } from "../store/appReducer";
import {
  realTimeProfileSelector,
} from "../store/userReducer";
import UserCard from "./components/UserCard";

const BrowseTeachers = (props) => {
  const profile = useSelector(realTimeProfileSelector);
  const lookups = useSelector(lookupsSelector);
  const settings = useSelector(appSettingsSelector);
  const { enquiryId } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [tab, setTab] = useState("Teacher");
  const [course, setCourse] = useState(null);
  const [teacherList, setTeacherList] = useState(null);
  const [enquiresList, setEnquiriesList] = useState(null);
  const [pageMode, setPageMode] = useState(enquiryId ? "BROWSE" : "FIND");
  const [filter, setFilter] = useState({
    rating: null,
    location: null,
    favorites: false,
    mode: null,
    demo: null,
  });
  const [popupMode, setPopupMode] = useState(null);

  // pre checks
  const getPresetCourses = () => {
    const topic = new URLSearchParams(window.location.search).get("topic");
    if (topic) {
      return [topic];
    }
    return [];
  }

  // state handlers
  useEffect(() => {
    if (pageMode === "BROWSE" && enquiryId === undefined) {
      setTeacherList(null);
      setEnquiriesList(null);
      setPageMode(enquiryId ? "BROWSE" : "FIND");
    }
    if (enquiryId) {
      const action = new URLSearchParams(window.location.search).get("action");
      if (action) {
        appendAction(action);
      }
      getEnquiryDetail();
    } else if (course === null) {

    }
  }, enquiryId);

  useEffect(() => {
    if (isLoading && course === null && enquiryId) {
      const action = new URLSearchParams(window.location.search).get("action");
      if (action) {
        appendAction(action);
      }
      getEnquiryDetail();
    }
    // if (isLoading && course === null && !enquiryId) {
    //   const topic = new URLSearchParams(window.location.search).get("topic");
    //   if(topic) {
    //     debugger;
    //     getCorseInfo(topic);
    //   }
    // }
  });

  useEffect(() => {
    if (course !== null && enquiryId) {
      searchTeachers();
    }
  }, []);

  useEffect(() => {
    if (course && course !== null) {
      if (tab === "Teacher") {
        searchTeachers();
      } else if (tab === "Happening") {
        searchHappenings();
      }
    }
  }, [tab, course]);

  // helpers
  const appendAction = (action) => {
    switch (action) {
      case "SUCESS_ENQUIRY":
        setPopupMode("SUCESS_ENQUIRY");
        break;
    }
  };

  // api calls
  const getEnquiryDetail = () => {
    $cs.enquiry
      .list({
        resource: `${$cs.enquiry.api}${enquiryId}/`,
      })
      .then(
        (res) => {
          setCourse(res.data);
          setLoading(false);
        },
        (err) => { }
      );
  };

  const searchTeachers = () => {
    if (course) {
      $cs.enquiry
        .create({
          resource: `${$cs.course.api}${enquiryId ? course.course.id : course.id
            }/browse/`,
          data: {
            ...filter,
            page: pageMode,
            enquiryId,
          },
        })
        .then(
          (res) => {
            setTeacherList(res.data);
          },
          (err) => { }
        );
    }
  };

  const searchHappenings = () => {
    $cs.enquiry
      .list({
        resource: `${$cs.course.api}${enquiryId ? course.course.id : course.id
          }/happening/`,
        params: filter,
      })
      .then(
        (res) => {
          setEnquiriesList(res.data);
        },
        (err) => { }
      );
  };

  // render functions
  const renderTeachers = () => {
    if (teacherList === null) {
      return <span>{enquiryId ? "Loading..." : "Select Topic to Filter"}</span>;
    } else {
      if (teacherList.results && teacherList.results.length > 0) {
        return teacherList.results.map((item, idx) => (
          <UserCard
            item={item}
            key={idx}
            enquiryId={enquiryId}
            pageMode={pageMode}
            course={enquiryId ? course.course : course}
          />
        ));
      } else {
        return <span>No matching results for selected criteria.</span>;
      }
    }
  };

  const renderHappenings = () => {
    if (enquiresList === null) {
      return <span>{enquiryId ? "Loading..." : "Select Topic to Filter"}</span>;
    } else {
      if (enquiresList.results && enquiresList.results.length > 0) {
        return enquiresList.results.map((item, idx) => (
          <UserCard
            item={{ ...item, ...item.owner }}
            module={'HAPPENINGS'}
            key={idx}
            enquiryId={enquiryId}
            course={enquiryId ? course.course : course}
            favorite={
              course.course &&
                course.course.favorite &&
                course.course.favorites.indexOf(item.id) !== -1
                ? true
                : false
            }
          />
        ));
      } else {
        return <span>No matching results for selected criteria.</span>;
      }
    }
  };

  const renderSessions = () => {
    return (
      <div>
        <div className="row margin-0">
          <div className="col-12 pad-10">
            <span>Coming Soon</span>
            {/* <iframe
              width="280"
              height="157"
              src="https://www.youtube.com/embed/GV9yHrQ293U"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <h4>Udemy</h4> */}
          </div>
        </div>
      </div>
    );
  };

  const renderFilterBlock = () => {
    return (
      <div className="find-teacher">
        <h3 className="title">{enquiryId ? "Browse" : "Find"} Teachers</h3>
        {enquiryId && !isLoading && (
          <h3 className="sub-title">
            {course.course.title} <small>({course.enquiry_id})</small>
          </h3>
        )}
        <div className="input-element">
          {!enquiryId && (
            <InputCourse
              value={course}
              preSelections={getPresetCourses()}
              setValue={(c) => {
                console.log(c);
                setCourse(c);
              }}
              mode={"INPUT"}
            />
          )}
        </div>
        <div className="filters-group">
          <ul>
            <li>
              <IGInput
                type="select"
                label={
                  lookups && lookups.ENQUIRY_MODE
                    ? lookups.ENQUIRY_MODE.description || "Teaching Mode"
                    : "Teaching Mode"
                }
                placeholder={"Teaching Mode"}
                value={filter.mode}
                className="margin-0"
                options={
                  lookups && lookups.ENQUIRY_MODE
                    ? lookups.ENQUIRY_MODE.codes.map((item, index) => [
                      item.code,
                      item.description,
                    ])
                    : []
                }
                setValue={(val) => {
                  setFilter({
                    ...filter,
                    mode: val,
                  });
                }}
              />
            </li>
            <li>
              <IGInput
                type="select"
                label={
                  lookups && lookups.ENQUIRY_FREE_DEMO
                    ? lookups.ENQUIRY_FREE_DEMO.description || "Free Demo"
                    : "Free Demo"
                }
                placeholder={"Free Demo"}
                value={filter.demo}
                className="margin-0"
                options={
                  lookups && lookups.ENQUIRY_FREE_DEMO
                    ? lookups.ENQUIRY_FREE_DEMO.codes.map((item, index) => [
                      item.code,
                      item.description,
                    ])
                    : []
                }
                setValue={(val) => {
                  setFilter({
                    ...filter,
                    demo: val,
                  });
                }}
              />
            </li>
            {enquiryId && !isLoading && (
              <li>
                <IGInput
                  type="select"
                  label={"Filter by Best Ratings"}
                  placeholder={"Filter by Best Ratings"}
                  value={filter.rating}
                  className="margin-0"
                  options={
                    lookups && lookups.TEACHER_RATING_VALUES
                      ? [
                        ["all", "Overall Ratings"],
                        ...lookups.TEACHER_RATING_VALUES.codes.map(
                          (item, index) => [item.code, item.description]
                        ),
                      ]
                      : ["all", "Overall Ratings"]
                  }
                  setValue={(val) => {
                    setFilter({
                      ...filter,
                      rating: val,
                    });
                  }}
                />
              </li>
            )}
            {filter.location && (
              <li>
                <IGInput
                  type="location"
                  label="Location"
                  placeholder="Location"
                  value={filter.location}
                  setValue={(val) => {
                    setFilter({
                      ...filter,
                      location: val,
                    });
                  }}
                />
              </li>
            )}
          </ul>
        </div>

        <div className="near-info">
          <input
            type="checkbox"
            name=""
            id=""
            checked={filter.location}
            onChange={(e) => {
              if (e.target.checked) {
                setFilter({
                  ...filter,
                  location: profile.address,
                });
              } else {
                setFilter({
                  ...filter,
                  location: null,
                });
              }
            }}
          />{" "}
          Near My Location (shows results within{" "}
          {settings && settings.FIND_TEACHER_DISTANCE
            ? settings.FIND_TEACHER_DISTANCE.value || "100"
            : "100"}{" "}
          kms radius)
        </div>
        <div className="view-favorites">
          {enquiryId && !isLoading && (
            <div className="col-2 pad-0 pad-top-5">
              <div>
                <input
                  type="checkbox"
                  name=""
                  id=""
                  checked={filter.favorites}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      favorites: e.target.checked,
                    });
                  }}
                />{" "}
                View Favorites
              </div>
            </div>
          )}
        </div>
        <div className="action-buttons">
          <ul>
            <li>
              <button
                className="find-button"
                disabled={!course}
                onClick={() => {
                  searchTeachers();
                }}
              >
                {" "}
                <span className="fa fa-search"></span> Find
              </button>
            </li>
            <li>
              <button
                className="reset-button"
                onClick={() => {
                  setFilter({
                    rating: null,
                    location: null,
                    favorites: false,
                    mode: null,
                    demo: null,
                  });
                  console.log(filter);
                }}
              >
                Reset
                <span className="fa fa-close"></span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderPopupBody = () => {
    return (
      <div className="row margin-0">
        <div className="col-12 text-center">
          <span
            className="fa fa-check-circle text-success margin-bottom-20"
            style={{ fontSize: "4rem" }}
          ></span>
          <h4 className="txt-theme">Enquiry Created Successfully</h4>
          <button
            className="btn btn-sm btn-yellow margin-top-10"
            onClick={() => {
              setPopupMode(null);
            }}
          >
            Start Browsing Teachers
          </button>
        </div>
      </div>
    );
  };

  return (
    <section>
      <div className="find-teach-card border-card pad-30 margin-bottom-30 margin-top-20">
        {renderFilterBlock()}
      </div>
      <div className="browse-teacher-tab border-card pad-30 margin-bottom-30">
        {!enquiryId && (
          <div>
            <ul className="user-tab-menu default-tabs">
              <li
                className={tab === "Teacher" ? "active" : ""}
                onClick={() => {
                  searchTeachers();
                  setTab("Teacher");
                }}
              >
                Teachers
              </li>
              <li
                className={tab === "Happening" ? "active" : ""}
                onClick={() => {
                  setTab("Happening");
                  // searchHappenings();
                }}
              >
                Happening!
              </li>
              <li
                className={tab === "LiveSessions" ? "active" : ""}
                onClick={() => {
                  setTab("LiveSessions");
                }}
              >
                Live Sessions
              </li>
            </ul>
          </div>
        )}
        {tab === "Teacher" && renderTeachers()}
        {tab === "Happening" && renderHappenings()}
        {tab === "LiveSessions" && renderSessions()}
      </div>
    </section>
  );
};

export default BrowseTeachers;
