import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { Button, Spinner, Container, Row, Col } from 'react-bootstrap';
import { realTimeProfileSelector, actionsObjSelector, setProfile, getProfile, getRealTimeProfile, getActions } from '../../store/userReducer';




const Help = (props) => {
    const [activeTab, setActiveTab] = useState('one')
    const profile = useSelector(realTimeProfileSelector);


    const accordianBlock = (label, id, content) => {
        return <div className="col-12 pad-0 margin-top-10 help-accordian">
            <h5 className='bold c-pointer' onClick={() => { setActiveTab(id) }}>{label} <span className="fa fa-chevron-down f-right"></span></h5>
            {
                activeTab === id && content
            }
        </div>
    }

    return (
        <div className="row margin-0 ">
            <div className="col-12 pad-0">
                <div className="shadow-theme pad-15">
                    <div className="row margin-0">
                        <div className="col-12 pad-0">
                            <h4 className='txt-theme'>Help <span className='f-right txt-black'>Need more info? <Link className="txt-theme" style={{ textDecoration: 'underline', fontSize: '14px' }} to="/inbox?action=ASK_ADMIN">Contact Admin</Link></span></h4>
                        </div>
                        {
                            profile.user_type === 'STUDENT' ?
                                <div className="col-12 pad-0">
                                    {accordianBlock('How can I use this portal', 'one', (<p>To learn anything, students can find the teachers across the world with matching skillset. Students can directly contact teachers, by checking their profile and unlocking contact details or let the teachers know that you are looking for training or project support or job support or assignment of specific topic.</p>))}
                                    {accordianBlock('Login', 'fdfddf', (<p>Register as student and select add topics or courses in to the wishlish, you would like to learn.</p>))}
                                    {accordianBlock('Profile Verification', 'dsd', (<p>Input required details. Verify your phone to post enquiry. You would be able to contact teacher only if the phone number is verified.</p>))}
                                    {accordianBlock('Wishlist', 'udsrweoruw', (<p>Select the list of topics or courses for which you would like to receive training alerts, teacher availability etc., You can add as many topics to your wishlist to get training alerts. You can remove topics from your wishlist to stop getting training alerts from teachers. Please note:  Atleast alteast on topic is mandatory in the wishlist.</p>))}
                                    {accordianBlock('Announcement', 'sdeoruw', (<p>Announcements are posted by teachers, Consultants or training institutes who is planning to conduct the training program. Your dashboard will show the list of announcements matching your wishlist. Depending upon the membership of teacher, student would get opportunity to unlock the teacher details for free or alternatively, student can use coins to unlock teacher details to contact.</p>))}
                                    {accordianBlock('Find Teacher', 'fdsf', (<p><b>Browse</b> the list of teachers available for training. System will fetch list of teachers available based on wishlist and you can refine search using parameters and pick the right teacher for you. Read and understand the details of teacher, profile, education, certifications, ratings before you shortlist the teacher. <br/> '<b>Happenings</b>' are training sessions scheduled to start by teachers in next few days. <br/>'<b>Live Sessions</b>' will help to find the courses in third party portal. Student can check and enroll in the course. <br/>Please Note: Go Teacher Pro is just facilitator and doesn't guarantee about the content quality.</p>))}
                                    {accordianBlock('Request Demo', 'fdsfsdfsd', (<p>Once you found the right teacher using 'Find Teacher' option, you have two options Unlock the teacher using Coins or request for demo.</p>))}
                                    {accordianBlock('Unlocking Teacher', 'werwerw', (<p>Unlocking teacher can be done by student by deducting coins. Coins can be bought using your country currency.</p>))}
                                    {accordianBlock('Purchasing Coins', 'kadassdk', (<p>Coins can be purchased using your country currency. If your country currency is not available, you can purchase using USD. (US Dollars). Coins are subject to expiry after specific no. of days.</p>))}
                                    {accordianBlock('Enquiry', 'dsdsds', (<p>You can post an enquiry, which will be sent to all teachers matching the topic to their skillset. Teachers would be able to unlock your details and contact you. While posting enquiry, student can specify the topic, training preferences, location, language, estimated fee, available timings, Urgency of task or training etc., This will help to communicate your wish and preferences to your teacher.</p>))}
                                    {accordianBlock('Request to add course', 'fdsfsdf', (<p>If you don't find the right course in the list, request admin to add course to master list using 'Inbox' option.</p>))}
                                    {accordianBlock('Enrollments', 'fsdfsdfsd', (<p><b>For Enquiries</b>: Teacher will send request to accept the proposal as teacher. Student can check the request in enquiry and accept the teacher.
                                        Once the acceptance is done, course will appear in enrollment list. From the point of time, rating feature will be available and student can provide ratings to the teachers. (Student ratings can be provided by teacher)
                                        <br/><b>For Announcements</b>: Teacher may ask teacher to send enrollment request. Student has to send the enrollment request to teacher, which in turn teacher will accept the student request.
                                        Once the acceptance is done, course will appear in enrollment list. From the point of time, rating feature will be available and student can provide ratings to the teachers. (Student ratings can be provided by teacher)</p>))}
                                    {accordianBlock('Ratings', 'dfsdfsdf', (<p>Students can provide rating to teachers and get ratings also for enquries and announcements; but teacher/student has to enroll to provide/get ratings.</p>))}
                                    {accordianBlock('Alerts', 'dfsfsdfsd', (<p>Students can check alerts for coin expiry and admin related alerts</p>))}
                                    {accordianBlock('Settings Menu', 'hjghgjgh', (<p>Subscribe or unsubscribe email notifications, Change password, Deactivate and account close requests can be availed in settings menu.</p>))}
                                    {accordianBlock('Invoices', 'dsfsdfsdfs', (<p>For Coins purchases, invoices will be generated and available in corresponding menu to download.</p>))}
                                    {accordianBlock('Dispute', 'jhgjghjg', (<p>User can raise dispute for suspected coins debits or credits payments. Admin will review and either refund or reject the request on case to case basis.</p>))}
                                </div>
                                :
                                <div className="col-12 pad-0">
                                    {accordianBlock('How can I use this portal', 'one', (<p>To provide sevices like training, project support, job support, teachers can post announcements to the students or respond to students enquiries across the world, with matching wishlist.
                                        Students can directly contact teachers, by checking their profile and unlocking contact details or let the teachers know that you are looking for training or project support or job support or assignment of specific topic.</p>))}
                                    {accordianBlock('Login', 'fdfddf', (<p>Register as teacher and select add topics or courses in to the skillset, which you have expertize to teach, provide project or job training.</p>))}
                                    {accordianBlock('Profile Verification', 'dsd', (<p>Input required details.
                                        Provide as much detailed information as required. This is the key and mandatory step for teacher to get enquiries or post notifictions.
                                        Admin will verify and approve your profile and post verification you can proceed to use the account for all activities.
                                        Verify your phone to post announcement. You would be able to contact student only if the phone number is verified.
                                        DEMO Flag = Y will help teacher to get demo requests from the students.</p>))}
                                    {accordianBlock('Skillset', 'udsrweoruw', (<p>Select the list of topics or courses for which you would like to receive training alerts, demo requests from the students.
                                        You can add as many topics to your skillset to get training alerts.
                                        You can remove topics from your skillset to stop getting training enquiries from students. Please note: Atleast one topic is mandatory in the skillset.</p>))}
                                    {accordianBlock('Announcement', 'sdeoruw', (<p>Announcements can be posted by Teachers, Consultants or training institutes who is planning to conduct the training program by deducting coins. Students dashboard will show the list of announcements matching their wishlist. Depending upon the membership of teacher, first few set of students will be shown your contact details for free. Other set of students can unlock the view the contact details of the teacher.
                                        Depending upon the membership of teacher, student would get opportunity to unlock the teacher details for free or alternatively, student can use coins to unlock teacher details to contact.</p>))}
                                    {accordianBlock('Find Student', 'fdsdfsd', (<p>Find the active training enquiry request open. Teacher can search the list of training, job support, project support related enquiries and choose to unlock the student details to contact.
                                        Teacher can read and understand the details of student, location, ratings before you pick the student to contact.<br/>
                                        Please Note: Go teacher pro is a facilitator and doesn't guarantee the student preference, seriousness about opting for job etc.,</p>))}
                                    {accordianBlock('Demo Request', 'fdsfsdfsd', (<p>Students can search teachers and request for demo. In case, teacher opted for Free Demo as Yes, students can send you the demo request.
                                        If sufficient coin balance is available, coins would be deducted and teacher contact will be shown to student. In case of no sufficient balance, student demo request will be posted to respective teacher and then teacher can unlock student contact details to discuss further on class/job details.</p>))}
                                    {accordianBlock('Unlocking Students', 'werwerw', (<p>Teachers can unlock enquiries and contact students by deducing prescribed coins.</p>))}
                                    {accordianBlock('Purchasing Coins', 'kadassdk', (<p>Coins can be purchased using your country currency. If your country currency is not available, you can purchase using USD. (US Dollars). Coins are subject to expiry after specific number of days, unless you purchase any premium membership</p>))}
                                    {accordianBlock('Memberships', 'dsdds', (<p>Teacher can purchase members to get various benefits.
                                        Get the enquiries instantly for highest premium membership otherwise there would be delay in getting enquiry notice.
                                        Unlock enquiries in discounted price.
                                        Post announcements about upcoming training sessions and set of students can view your contact for free, depending upon your membership type.
                                        Purchase membership using coins or currency of your country.
                                        Get additional validity to your existing coins and avoid expiry of coins till your membership expires.</p>))}
                                    {accordianBlock('Enquiry', 'dsdsds', (<p>You can view an enquiry, which was shown to you on dashboard matching the topic to your skillset. Teachers would be able to unlock details and contact students.
                                        Typically enquiry will have details related to topic, training preferences, location, language, estimated fee, available timings, Urgency of task or training etc.,
                                        Get the enquiries instantly for highest premium membership otherwise there would be delay in getting enquiry notice. Check 'Membership' page for more details.</p>))}
                                    {accordianBlock('Request to add course', 'fdsfsdf', (<p>If you don't find the right course in the list, request admin to add course to master list using 'Inbox' option.</p>))}
                                    {accordianBlock('Enrollments', 'fsdfsdfsd', (<p><b>For Enquiries</b>: Teacher has to send request to accept the proposal as teacher. Student can check the request in enquiry and accept the teacher.
                                        Once the acceptance is done, course will appear in enrollment list. From the point of time, rating feature will be available and teacher can provide ratings to the students. (Teacher ratings can be provided by student)
                                        <br/><b>For Announcements</b>: Teacher may ask teacher to send enrollment request. Student has to send the enrollment request to teacher, which in turn teacher will accept the student request.
                                        Once the acceptance is done, course will appear in enrollment list. From the point of time, rating feature will be available and teacher can provide ratings to the students. (Teacher ratings can be provided by student)</p>))}
                                    {accordianBlock('Ratings', 'dfsdfsdf', (<p>Teachers can provide rating to students and get ratings also for enquries and announcements; but teacher/student has to enroll to provide/get ratings.</p>))}
                                    {accordianBlock('Alerts', 'dfsfsdfsd', (<p>Students can check alerts for coin expiry and admin related alerts</p>))}
                                    {accordianBlock('Settings Menu', 'hjghgjgh', (<p>Subscribe or unsubscribe email notifications, Change password, Deactivate and account close requests can be availed in settings menu.</p>))}
                                    {accordianBlock('Invoices', 'dsfsdfsdfs', (<p>For Coins purchases and membership, invoices will be generated and available in corresponding menu to download.</p>))}
                                    {accordianBlock('Dispute', 'jhgjghjg', (<p>User can raise dispute for suspected coins debits or credits or membership payments. Admin will review and either refund or reject the request on case to case basis.</p>))}
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Help;
