import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $cs from "../../services.js";

const InputRating = (props) => {
  const { value, setValue, mode, showValue, label } = props;
  const [rating, setRating] = useState(value);
  const [hover, setHover] = useState(0);

  if (mode === "view") {
    return (
      <div className="star-rating">
        {label && <span className="name">{label}</span>}
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <button
              type="button"
              key={index}
              className={index <= value ? "on" : "off"}
            >
              <span className="star">&#9733;</span>
            </button>
          );
        })}
        {showValue && <span>({parseFloat(value).toFixed(1)})</span>}
      </div>
    );
  } else {
    return (
      <div className="star-rating">
        {[...Array(5)].map((star, index) => {
          const stableIndex = index + 1;
          return (
            <button
              type="button"
              key={index}
              className={
                value >= stableIndex ||
                hover >= stableIndex ||
                rating >= stableIndex
                  ? "on"
                  : "off"
              }
              onMouseEnter={() => setHover(stableIndex)}
              onMouseLeave={() => {
                setHover(value);
              }}
              onClick={() => {
                setRating(stableIndex);
                setValue(stableIndex);
              }}
            >
              <span className="star">&#9733;</span>
            </button>
          );
        })}
      </div>
    );
  }
};

export default InputRating;
