import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import $cs from "../../services.js";
import moment from "moment";
import {
  realTimeProfileSelector,
  profilesSelector,
  getProfiles,
} from "../../store/userReducer";

const EnquiriesList = (props) => {
  const { mode, enquiryType, params } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [appWidth, setappWidth] = useState(document.body.offsetWidth);
  const profile = useSelector(realTimeProfileSelector);
  const profiles = useSelector(profilesSelector);
  const titles = {
    REQUEST: {
      title: "Enquiries",
      teacherDescription: "Training requests from the students.",
      description: "Find the teacher by posting an enquiry.",
    },
    ANNOUNCEMENT: {
      title: "Announcements",
      teacherDescription:
        "Communicate to students about your upcoming training sessions.",
      description:
        "Announcements from the teachers about upcoming training sessions, matching your wishlist.",
    },
    DEMO: {
      title: "Demo Requests",
      teacherDescription: "Demo requests raised by students.",
      description: "Request demo from find teacher screen.",
    },
  };
  const [enquiryTab, setEnquiryTab] = useState("OPEN");
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [isStarted, setStarted] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [openEnquiriesList, setOpenEnquiries] = useState(null);
  const [archivedEnquiriesList, setArchivedEnquiries] = useState(null);

  useEffect(() => {
    if (
      enquiryType !== "UPCOMING" &&
      !isStarted &&
      isLoading &&
      openEnquiriesList === null &&
      profile &&
      profile.status !== "UNDER_VERIFICATION"
    ) {
      setStarted(true);
      getOpenEnquires();
      getArchivedEnquires();
    }
    if (
      enquiryType !== "UPCOMING" &&
      profile &&
      profile.status === "UNDER_VERIFICATION"
    ) {
      setLoading(false);
      // setOpenEnquiries({
      //     results: []
      // });
      // setArchivedEnquiries({
      //     results: []
      // });
    }
  });

  useEffect(() => {
    if (!isStarted && enquiryType === "UPCOMING") {
      setStarted(true);
      getOpenEnquires();
    }
  });

  const addToWishlist = (course_id) => {
    const payload = {
      action: "addbulk",
      courses: [course_id],
    };
    $cs.profiles
      .create({
        resource: `${$cs.profiles.api}${profiles.results[0].id}/updatecourse/`,
        data: payload,
      })
      .then(
        (res) => {
          dispatch(getProfiles());
        },
        (err) => { }
      );
  };

  const getOpenEnquires = (resource = "") => {
    $cs.enquiry
      .list({
        resource:
          enquiryType === "UPCOMING" ? `${$cs.enquiry.api}upcoming/` : resource,
        pageSize,
        params:
          enquiryType === "UPCOMING"
            ? {
              mode: enquiryType,
              ...params,
            }
            : {
              enquiry_type: enquiryType,
              status: "OPEN",
            },
      })
      .then(
        (res) => {
          setOpenEnquiries(res.data);
          setCount(res.data.count);
          setLoading(false);
        },
        (err) => { }
      );
  };

  const getArchivedEnquires = (resource = "") => {
    $cs.enquiry
      .list({
        resource,
        pageSize,
        params: {
          enquiry_type: enquiryType,
          status: "ARCHIVED",
        },
      })
      .then(
        (res) => {
          setArchivedEnquiries(res.data);
        },
        (err) => { }
      );
  };

  const unlockEnquiry = (item) => {
    $cs.enquiry
      .list({
        resource: `${$cs.enquiry.api}${item.id}/unlock/`,
      })
      .then(
        (res) => {
          getOpenEnquires();
        },
        (err) => { }
      );
  };

  const renderPagination = () => {
    const dataList =
      enquiryTab === "OPEN"
        ? openEnquiriesList.results
        : archivedEnquiriesList.results;
    if (dataList.length > 0) {
      return (
        <div className="row margin-0">
          <div className="col text-center">
            {openEnquiriesList.previous !== null && (
              <span
                className="txt-theme margin-right-10 c-pointer"
                onClick={() => {
                  getOpenEnquires(openEnquiriesList.previous);
                }}
              >
                Prev
              </span>
            )}
            {openEnquiriesList.next !== null && (
              <span
                className="txt-theme c-pointer"
                onClick={() => {
                  getOpenEnquires(openEnquiriesList.next);
                }}
              >
                Next
              </span>
            )}
          </div>
        </div>
      );
    } else {
      return;
    }
  };

  const renderParticipantsCount = (item) => {
    return (
      <div className="info-block pad-top-15">
        <span className="text-right d-block">
          {item.total_enrollments} views
        </span>
        <div className="gt-progess-bar">
          <div
            className="gt-progress-bar-process"
            style={{
              width: `${(item.total_enrollments / item.unlocks) * 100}%`,
            }}
          ></div>
        </div>
      </div>
    );
  };

  const renderEnquiries = () => {
    if (isLoading) {
      return (
        <li className="text-center">
          Loading...
        </li>
      );
    } else {
      let dataList = null;
      if (enquiryTab === "OPEN" && openEnquiriesList) {
        dataList = openEnquiriesList.results;
      } else if (archivedEnquiriesList) {
        dataList = archivedEnquiriesList.results;
      }
      if (dataList && dataList.length > 0) {
        if (enquiryType === "REQUEST") {
          return dataList.map((item, index) => (
            <li key={index}>
              <div
                className="each-announcement cursor"
                onClick={appWidth < 800 ? () => {
                  history.push(`/enquiry/${item.id}`);
                } : undefined}
              >
                <div className="top">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-12">
                      <h5 className="margin-bottom-0">
                        {item.course.parent_detail
                          ? item.course.parent_detail.topic + " - "
                          : ""}
                        {item.course ? item.course.title : "-"}{" "}
                        <small> ({item.enquiry_id})</small>
                      </h5>
                      <span>
                        {item.form
                          ? item.form.location
                            ? item.form.location.name || item.form.location
                            : "-"
                          : "-"}
                      </span>
                    </div>
                    <div className="col-md-2 col-sm-3 col-6 order-4 order-sm-2">
                      {renderParticipantsCount(item)}
                    </div>
                    <div className="col-md-2 col-sm-3 col-6">
                      <div className="info-block">
                        <label htmlFor="">Posted On</label>
                        <p>
                          {moment(item.created_on).format("DD MMM, YYYY") ||
                            "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-3 col-6">
                      <div className="info-block">
                        <label htmlFor="">Respond By</label>
                        <p>
                          {moment(item.archived_on).format("DD MMM, YYYY") ||
                            "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="middle">
                  <div className="row margin-0">
                    <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                      <div className="info-snip">
                        <label htmlFor="">Mode</label>
                        <p>
                          {item.form && item.form.mode ? item.form.mode : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                      <div className="info-snip">
                        <label htmlFor="">Frequency</label>
                        <p>
                          {item.form && item.form.frequency
                            ? item.form.frequency
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                      <div className="info-snip">
                        <label htmlFor="">Language</label>
                        <p>
                          {item.form && item.form.language
                            ? item.form.language
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                      <div className="info-snip">
                        <label htmlFor="">#Students</label>
                        <p>
                          {item.form && item.form.student_count
                            ? item.form.student_count
                            : "1"}
                        </p>
                      </div>
                    </div>
                    {item.form && item.form.is_urgent && (
                      <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                        <div className="info-snip pad-top-15">
                          <p className="text-danger">It's an Urgent Enquiry</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {
                  (appWidth > 800 || (profile.user_type === 'STUDENT' && item.status === 'OPEN')) && <div className="bottom">
                    <div className="row margin-0">
                      <div className="col pad-0">
                        <div className="text-right">
                          <button
                            className="btn btn-sm btn-theme txt-theme"
                            onClick={() => {
                              history.push(`/enquiry/${item.id}`);
                            }}
                          >
                            View
                          </button>
                          {profile.user_type === "STUDENT" && (
                            <button
                              className="btn btn-sm btn-danger margin-left-10"
                              onClick={() => {
                                history.push(`/browse/${item.id}`);
                              }}
                            >
                              {" "}
                              <span className="fa fa-search"></span> Teachers
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </li>
          ));
        } else if (enquiryType === "ANNOUNCEMENT") {
          return dataList.map((item, index) => (
            <li key={index}>
              <div
                className="each-announcement cursor"
                onClick={appWidth < 800 ? () => {
                  history.push(`/announcement/${item.id}`);
                } : undefined}
              >
                <div className="top">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-12">
                      <h5>
                        {item.course.parent_detail
                          ? item.course.parent_detail.topic + " - "
                          : ""}
                        {item.course ? item.course.title : "-"}
                        <small> ({item.enquiry_id})</small>
                      </h5>
                      <span>
                        {item.form
                          ? item.form.location
                            ? item.form.location.name || item.form.location
                            : "-"
                          : "-"}
                      </span>
                    </div>
                    <div className="col-md-3 col-sm-3 col-6">
                      <div className="info-block">
                        <label htmlFor="">Posted On</label>
                        <p>
                          {moment(item.created_on).format("DD MMM, YYYY") ||
                            "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="middle">
                  <div className="row margin-0">
                    <div
                      className="col-lg-2 col-md-3 col-sm-3 col-6"
                      onClick={() => {
                        history.push(`/announcement/${item.id}`);
                      }}
                    >
                      <div className="info-snip">
                        <label htmlFor="">Mode</label>
                        <p>
                          {item.form && item.form.mode ? item.form.mode : "-"}
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-2 col-md-3 col-sm-3 col-6"
                      onClick={() => {
                        history.push(`/announcement/${item.id}`);
                      }}
                    >
                      <div className="info-snip">
                        <label htmlFor="">Frequency</label>
                        <p>
                          {item.form && item.form.frequency
                            ? item.form.frequency
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-2 col-md-3 col-sm-3 col-6"
                      onClick={() => {
                        history.push(`/announcement/${item.id}`);
                      }}
                    >
                      <div className="info-snip">
                        <label htmlFor="">Language</label>
                        <p>
                          {item.form && item.form.language
                            ? item.form.language
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3 col-6">
                      <div className="info-snip">
                        <label htmlFor="">Free Demo</label>
                        <p
                          className={
                            item.form && item.form.free_demo === "Yes"
                              ? "text-danger"
                              : ""
                          }
                        >
                          {item.form && item.form.free_demo
                            ? item.form.free_demo
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  appWidth > 800 && <div className="bottom">
                    <div className="row justify-content-end">
                      <div className="col-4 d-flex justify-content-end">
                        <button
                          className="btn btn-sm btn-theme txt-theme"
                          onClick={() => {
                            history.push(`/announcement/${item.id}`);
                          }}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="row margin-0">
                <div className="col-12 pad-0"></div>
              </div>
            </li >
          ));
        } else if (enquiryType === "UPCOMING") {
          return dataList.map((item, index) => (
            <li key={index} className="c-pointer">
              <div className="row margin-0">
                <div className="col-md-6 col-sm-6 col-12 pad-10">
                  <h5 className="margin-bottom-0">
                    {item.course.parent_detail
                      ? item.course.parent_detail.topic + " - "
                      : ""}
                    {item.course ? item.course.title : "-"}
                    <small> ({item.enquiry_id})</small>
                  </h5>
                  <span>
                    {item.form
                      ? item.form.location
                        ? item.form.location.name || item.form.location
                        : "-"
                      : "-"}
                  </span>
                </div>
                <div className="col-md-3 col-sm-3 col-6 max-fit-content">
                  <div className="info-block">
                    <label htmlFor="">Posted On</label>
                    <p>
                      {moment(item.created_on).format("DD MMM, YYYY") || "-"}
                    </p>
                  </div>
                </div>
                <div className="col-md-3 pad-left-10 max-fit-content">
                  <div className="info-block">
                    <label htmlFor="">Respond By</label>
                    <p>
                      {item.form && item.form.respond_by
                        ? moment(item.created_on)
                          .add(parseInt(item.form.respond_by) || 3, "days")
                          .format("DD MMM, YYYY")
                        : "-"}
                    </p>
                  </div>
                </div>
                <div
                  className="col-12 pad-0"
                  style={{ borderTop: "1px solid #eee" }}
                >
                  <div className="row margin-0">
                    <div className="col-lg-2 col-md-3 col-sm-3 col-6 pad-left-10">
                      <div className="info-snip">
                        <label htmlFor="">Mode</label>
                        <p>
                          {item.form && item.form.mode ? item.form.mode : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3 col-6 pad-left-10">
                      <div className="info-snip">
                        <label htmlFor="">Frequency</label>
                        <p>
                          {item.form && item.form.frequency
                            ? item.form.frequency
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3 col-6 pad-left-10">
                      <div className="info-snip">
                        <label htmlFor="">Language</label>
                        <p>
                          {item.form && item.form.language
                            ? item.form.language
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3 col-6 pad-left-10">
                      <div className="info-snip">
                        <label htmlFor="">Free Demo</label>
                        <p
                          className={
                            item.form && item.form.free_demo === "Yes"
                              ? "text-danger"
                              : ""
                          }
                        >
                          {item.form && item.form.free_demo
                            ? item.form.free_demo
                            : "-"}
                        </p>
                      </div>
                    </div>
                    {profile && profile.user_type === "STUDENT" && (
                      <div className="col text-right pad-top-10 mb-hide">
                        <button
                          className="btn btn-sm btn-theme txt-theme"
                          onClick={() => {
                            history.push(`/announcement/${item.id}`);
                          }}
                        >
                          View
                        </button>
                      </div>
                    )}
                    {profile && profile.user_type === "STUDENT" && (
                      <div className="col text-right pad-10 mb-visible">
                        <button
                          className="btn btn-sm btn-theme txt-theme"
                          onClick={() => {
                            history.push(`/announcement/${item.id}`);
                          }}
                        >
                          View
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li>
          ));
        } else if (enquiryType === "DEMO") {
          return dataList.map((item, index) => (
            <li key={index}>
              <div className="row margin-0">
                <div className="col pad-10">
                  <h5 className="margin-bottom-0">
                    {item.course.parent_detail
                      ? item.course.parent_detail.topic + " - "
                      : ""}
                    {item.course ? item.course.title : "-"}{" "}
                    <small>({item.enquiry_id})</small>
                  </h5>
                  <span>{item.form ? item.form.preference : "-"}</span>
                </div>
                <div className="col-md-3 pad-left-10">
                  <div className="info-snip">
                    <label htmlFor="">Name</label>
                    <p>
                      {item.owner_details && item.owner_details.name
                        ? item.owner_details.name
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="col max-fit-content">
                  <div className="info-block">
                    <label htmlFor="">Requested On</label>
                    <p>
                      {moment(item.created_on).format("DD MMM, YYYY") || "-"}
                    </p>
                  </div>
                </div>
                <div className="col max-fit-content">
                  <div className="info-block">
                    <label htmlFor="">Respond By</label>
                    <p>
                      {moment(item.archived_on).format("DD MMM, YYYY") || "-"}
                    </p>
                  </div>
                </div>
                <div
                  className="col-12 pad-0"
                  style={{ borderTop: "1px solid #eee" }}
                >
                  <div className="row margin-0">
                    <div className="col-12 pad-left-10">
                      <div className="info-snip margin-0">
                        <label className="margin-0 txt-theme" htmlFor="">
                          {profile.user_type === "TEACHER"
                            ? "Student"
                            : "Teacher"}{" "}
                          Details:
                        </label>
                      </div>
                    </div>
                    {item.activity && (
                      <>
                        <div className="col-md-3 pad-left-10">
                          <div className="info-snip">
                            <label htmlFor="">Email</label>
                            <p className={(item.owner_details && item.owner_details.email) ? 'text-danger' : ''}>
                              {item.owner_details && item.owner_details.email
                                ? item.owner_details.email
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3 pad-left-10">
                          <div className="info-snip">
                            <label htmlFor="">Mobile</label>
                            <p className={(item.owner_details && item.owner_details.mobile) ? 'text-danger' : ''}>
                              {item.owner_details && item.owner_details.mobile
                                ? item.owner_details.mobile
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-md-3 pad-left-10">
                      <div className="info-snip">
                        <label htmlFor="">Entity Type</label>
                        <p>
                          {item.owner_details && item.owner_details.entity_type
                            ? item.owner_details.entity_type
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col text-right pad-10">
                      <button
                        className="btn btn-sm btn-theme txt-theme"
                        onClick={() => {
                          history.push(`/demo/${item.id}`);
                        }}
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ));
        }
      } else {
        return (
          <div className="row margin-0">
            <div className="col text-center">
              <span className="text-center">
                {enquiryType === "UPCOMING"
                  ? "No Upcoming Announcements"
                  : `No ${enquiryTab} ${titles[enquiryType].title}`}
              </span>
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div
      className={`${appWidth > 7000 ? "banner-block pad-10" : ""
        }enquiry-list-block`}
    >
      {mode !== "home" && (
        <div className="enquiry-list-block-content pad-top-10 enquiryListBlockMarginClass">
          <div className="row margin-0">
            <div className="col-6 pad-0">
              <div className="tab-inner-title">
                <h4>{titles[enquiryType].title} </h4>
                <span className="count">{count}</span>
              </div>
            </div>
            <div className="col-6 pad-0 pad-top-5">
              <div className="txt-theme bold margin-bottom-0">
                {enquiryType === "REQUEST" &&
                  profile.user_type === "STUDENT" &&
                  profile.status === "ACTIVE" && (
                    <button
                      className="btn btn-sm btn-yellow f-right"
                      onClick={() => {
                        history.push("/new-enquiry");
                      }}
                    >
                      + New
                    </button>
                  )}
                {enquiryType === "ANNOUNCEMENT" &&
                  profile.user_type === "TEACHER" &&
                  profile.status === "ACTIVE" && (
                    <button
                      className="btn btn-sm btn-yellow f-right"
                      onClick={() => {
                        history.push("/new-announcement/");
                      }}
                    >
                      + New
                    </button>
                  )}
              </div>
            </div>
            <div className="col-12 pad-0">
              <p className="enquiry-list-desc">
                {profile.user_type === "TEACHER" ? (
                  <span>{titles[enquiryType].teacherDescription}</span>
                ) : (
                  <span>{titles[enquiryType].description}</span>
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      {mode !== "home" && (
        <div className="enquiry-list-block-content">
          <ul className="default-tabs-horizontal mb-1">
            <li className={enquiryTab === "OPEN" ? "active" : ""}>
              <a
                onClick={() => {
                  setEnquiryTab("OPEN");
                }}
              >
                Open
              </a>
            </li>
            <li className={enquiryTab === "ARCHIVED" ? "active" : ""}>
              <a
                onClick={() => {
                  setEnquiryTab("ARCHIVED");
                }}
              >
                Archived
              </a>
            </li>
          </ul>
        </div>
      )}
      <div className="row margin-0">
        <div className="col-12 enquiry-list-block-content">
          <div className="responsive-list">
            <ul className="reponsive-enquires-list">{renderEnquiries()}</ul>
            {!isLoading && openEnquiriesList !== null && renderPagination()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiriesList;
