exports.EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
exports.validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};
exports.feedbackLabels = {
    Promptness: 'Attendance & Promptness',
    Knowledge: 'Subject Knowledge',
    Practicals: 'Practicals & Hands-on',
    UnderStanding: 'Subject Understanding',
    Payment: 'On Time Payment',
}
exports.feedbackList = {
    STUDENT: {
        Promptness: 'Attendance & Promptness',
        Payment: 'On Time Payment',
        UnderStanding: 'Subject Understanding',
    },
    TEACHER: {
        Promptness: 'Attendance & Promptness',
        Knowledge: 'Subject Knowledge',
        Practicals: 'Practicals & Hands-on'
    }
}
