import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Button, Spinner, Container, Row, Col } from 'react-bootstrap';

const Billing = (props) => {
    // const dispatch = useDispatch();
    // const valueSelector = useSelector();
    // const [isLoading, setLoading] = useState(true);

    // useEffect(() => {
    // });

    const renderView = () => {
        // if(isLoading) {
        // }
        return (
            <h6>This is Billing</h6>
        )
    }

    return (
        <div>
          {renderView()}
        </div>
    );
}

export default Billing;
