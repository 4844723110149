import { useSelector, useDispatch } from "react-redux";
import { bannersSelector } from "../../store/appReducer";

const Banner = (props) => {
  const { target = "WALLET" } = props;
  const banners = useSelector(bannersSelector);

  if (banners && banners[target] && banners[target].length > 0) {
    return (
      <div className="margin-bottom-15">
        <img
          src={banners[target][0].banner}
          style={{ width: "100%" }}
          className="img-fluid"
          alt=""
        />
      </div>
    );
  }

  return (
    <div className="row margin-0">
      <div className="col pad-0 margin-bottom-10 d-flex-content-center">
        <h3 className="bold txt-grey">
          Banner on latest News <br /> or To do for the user
        </h3>
      </div>
      <div className="col-3 pad-0">
        <img src="/user_dashboard_1.png" className="img-fluid" />
      </div>
    </div>
  );
};

export default Banner;
