import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory, useLocation, useParams } from "react-router-dom";
import $cs from '../services.js'
import { IGInput } from "../ignitor-react";
import IGModal from "../ignitor-react/IGModal";
import InputCourse from "./components/InputCourse";
import moment from "moment";
import { lookupsSelector, appSettingsSelector } from '../store/appReducer';
import { realTimeProfileSelector, setProfile, userTypeSelector, getEnquires, enquiriesSelector, getRealTimeProfile, getProfiles } from '../store/userReducer';

const NewEnquiry = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(realTimeProfileSelector);
    const lookups = useSelector(lookupsSelector);
    const appSettings = useSelector(appSettingsSelector);
    const [isLoading, setLoading] = useState(true);
    const [stage, setStage] = useState('form');
    const [seletedCourseData, setSeletedCourseData] = useState(null);
    const [seletedCourse, setSeletedCourse] = useState(null);
    const [error, setError] = useState({});
    const [popupMode, setPopupMode] = useState(null);

    const getDefaultValue = (optionKey) => {
        let defaultValue = [];
        if(lookups && lookups[optionKey]) {
            defaultValue = lookups[optionKey].codes.filter(item => item.is_default);
        }
        return defaultValue.length>0?defaultValue[0].code:'';
    }

    const presetForm = (type) => {
        switch(type) {
            case "ANNOUNCEMENT":
                return {
                    location: profile.address,
                    type: getDefaultValue('ENQUIRY_TYPE'),
                    assignment: getDefaultValue('ENQUIRY_ASSIGNMENT'),
                    // skill: getDefaultValue('ENQUIRY_SKILL'),
                    frequency: getDefaultValue('ENQUIRY_FREQUENCY'),
                    mode: getDefaultValue('ENQUIRY_MODE'),
                    language: getDefaultValue('ENQUIRY_LANGUAGE'),
                    free_demo: getDefaultValue('ENQUIRY_FREE_DEMO'),
                    fee_type: getDefaultValue('ENQUIRY_FEE_TYPE'),
                    fee_negotiable: getDefaultValue('ENQUIRY_FEE_NEGOTIABLE'),
                    fee: 0,
                    comments: '',
                    start_date: moment().format('YYYY-MM-DD'),
                    respond_by: getDefaultValue('ENQUIRY_RESPOND_BY'),
                };
                break;
            case "REQUEST":
            default:
                return {
                    location: profile.address,
                    type: getDefaultValue('ENQUIRY_TYPE'),
                    assignment: getDefaultValue('ENQUIRY_ASSIGNMENT'),
                    // skill: getDefaultValue('ENQUIRY_SKILL'),
                    frequency: getDefaultValue('ENQUIRY_FREQUENCY'),
                    mode: getDefaultValue('ENQUIRY_MODE'),
                    language: getDefaultValue('ENQUIRY_LANGUAGE'),
                    free_demo: getDefaultValue('ENQUIRY_FREE_DEMO'),
                    fee_type: getDefaultValue('ENQUIRY_FEE_TYPE'),
                    fee_negotiable: getDefaultValue('ENQUIRY_FEE_NEGOTIABLE'),
                    student_count: getDefaultValue('ENQUIRY_STUDENT_COUNT'),
                    fee: 0,
                    is_urgent: false,
                    comments: '',
                };
        }
    }

    const [payload, setPayload] = useState(profile.user_type==='TEACHER'?{
        course: null,
        enquiry_type: 'ANNOUNCEMENT',
        questionnaire: {},
        form: {
            ...presetForm('ANNOUNCEMENT')
        }
    }:{
        course: '',
        enquiry_type: 'REQUEST',
        questionnaire: {},
        form: {
            ...presetForm('REQUEST')
        }
    });

    useEffect(() => {
        if(lookups !== null) {
            setPayload(profile.user_type==='TEACHER'?{
                course: null,
                enquiry_type: 'ANNOUNCEMENT',
                questionnaire: {},
                form: {
                    ...presetForm('ANNOUNCEMENT')
                }
            }:{
                course: '',
                enquiry_type: 'REQUEST',
                questionnaire: {},
                form: {
                    ...presetForm('REQUEST')
                }
            });
        }
    }, [lookups]);

    const getErrorMessage = (field) => {
        if(error && error[field]) {
            return (
                <span className="error">{error[field]}</span>
            )
        }
        return '';
    };

    const renderDynamicInput = (label, key, optionKey) => {
        return (
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <IGInput
                    type="select"
                    label={lookups[optionKey].description || label}
                    placeholder={lookups[optionKey].description || label}
                    value={payload.form[key]}
                    options={lookups && lookups[optionKey]?lookups[optionKey].codes.map((item, index) => [item.code, item.description]):[]}
                    error={error[key]}
                    setValue={(val) => {
                        setPayload({
                            ...payload,
                            form: {
                                ...payload.form,
                                [key]: val
                            }
                        })
                    }}
                />
            </div>
        )
    }

    const validateEnquiry = () => {
        let proceed = true;
        let errorObj = {};
        if(!payload.course || payload.course === "") {
            errorObj.course = 'Select Topic';
            proceed = false;
        }
        if(!payload.form.location || payload.form.location === "") {
            errorObj.location = 'Enter Location';
            proceed = false;
        }
        if(!payload.form.type || payload.form.type === "") {
            errorObj.type = 'Select Type';
            proceed = false;
        }
        if(!payload.form.assignment || payload.form.assignment === "") {
            errorObj.assignment = 'Select Assignment';
            proceed = false;
        }
        if(!payload.form.frequency || payload.form.frequency === "") {
            errorObj.frequency = 'Select Frequency';
            proceed = false;
        }
        if(!payload.form.mode || payload.form.mode === "") {
            errorObj.mode = 'Select Mode';
            proceed = false;
        }
        if(!payload.form.language || payload.form.language === "") {
            errorObj.language = 'Select Language';
            proceed = false;
        }
        if(!payload.form.fee_type || payload.form.fee_type === "") {
            errorObj.fee_type = 'Select Fee Type';
            proceed = false;
        }
        if(payload.enquiry_type==='ANNOUNCEMENT') {
            if(!payload.form.start_date || payload.form.start_date === "") {
                errorObj.start_date = 'Select Start Date';
                proceed = false;
            }
            if(!payload.form.respond_by || payload.form.respond_by === "") {
                errorObj.respond_by = 'Select Respond By';
                proceed = false;
            }
            if(payload.form.start_date && moment(payload.form.start_date).isBefore(moment().add(-1, "days"))) {
                errorObj.start_date = 'Start Date should be in future';
                proceed = false;
            }
        }
        setError(errorObj);
        return proceed;
    };

    const validateQuestionairee = () => {
        if(payload.enquiry_type === 'ANNOUNCEMENT') {
            return true;
        }
        let proceed = true;
        let errorObj = {};
        seletedCourseData.questionnaire.forEach(item => {
            if(item.options.length > 0 && payload.questionnaire[item.id].value === null) {
                proceed = false;
                errorObj[item.id] = "Please select option";
            }
        });
        setError(errorObj);
        return proceed;
    };

    const postEnquiry = (action_type='POSTPAID') => {
        if(validateEnquiry() && validateQuestionairee()) {
            let finalPayload = payload;
            finalPayload.action_type = action_type;
            $cs.enquiry.create({
                data: finalPayload
            }).then(res => {
                dispatch(getRealTimeProfile());
                dispatch(getProfiles());
                if(profile.user_type==='TEACHER') {
                    history.push("/dashboard?tab=ANNOUNCEMENTS");
                } else {
                    history.push(`/browse/${res.data.id}?action=SUCESS_ENQUIRY`);
                }
            }, err => {
                if (
                    err.response.status === 400 &&
                    err.response.data === "INSUFFICIENT_COINS"
                ) {
                    setPopupMode("INSUFFICIENT_COINS");
                }
            });
        }
    };

    const getQuestionairee = (course) => {
        setPayload({
            ...payload,
            course: course.id
        });
        $cs.course.list({
            resource: `${$cs.course.api}${course.id}/questionnaire/`
        }).then(res => {
            setSeletedCourseData(res.data);
            let questionaireeObj = {};
            res.data.questionnaire.forEach(item => {
                questionaireeObj[item.id] = {
                    question: item.question,
                    value: item.options.length>0?null:false
                }
            });
            setPayload({
                ...payload,
                course: course.id,
                questionnaire: questionaireeObj
            });
        }, err => {
        });
    };

    const renderAnnouncementBtn = () => {
        if(seletedCourseData && seletedCourseData.announcement_coins && seletedCourseData.announcement_coins > 0) {
            if(seletedCourseData.announcement_coins > profile.credits) {
                return <div className='text-center'>
                        <span className='text-danger'>Insufficient coins for posting Announcement</span><br />
                        <button className="btn btn-yellow btn-sm margin-right-10" disabled>Post for {seletedCourseData.announcement_coins} coins</button>
                    </div>
            } else {
                return <button className="btn btn-yellow btn-sm margin-right-10" onClick={()=>{
                    postEnquiry();
                }}>Post for {seletedCourseData.announcement_coins} coins</button>
            }
        } else {
            return <button className="btn btn-yellow btn-sm margin-right-10" onClick={()=>{
                postEnquiry();
            }}>Post for Free</button>
        }
    };

    const renderActionBtn = (stage=1) => {
        if(stage==1) {
            if(seletedCourseData && seletedCourseData.questionnaire && seletedCourseData.questionnaire.length > 0) {
                return <button className="btn btn-theme btn-sm" onClick={()=>{
                    if(validateEnquiry()) {
                        setStage('questionairee');
                    }
                }}>Next</button>
            } else {
                let minCoins = 20;
                if(appSettings && appSettings.URGENT_REQUEST_COINS) {
                    minCoins = appSettings.URGENT_REQUEST_COINS.value;
                }
                if(payload.form.is_urgent && profile.credits < minCoins) {
                    return <div className='text-center'>
                        <span className='text-danger'>Insufficient coins for posting Urgent Enquiry</span><br />
                        <button className="btn btn-theme btn-sm" disabled>Post {profile.user_type==='TEACHER'?'Announcement':'Enquiry'} for {payload.enquiry_type==='REQUEST'?payload.form.is_urgent?(appSettings && appSettings.URGENT_REQUEST_COINS?appSettings.URGENT_REQUEST_COINS.value+' coins':'Free'):'Free':'Free'}</button>
                    </div>
                } else {
                    return <button className="btn btn-theme btn-sm" onClick={()=>{
                        postEnquiry();
                    }}>Post {profile.user_type==='TEACHER'?'Announcement':'Enquiry'} for {payload.enquiry_type==='REQUEST'?payload.form.is_urgent?(appSettings && appSettings.URGENT_REQUEST_COINS?appSettings.URGENT_REQUEST_COINS.value+' coins':'Free'):'Free':'Free'}</button>
                }
            }
        } else {
            let minCoins = 20;
            if(appSettings && appSettings.URGENT_REQUEST_COINS) {
                minCoins = appSettings.URGENT_REQUEST_COINS.value;
            }
            if(payload.form.is_urgent && profile.credits < minCoins) {
                return <div className='text-center'>
                    <span className='text-danger'>Insufficient coins for posting Urgent Enquiry</span><br />
                    <button className="btn btn-theme btn-sm" disabled>Post {profile.user_type==='TEACHER'?'Announcement':'Enquiry'} for {payload.enquiry_type==='REQUEST'?payload.form.is_urgent?(appSettings && appSettings.URGENT_REQUEST_COINS?appSettings.URGENT_REQUEST_COINS.value+' coins':'Free'):'Free':'Free'}</button>
                </div>
            } else {
                return <button className="btn btn-theme btn-sm" onClick={()=>{
                    postEnquiry();
                }}>Post {profile.user_type==='TEACHER'?'Announcement':'Enquiry'} for {payload.enquiry_type==='REQUEST'?payload.form.is_urgent?(appSettings && appSettings.URGENT_REQUEST_COINS?appSettings.URGENT_REQUEST_COINS.value+' coins':'Free'):'Free':'Free'}</button>
            }
        }
    }

    const renderModalContent = () => {
        return (
            <div className="row margin-0 justify-content-center">
                {popupMode !== null && popupMode === "INSUFFICIENT_COINS" && (
                    <div className="col-12 pad-0 text-center">
                        <h4>Insufficient coins balance.</h4>
                        <div className="text-center pad-10">
                            <button
                            className="btn btn-theme btn-sm margin-right-10"
                            onClick={() => {
                                history.push("/my-wallet");
                            }}
                            >
                            Add Coins
                            </button>
                            <button
                            className="btn btn-plain btn-sm"
                            onClick={() => {
                                setPopupMode(null);
                            }}
                            >
                            Cancel
                            </button>
                        </div>
                        </div>
                    )}
            </div>
        )
    };

    return (
        <div className="row margin-0">
            <div className="col-12 pad-0">
                <div className="banner-block pad-15">
                    <div className="row margin-0">
                        <div className="col-6 pad-0">
                            <h4 className="txt-theme bold margin-bottom-15">New {profile.user_type==='TEACHER'?'Announcement':'Enquiry'}</h4>
                        </div>
                        <div className="col-6 text-right pad-0 pad-bottom-10">
                            {
                                stage === 'form' && <span>Didn't found course? <br /><Link className="txt-theme" style={{textDecoration: 'underline', fontSize: '14px'}} to="/inbox?action=ADD_NEW_COURSE">Request to add Course</Link></span>
                            }
                        </div>
                    </div>
                    
                    {
                        lookups === null?(
                            <div>
                                Loading....
                            </div>
                        ):(
                            <div>
                                {
                                    stage === 'form' && (
                                        <div>
                                            <div className="row">
                                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 pad-bottom-10">
                                                    <InputCourse value={seletedCourse} label="Topic" isRequired={true} selected={true} setValue={(c)=>{
                                                        setSeletedCourse(c);
                                                        getQuestionairee(c);
                                                    }} mode={'INPUT'} />
                                                    {getErrorMessage('course')}
                                                </div>
                                                {
                                                    payload.enquiry_type === 'REQUEST' && <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 pad-top-20">
                                                        <div className="form-check" style={{display: 'block'}}>
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(event)=>{
                                                                setPayload({
                                                                    ...payload,
                                                                    form: {
                                                                        ...payload.form,
                                                                        is_urgent: event.target.checked
                                                                    }
                                                                })
                                                            }} />
                                                            <label className="form-check-label" for="flexCheckDefault">
                                                                Is Urgent
                                                            </label>
                                                        </div>
                                                        <span className='d-block'>Requires {appSettings && appSettings.URGENT_REQUEST_COINS?appSettings.URGENT_REQUEST_COINS.value:20} coins to flag. <Link className='btn-underline' style={{fontSize: '0.8rem'}} to="/my-wallet">Add coins</Link></span>
                                                    </div>
                                                }
                                            </div>
                                            <div className="row">
                                                {payload.enquiry_type === 'ANNOUNCEMENT' && renderDynamicInput('Free Demo', 'free_demo', 'ENQUIRY_FREE_DEMO')}
                                                {renderDynamicInput('Mode', 'mode', 'ENQUIRY_MODE')}
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                                    <IGInput
                                                        type="location"
                                                        label={'Location'}
                                                        placeholder="Location"
                                                        value={payload.form.location}
                                                        error={error.location}
                                                        setValue={(val) => {
                                                            setPayload({
                                                                ...payload,
                                                                form: {
                                                                    ...payload.form,
                                                                    location: val
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                {/* {renderDynamicInput('Skill Level', 'skill', 'ENQUIRY_SKILL')} */}
                                                {renderDynamicInput('Type', 'type', 'ENQUIRY_TYPE')}
                                                {renderDynamicInput('Assignment', 'assignment', 'ENQUIRY_ASSIGNMENT')}
                                                {renderDynamicInput('Frequency', 'frequency', 'ENQUIRY_FREQUENCY')}
                                                {renderDynamicInput('Language', 'language', 'ENQUIRY_LANGUAGE')}
                                                {payload.enquiry_type === 'REQUEST' && renderDynamicInput('Student Count', 'student_count', 'ENQUIRY_STUDENT_COUNT')}
                                                {renderDynamicInput('Fee Type', 'fee_type', 'ENQUIRY_FEE_TYPE')}
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                                    <IGInput
                                                        type="number"
                                                        label={`Estimated Fee in ${profile.region}`}
                                                        placeholder="Estimated Fee"
                                                        maxLength={6}
                                                        value={payload.form.fee}
                                                        error={error.fee}
                                                        setValue={(val) => {
                                                            setPayload({
                                                                ...payload,
                                                                form: {
                                                                    ...payload.form,
                                                                    fee: val
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                {renderDynamicInput('Fee Negotiable', 'fee_negotiable', 'ENQUIRY_FEE_NEGOTIABLE')}
                                                {
                                                    payload.enquiry_type==='ANNOUNCEMENT' && (<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                                        <div className="form-group">
                                                            <label>Start Date</label>
                                                            <input type="date" className="form-control form-control-sm" value={payload.form.start_date} placeholder="Start Date" onChange={(e) => {
                                                                setPayload({
                                                                    ...payload,
                                                                    form: {
                                                                        ...payload.form,
                                                                        start_date: e.target.value
                                                                    }
                                                                })
                                                            }} />
                                                            {getErrorMessage('start_date')}
                                                        </div>
                                                    </div>)
                                                }
                                                {
                                                    payload.enquiry_type==='ANNOUNCEMENT' && (<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                                        <div className="form-group">
                                                            <label>Respond By</label>
                                                            <select name="" id="" className="form-control form-control-sm" value={payload.form.respond_by} placeholder="Select Days" onChange={(e) => {
                                                                setPayload({
                                                                    ...payload,
                                                                    form: {
                                                                        ...payload.form,
                                                                        respond_by: e.target.value
                                                                    }
                                                                })
                                                            }}>
                                                                <option value="null">Select</option>
                                                                {
                                                                    lookups && lookups.ENQUIRY_RESPOND_BY && lookups.ENQUIRY_RESPOND_BY.codes.map((item, index) => (
                                                                        <option key={index} value={item.code}>{item.description}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            {getErrorMessage('respond_by')}
                                                        </div>
                                                    </div>)
                                                }
                                                {
                                                    payload.enquiry_type==='REQUEST'?<div className="col-12">
                                                        <p className='margin-bottom-0'><b>Is Urgent: </b> Enquiry will be flagged as Priority, which costs you {appSettings && appSettings.URGENT_REQUEST_COINS?appSettings.URGENT_REQUEST_COINS.value:20} coins, choose only if needed. Otherwise it's <b>FREE</b>.</p>
                                                        <p className='margin-bottom-0'><b>Post Enquiry: </b> This action will send alert to all matching teachers. Teachers will unlock your details to contact you.</p>
                                                    </div>:<div className="col-12">
                                                        <p className='margin-bottom-0'><b>Post Announcement: </b> This action will send alert to all matching students. If you have premium membership few students can contact you free of cost.</p>
                                                    </div>
                                                }
                                                {/* {
                                                    !payload.mobile_verified && <div className="text-center col-12 pad-0">
                                                        <span className='text-danger'>Please verify phone to continue.</span>
                                                    </div>
                                                } */}
                                                {
                                                    seletedCourseData!==null && (
                                                        payload.enquiry_type==='ANNOUNCEMENT'?(
                                                            <div className="col-12 text-center pad-top-15">
                                                                {renderAnnouncementBtn()}
                                                            </div>
                                                        ):(
                                                            <div className="col-12 text-center pad-top-15">
                                                                {renderActionBtn()}
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    stage === 'questionairee' && (
                                        <div className="row">
                                            <div className="col-12">
                                                <p className='semi-bold'>Answer below question for proceeding</p>
                                                {
                                                    seletedCourseData && seletedCourseData.questionnaire && seletedCourseData.questionnaire.map((item, idx) => (
                                                        <div className='row margin-0 margin-bottom-5 margin-top-5' style={{borderBottom: '1px solid #ddd'}} key={idx}>
                                                            <div className="col-10 pad-0">
                                                                {item.question}
                                                            </div>
                                                            <div className="col-2 text-right pad-right-0">
                                                                {
                                                                    item.options.length>0?(
                                                                        <div>
                                                                            <select name="" id="" className="form-control form-control-sm" value={payload.questionnaire[item.id].value} placeholder="Enter assignment" onChange={(e) => {
                                                                                setPayload({
                                                                                    ...payload,
                                                                                    questionnaire: {
                                                                                        ...payload.questionnaire,
                                                                                        [item.id]: {
                                                                                            ...payload.questionnaire[item.id],
                                                                                            value: e.target.value
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }}>
                                                                                <option value="null">Select Type</option>
                                                                                {
                                                                                    item.options.map((itm, index) => (
                                                                                        <option key={index} value={itm}>{itm}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    ):(<input type="checkbox" value={payload.questionnaire[item.id]} onChange={(e)=>{
                                                                        setPayload({
                                                                            ...payload,
                                                                            questionnaire: {
                                                                                ...payload.questionnaire,
                                                                                [item.id]: {
                                                                                    ...payload.questionnaire[item.id],
                                                                                    value: e.target.checked
                                                                                }
                                                                            }
                                                                        })
                                                                    }} name="" id="" />)
                                                                }
                                                            </div>
                                                            <div className="col-12">
                                                                {getErrorMessage(item.id)}
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className="col-12 text-center">
                                                {renderActionBtn(2)}
                                                {/* <button className="btn btn-theme btn-sm" disabled={payload.enquiry_type==='REQUEST' && payload.form.is_urgent && profile.credits<20} onClick={()=>{
                                                    postEnquiry();
                                                }}>Post {profile.user_type==='TEACHER'?'Announcement':'Enquiry'} for {payload.enquiry_type==='REQUEST'?payload.form.is_urgent?(appSettings && appSettings.URGENT_REQUEST_COINS?appSettings.URGENT_REQUEST_COINS.value+' coins':'Free'):'Free':'Free'}</button> */}
                                                <button className="btn btn-plain btn-sm margin-left-10" onClick={()=>{
                                                    setStage('form');
                                                }}>Back</button>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
            <IGModal visible={popupMode!==null} body={renderModalContent} close={()=>{setPopupMode(null)}} />
        </div>
    )
};

export default NewEnquiry;

