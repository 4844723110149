import { useState, useRef, useEffect } from 'react';


const useComponentVisible = (initialIsVisible) => {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref, isComponentVisible, setIsComponentVisible };
}


const InputSelect = (props) => {
    const { type, label, error, id, name, value, placeholder='Enter Text', setValue, rows=3, cols, options, isRequired, disabled, multiple } = props;
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [selectedValue, setSelectedValue] = useState(value);

    const setSelection = (val) => {
        if(multiple) {
            if(selectedValue.indexOf(val) !==-1) {
                let selectedValueRef = [...value];
                const iddx = value.indexOf(val);
                selectedValueRef.splice(iddx, 1);
                setSelectedValue(selectedValueRef);
                setValue(selectedValue);
            } else {
                setSelectedValue([val, ...selectedValue]);
                setValue(selectedValue);
                setIsComponentVisible(true);
            }
        } else {
            setSelectedValue(val);
            setValue(val);
        }
    }

    useEffect(()=>{
        setValue(selectedValue);
    }, [selectedValue]);

    const getActiveClass = (item) => {
        if(multiple && value.indexOf(item)!==-1) {
            return 'active';
        } else if(value===item) {
            return 'active';
        }
        return '';
    };

    const renderOptions = () => {
        if(!options || options.length === 0) {
            return <li>No Options</li>
        } else {
            return options.map((item, idx) => {
                if(typeof item === "object") {
                    return <li key={idx} className={getActiveClass(item[0])} onClick={(event)=>{
                        setSelection(item[0]);
                        event.preventDefault();
                    }}>{item[1]}</li>
                }
                return <li key={idx} className={getActiveClass(item)} onClick={(event)=> {
                    setSelection(item);
                    event.preventDefault();
                }}>{item}</li>
            });
        }
    }

    const renderSelectedValue = () => {
        if(value) {
            let selectionLabel = value;
            if(multiple) {
                selectionLabel = "";
                value.forEach((ele, idx) => {
                    options.forEach(item => {
                        if(typeof item === "object" && ele === item[0]) {
                            selectionLabel += idx===0?item[1]:`, ${item[1]}`;
                        } else if(ele === item) {
                            selectionLabel += idx===0?item:`, ${item}`;
                        }
                    });
                });
                if(selectionLabel === "") {
                    return placeholder || 'Select';
                }
                return selectionLabel;
            } else {
                options.forEach(item => {
                    if(typeof item === "object" && value === item[0]) {
                        selectionLabel = item[1];
                    }
                });
            }
            return selectionLabel;
        }
        return placeholder || 'Select';
    };

    return <div 
        className={`form-control ig-input-select ig-form-input ${error?' ig-border-error':''}`}
        ref={ref}
        onClick={()=>setIsComponentVisible(!isComponentVisible)}
        >
            <span style={{width:'80%', maxWidth:'80%',textOverflow: 'ellipsis', whiteSpace: 'nowrap',overflow: 'hidden', display: 'inline-block'}}>{renderSelectedValue()}</span>
            {
                isComponentVisible && <span className="fa fa-angle-up f-right" style={{padding: '1%'}}></span>
            }
            {
                !isComponentVisible && <span className="fa fa-angle-down f-right" style={{padding: '1%'}}></span>
            }
            {
                isComponentVisible && <div className="ig-input-select-options">
                    <ul className="options-list">
                        {renderOptions()}
                    </ul>
                </div>
            }
        </div>
};

export default InputSelect;