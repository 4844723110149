import { useState, useRef, useEffect } from 'react';
import './IGInput.css';
import InputLocation from './InputLocation.jsx';
import InputPhone from './InputPhone.jsx';
import InputSelect from './InputSelect.jsx';
import InputFile from './InputFile.jsx';

const IGInput = (props) => {
    const { type, label, error, id, name, value, placeholder = 'Enter Text', setValue, rows = 3, cols, options, isRequired, disabled, onKeyEnter=null, helpBox=null, styles={}, infoLabel=null, pattren=null, maxLength=null } = props;

    const renderInput = () => {
        if (type === 'select') {
            return <InputSelect {...props} />
        } else if (type === 'phone') {
            return <InputPhone {...props} className={`form-control ig-form-input ${error ? ' ig-border-error' : ''}`} />
        } else if (type === 'location') {
            return <InputLocation {...props} className={`form-control ig-form-input ${error ? ' ig-border-error' : ''}`} />
        } else if (type === 'file') {
            return <InputFile {...props} className={`form-control ig-form-input ${error ? ' ig-border-error' : ''}`} />
        } else if (type === 'textarea') {
            return <textarea
                id={id}
                name={name}
                value={value}
                rows={rows}
                cols={cols}
                disabled={disabled}
                onChange={(event) => {
                    setValue(event.target.value)
                }}
                placeholder={placeholder}
                className={`form-control ig-form-input ${error ? ' ig-border-error' : ''}`}
                style={{ height: (document.body && document.body.offsetWidth < 1000 && value && value.length > 500)?`${value.length / 2}px`:'auto' }} >
            </textarea>
        } else if (type === 'label') {
            return <div className='form-control ig-form-input disabled'>
                {value || label}
            </div>
        } else if (type === 'password') {
            return <>
                <input
                    id={id}
                    name={name}
                    type={type}
                    value={value}
                    maxLength={20}
                    disabled={disabled}
                    autoComplete={false}
                    onChange={(event) => {
                        setValue(event.target.value)
                    }}
                    placeholder={placeholder}
                    className={`form-control ig-form-input ${error ? ' ig-border-error' : ''}`}
                />
                <img src="/ic_look.png" style={{ display: "inline-block" }} alt="" className="float-right" />
            </>
        }
        return <input
            id={id}
            name={name}
            type={type}
            value={value}
            disabled={disabled}
            autoComplete={false}
            maxLength={maxLength?maxLength:30}
            pattern={pattren}
            onChange={(event) => {
                setValue(event.target.value)
            }}
            onKeyPress={(event)=>{
                if(event.key === "Enter" && onKeyEnter !== null) {
                    onKeyEnter(event);
                }
            }}
            placeholder={placeholder}
            className={`form-control ig-form-input ${error ? ' ig-border-error' : ''}`} />

    };

    return (
        <div className="ig-input" style={styles}>
            {label && <label className='ig-input-label'>{label} {isRequired && <span className='text-danger'>*</span>} {infoLabel && <span className='text-secondary' style={{fontWeight: '400'}}>({infoLabel})</span>} {
                helpBox?<div className="help-box f-right text-right">
                    <span className="fa fa-info-circle c-pointer" style={{marginTop: '8px'}}></span>
                    <div className="help-box-content">
                        {helpBox()}
                    </div>
                </div>:''
            }</label>}
            <div className='ig-input-frame'>
                {renderInput()}
            </div>
            {error && <span className='ig-error-text'>{error}</span>}
        </div>
    )
};

export {
    IGInput
};