import axios, { AxiosError, CancelToken } from 'axios';

export const baseAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER_URL || 'http://localhost:8000',
});

baseAxiosInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('Token') || null;
    if (token) {
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
});

export const updateApiToken = (token) => {
    baseAxiosInstance.defaults.headers.common[
        'Authorization'
    ] = `Bearer ${localStorage.getItem('Token')}` || '';
};

const sendStats = (a,b) => {};

export const handleError = (error) => {
  if (error.config && error.response) {
    switch (error.response.status) {
      case 400:
        if(typeof error.response.data === 'object') {
          Object.keys(error.response.data).forEach(
            (field) => (error.response.data[field] = typeof error.response.data[field]==='object'?error.response.data[field][0]:error.response.data[field])
          );
        }
        break;
      case 401:
        localStorage.clear();
        window.location.href = "/";
        break;
      case 403:
        break;
      case 404:
      case 405:
      case 500:
        sendStats(error.response.status, error.response.data);
        break;
    }
  }
  return Promise.reject(error);
};

baseAxiosInstance.interceptors.response.use(undefined, handleError);

export const getCancelRequestToken = () => axios.CancelToken.source();


class REST {
    pageSize = 30;
    api = '';

    // eslint-disable-next-line
    get token() {
        return localStorage.getItem("Token");
    }

    list({ resource = '', params = { active: true }, pageSize = null }) {
        // console.log(process.env.REACT_APP_API_SERVER_URL);
        const parameters = params;
        let resourceUrl = resource;
        if (resourceUrl === '') {
            resourceUrl = this.api;
        }
        parameters.limit = pageSize === null ? this.pageSize : pageSize;
        return baseAxiosInstance.get(resourceUrl, {
            params: parameters
        });
    }

    retrive({ resource = '', params = {}, page = 1, pageSize = null }) {
        const parameters = params;
        let resourceUrl = resource;
        if (resourceUrl === '') {
            resourceUrl = this.api;
        }
        parameters.page = page;
        parameters.limit = pageSize === null ? this.pageSize : pageSize;
        return baseAxiosInstance.get(resourceUrl, {
            params: parameters
        });
    }

    create({ resource = '', params = {}, data = {}, page = 1, pageSize = null }) {
        const parameters = params;
        let resourceUrl = resource;
        if (resourceUrl === '') {
            resourceUrl = this.api;
        }
        parameters.page = page;
        parameters.limit = pageSize === null ? this.pageSize : pageSize;
        return baseAxiosInstance({
            method: 'post',
            url: resourceUrl,
            data,
            params: parameters
        });
    }

    update({ resource = '', params = {}, data = {} }) {
        const parameters = params;
        let resourceUrl = resource;
        if (resourceUrl === '') {
            resourceUrl = this.api;
        }
        return baseAxiosInstance({
            method: "put",
            url: resourceUrl,
            data,
            params: parameters
        });
    }

    destroy({ resource = '', params = {} }) {
        const parameters = params;
        let resourceUrl = resource;
        if (resourceUrl === '') {
            resourceUrl = this.api;
        }
        return baseAxiosInstance({
            method: "delete",
            url: resourceUrl,
            params: parameters
        });
    }

    // To generate the signed link for upload to S3
    getSignedLinkForUpload({fileName = '',
        fileType = '',
        access = true,
        params = {},
        resource = '',
        type = "assets"
    }) {
        const parameters = params;
        let resourceUrl = resource;
        if (resourceUrl === '') {
            resourceUrl = `${process.env.REACT_APP_API_SERVER_URL}/api/get-signed-url/`;
        }
        return baseAxiosInstance({
            method: "post",
            url: resourceUrl,
            data: {
                file_name: fileName,
                file_type: fileType,
                public: access,
                type
            },
            params: parameters
        });
    }
}

// Authentication
class Authentication {
    // To remove the token from localstorage
    removeLocalStorage = () => {
      localStorage.clear();
    };
  
    signup(data = {}) {
      return baseAxiosInstance({
        method: "post",
        url: `${process.env.REACT_APP_API_SERVER_URL}/signup/`,
        data
      });
    }
  
    // To login using email & password
    login(data) {
      return baseAxiosInstance({
        method: "post",
        url: `${process.env.REACT_APP_API_SERVER_URL}/login/`,
        data
      });
    }
  
    otp(data) {
      return baseAxiosInstance({
        method: "post",
        url: `${process.env.REACT_APP_API_SERVER_URL}/otp/`,
        data
      });
    }
  
    // To remove the token from server & localstorage
    logout() {
      this.removeLocalStorage();
      window.location.href = '/';
    }
  
    // send reset password link
    forgotPassword(email) {
      return baseAxiosInstance({
        method: "post",
        url: `${process.env.REACT_APP_API_SERVER_URL}/forgot-password/`,
        data: {
          email
        }
      });
    }
  
    // Check reset password link
    checkResetPasswordLink(id) {
      return baseAxiosInstance({
        method: "post",
        url: `${process.env.REACT_APP_API_SERVER_URL}/check-password/${id}/`
      });
    }
  
    // Check & Reset Password
    checkAndResetPassword(id, password) {
      return baseAxiosInstance({
        method: "post",
        url: `${process.env.REACT_APP_API_SERVER_URL}/reset-password/${id}/`,
        data: {
          password
        }
      });
    }
  }

class SearchProduct extends REST {
    api = `${process.env.REACT_APP_API_SERVER_URL}/api/search-product/`;
}

class Plan extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/plan/`;
}

class Subscription extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/subscription/`;
}

class Category extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/category/`;
}

class SubCategory extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/sub-category/`;
}

class Course extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/course/`;
}

class Enquiry extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/enquiry/`;
}

class Alert extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/alert/`;
}

class Transaction extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/transaction/`;
}

class Action extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/action/`;
}

class Profile extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/profile/`;
}

class Enrollment extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/enrollment/`;
}

class User extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/admin/user/`;
}

class Profiles extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/profiles/`;
}

class Taxes extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/admin/tax/`;
}

class AnyAction extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/any-action/`;
}

class Pricing extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/pricing/`;
}

class Lookup extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/lookup/`;
}

class Settings extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/setting/`;
}

class OTP extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/otp/`;
}

class Demo extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/demo/`;
}

class Banner extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/banner/`;
}

class Discount extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/admin/discount/`;
}

class Coupon extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/admin/coupon/`;
}

class Contact extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/contact/`;
}

class Events extends REST {
  api = `${process.env.REACT_APP_API_SERVER_URL}/api/event/`;
}

class ImageUtil extends REST {
	upload(File, path = "public", access = true) {
    const that = this;
		return new Promise((resolve, reject) => {
			that
				.getSignedLinkForUpload({
					fileName: Math.random().toString(36).substr(2, 9)+'.'+File.name.split('.').pop(),
					fileType: File.type,
					access: access,
					type: path,
				})
				.then(
					(response) => {
						const signedData = response.data;
						const options = {
							headers: {
								"Content-Type": File.type,
							},
							onUploadProgress(progressEvent) {},
						};
						axios
							.put(signedData.signed_url, File, options)
							.then(() => {
								resolve(signedData.url);
							})
							.catch(() => {
								reject("Unable to Upload file");
							});
					},
					(err) => {
						reject("Unable to Get Signed URL");
					}
				);
		});
	}
}

const $cs = {
    REST,
    product: new SearchProduct(),
    auth: new Authentication(),
    plan: new Plan(),
    event: new Events(),
    subscription: new Subscription(),
    category: new Category(),
    subcategory: new SubCategory(),
    course: new Course(),
    enquiry: new Enquiry(),
    transaction: new Transaction(),
    action: new Action(),
    profile: new Profile(),
    profiles: new Profiles(),
    alert: new Alert(),
    anyAction: new AnyAction(),
    img: new ImageUtil(),
    tax: new Taxes(),
    coupon: new Coupon(),
    pricing: new Pricing(),
    lookup: new Lookup(),
    setting: new Settings(),
    user: new User(),
    otp: new OTP(),
    banner: new Banner(),
    discount: new Discount(),
    coupon: new Coupon(),
    demo: new Demo(),
    contact: new Contact(),
    enrollment: new Enrollment()
}

export default $cs;
