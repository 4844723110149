import EnquiriesList from './components/EnquiriesList';

const Announcements = (props) => {

    return (
        <div className="row margin-0">
            <div className="col-12 pad-0">
                <EnquiriesList enquiryType={'ANNOUNCEMENT'} />
            </div>
        </div>
    )
};

export default Announcements;

