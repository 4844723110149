import './App.css';
import { Spinner, Container, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import Base from "./skeltons/Base.jsx";

function App(props) {
  const [isLoading, setLoading] = useState(false);

  setTimeout(() => {
    setLoading(false);
  }, 1000);

  const renderMainApp = () => {
    if(isLoading) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        </Container>
      )
    }
    return <Base/>
  }

  return (
    <div style={{minHeight: '100vh'}}>
      {renderMainApp()}
    </div>
  );
}

export default App;
