import EnquiriesList from './components/EnquiriesList';

const Enquiries = (props) => {
    return (
        <div className="row margin-0">
            <div className="col-12 pad-0">
                <EnquiriesList enquiryType={'REQUEST'} />
            </div>
        </div>
    )
};

export default Enquiries;
