import React from "react";
import ReactDOM from "react-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// External SCSS file start Here
import "./scss/app.scss";
import "./scss/components.scss";
// External SCSS file end Here
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { Button } from "react-bootstrap";

const MyAppContext = React.createContext(null);

const contextValue = {
  Button,
};

ReactDOM.render(
  <React.StrictMode>
    <MyAppContext.Provider value={contextValue}>
      <Provider store={store}>
        <App />
      </Provider>
    </MyAppContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
