import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import $cs from '../services.js'

export const getProfile = createAsyncThunk(
    'user/getProfile',
    async (thunkAPI) => {
        const response = await $cs.profile.list({}).then(res => res.data);
        return response
    }
)

export const getRealTimeProfile = createAsyncThunk(
    'user/getRealTimeProfile',
    async (thunkAPI) => {
        const response = await $cs.profile.list({}).then(res => res.data);
        localStorage.setItem('profile', JSON.stringify(response));
        return response
    }
)

export const getProfiles = createAsyncThunk(
    'user/getProfiles',
    async (thunkAPI) => {
        const response = await $cs.profiles.list({}).then(res => res.data);
        return response
    }
)

export const getEnquires = createAsyncThunk(
    'user/getEnquires',
    async (thunkAPI) => {
        const response = await $cs.enquiry.list({}).then(res => res.data);
        return response
    }
)

export const getActions = createAsyncThunk(
    'user/getActions',
    async (thunkAPI) => {
        const response = await $cs.user.list({
            resource: `${$cs.user.api}actions/`
        }).then(res => res.data);
        return response
    }
)

export const getEnrollments = createAsyncThunk(
    'user/getEnrollments',
    async (page=null, thunkAPI) => {
        const response = await $cs.enquiry.list({
            resource: page || `${$cs.enquiry.api}enrollments/`,
            pageSize: 5
        }).then(res => res.data);
        return response
    }
)

export const getNotifications = createAsyncThunk(
    'user/getNotifications',
    async (thunkAPI) => {
        const response = await $cs.alert.list({
            pageSize: 20
        }).then(res => res.data);
        return response
    }
)

export const markAllAsRead = createAsyncThunk(
    'user/getNotifications',
    async (thunkAPI) => {
        const response = await $cs.alert.list({
            resource: `${$cs.alert.api}read/`,
            pageSize: 20
        }).then(res => res.data);
        return response
    }
)

export const getStats = createAsyncThunk(
    'user/getStats',
    async (thunkAPI) => {
        const response = await $cs.stats.list({}).then(res => res.data);
        return response
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isLoading: true,
        profile: JSON.parse(localStorage.getItem('profile')) || null,
        realTimeProfile: JSON.parse(localStorage.getItem('profile')) || null,
        credits: 0,
        skills: null,
        stats: null,
        enquiries: null,
        enrollments: null,
        actions: null,
        actionsObj: null,
        notifications: null,
        unreadNotifications: false,
        plan: JSON.parse(localStorage.getItem('profile'))?(JSON.parse(localStorage.getItem('profile')).plan || null):null,
        profiles: null,
        currentProfile: null,
        userType: null,
        rules: null,
        currentUserType: localStorage.getItem('user_type') || null,
    },
    reducers: {
        setProfile: (state, action) => {
            state.profile = action.payload;
            state.realTimeProfile = action.payload;
            state.userType = action.payload.user_type;
            state.plan = action.payload.plan;
            if(action.payload.plan && action.payload.plan.plan_details && action.payload.plan.plan_details.rules) {
                state.rules = action.payload.plan.plan_details.rules;
            }
            state.credits = action.payload.credits;
        },
        setCredits: (state, action) => {
            state.credits = action.payload;
        },
        setCurrentProfile: (state, action) => {
            state.currentProfile = action.payload;
        },
        resetUserState: (state, action) => {
            state.isLoading = null;
            state.profile = null;
            state.skills = null;
            state.enquiries = null;
            state.notifications = null;
            state.unreadNotifications = false;
            state.plan = null;
            state.profiles = null;
            state.currentProfile = null;
            state.userType = null;
            state.currentUserType = null;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getProfile.fulfilled, (state, action) => {
            state.profile = action.payload;
            localStorage.setItem('profile', JSON.stringify(action.payload));
            state.isLoading = false;
            state.userType = action.payload.user_type;
            state.plan = action.payload.plan;
            if(action.payload.plan && action.payload.plan.plan_details && action.payload.plan.plan_details.rules) {
                state.rules = action.payload.plan.plan_details.rules;
            }
            // if(action.payload && action.payload.user_type) {
            //     state.plan = action.payload.plan;
            //     state.userType = action.payload.user_type;
            //     if(state.currentUserType===null) state.currentUserType=action.payload.user_type;
            // }
        })
        .addCase(getProfiles.fulfilled, (state, action) => {
            state.profiles = action.payload;
            if(action.payload.results.length > 0) {
                state.currentProfile = action.payload.results[0];
                state.skills = action.payload.results[0].courses_detail;
            } else {
                state.skills = [];
            }
            // if(state.currentUserType !== null) {
            //     const userProfiles = action.payload.results.filter(item => item.profile_type === state.currentUserType);
            //     state.currentProfile = userProfiles.length>0?userProfiles[0]:null;
            // } else if(action.payload.results.length > 0) {
            //     const userProfiles = action.payload.results.filter(item => item.profile_type === state.userType);
            //     state.currentProfile = userProfiles.length>0?userProfiles[0]:null;
            // }
        })
        .addCase(getEnquires.fulfilled, (state, action) => {
            state.enquiries = action.payload;
        })
        .addCase(getActions.fulfilled, (state, action) => {
            let actionsObj = {};
            action.payload.forEach(item => {
                if(item.status !== 'COMPLETED' && item.status !== 'REJECTED') {
                    if(!actionsObj[item.action]) {
                        actionsObj[item.action] = [];
                    }
                    actionsObj[item.action].push(item);
                }
            });
            state.actions = action.payload;
            console.log(actionsObj);
            state.actionsObj = actionsObj;
        })
        .addCase(getEnrollments.fulfilled, (state, action) => {
            if(state.enrollments) {
                // const oldResults = state.enrollments.results;
                const newResults = action.payload.results;
                state.enrollments = {
                    ...action.payload,
                    results: [
                        // ...oldResults,
                        ...newResults
                    ]
                }
            } else {
                state.enrollments = action.payload;
            }
        })
        .addCase(getNotifications.fulfilled, (state, action) => {
            if(state.notifications && action.payload && action.payload.start!==1) {
                const oldResults = state.notifications.results;
                const newResults = action.payload.results;
                state.notifications = {
                    ...action.payload,
                    results: [
                        ...oldResults,
                        ...newResults
                    ]
                }
            } else {
                state.notifications = action.payload;
            }
            if(state.notifications.results) {
                const ct = state.notifications.results.filter(itm => itm.status==='CREATED');
                state.unreadNotifications = ct.length>0;
            }
        })
        .addCase(getStats.fulfilled, (state, action) => {
            state.stats = action.payload;
        })
        .addCase(getRealTimeProfile.fulfilled, (state, action) => {
            state.realTimeProfile = action.payload;
            state.userType = action.payload.user_type;
            state.plan = action.payload.plan;
            if(action.payload.plan && action.payload.plan.plan_details && action.payload.plan.plan_details.rules) {
                state.rules = action.payload.plan.plan_details.rules;
            }
        })
    },
});

export const { setProfile, setCurrentProfile, resetUserState, setCredits } = userSlice.actions;

export const realTimeProfileSelector = state => state.user.realTimeProfile;
export const profileSelector = state => state.user.profile;
export const profilesSelector = state => state.user.profiles;
export const actionsSelector = state => state.user.actions;
export const actionsObjSelector = state => state.user.actionsObj;
export const skillsSelector = state => state.user.skills;
export const notificationSelector = state => state.user.notifications;
export const unreadNotificationSelector = state => state.user.unreadNotifications;
export const planSelector = state => state.user.plan;
export const currentProfileSelector = state => state.user.currentProfile;
export const userTypeSelector = state => state.user.currentUserType;
export const enquiriesSelector = state => state.user.enquiries;
export const enrollmentsSelector = state => state.user.enrollments;
export const rulesSelector = state => state.user.rules;
export const creditssSelector = state => state.user.credits;
export const ruleSelector = state => {
    return state.user.realTimeProfile;
};

export default userSlice.reducer;
