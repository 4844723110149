import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Button, Spinner, Container, Row, Col } from 'react-bootstrap';
import Authenticate from "./Authenticate";
import $cs from '../services.js'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { realTimeProfileSelector, setProfile, getProfile, getProfiles, currentProfileSelector, setCurrentProfile } from '../store/userReducer';

const Course = (props) => {
    const dispatch = useDispatch();
    // const valueSelector = useSelector();
    const profile = useSelector(realTimeProfileSelector);
    const history = useHistory();
    const { courseId } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [stage, setStage] = useState(0);
    const [course, setCourse] = useState(null);
    const [payload, setPayload] = useState({
        course: courseId,
        enquiry_type: 'REQUEST',
        questionnaire: null,
        form: {
            location: 'Hyderabad',
            assignment: 'Test',
            skill: 'AWS',
            frequency: '45 days',
            language: 'English',
            mode: 'Online',
            comments: 'Test comments for testing',
        }
    });

    const getCourses = () => {
        $cs.course.list({
            resource: `${$cs.course.api}${courseId}/`,
        }).then(res => {
            setCourse(res.data);
            setLoading(false);
            setStage(1);
        }, err => {
        });
    };
    
    const postEnquiry = () => {
        $cs.enquiry.create({
            data: payload
        }).then(res => {
            dispatch(getProfiles());
            history.push("/dashboard");
        }, err => {
        });
    };

    useEffect(() => {
        if(isLoading && course === null) {
            getCourses();
        }
    });

    const handleAction = (action, value) => {
        switch(action) {
            case "stage":
                setStage(value);
                break;
        }
    };

    const renderSteps = () => {
        if(stage === 1) {
            console.log(course);
            return (
                <div className="row margin-0">
                    <div className="col-12 pad-10" style={{borderBottom: '1px solid #ddd'}}>
                        <h3><b>{course.title}</b></h3>
                        {
                            course && course.sub_category && <h4>{course.sub_category.title}</h4>
                        }
                    </div>
                    <div className="col-12 pad-10" style={{minHeight:'30vh'}}>
                        <div className="row margin-0">
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="">Location</label>
                                    <input type="text" className="form-control form-control-sm" value={payload.form.location} placeholder="Enter location" onInput={(e) => {
                                        setPayload({
                                            ...payload,
                                            form: {
                                                ...payload.form,
                                                location: e.target.value
                                            }
                                        })
                                    }} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="">Assignment</label>
                                    <input type="text" className="form-control form-control-sm" value={payload.form.assignment} placeholder="Enter assignment" onInput={(e) => {
                                        setPayload({
                                            ...payload,
                                            form: {
                                                ...payload.form,
                                                assignment: e.target.value
                                            }
                                        })
                                    }} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="">Skill</label>
                                    <input type="text" className="form-control form-control-sm" value={payload.form.skill} placeholder="Enter skill" onInput={(e) => {
                                        setPayload({
                                            ...payload,
                                            form: {
                                                ...payload.form,
                                                skill: e.target.value
                                            }
                                        })
                                    }} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="">Frequency</label>
                                    <input type="text" className="form-control form-control-sm" value={payload.form.frequency} placeholder="Enter frequency" onInput={(e) => {
                                        setPayload({
                                            ...payload,
                                            form: {
                                                ...payload.form,
                                                frequency: e.target.value
                                            }
                                        })
                                    }} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="">Language</label>
                                    <input type="text" className="form-control form-control-sm" value={payload.form.language} placeholder="Enter language" onInput={(e) => {
                                        setPayload({
                                            ...payload,
                                            form: {
                                                ...payload.form,
                                                language: e.target.value
                                            }
                                        })
                                    }} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="">Mode</label>
                                    <input type="text" className="form-control form-control-sm" value={payload.form.mode} placeholder="Enter mode" onInput={(e) => {
                                        setPayload({
                                            ...payload,
                                            form: {
                                                ...payload.form,
                                                mode: e.target.value
                                            }
                                        })
                                    }} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="">Comments</label><br />
                                    <textarea name="" id="" style={{width: '100%'}} rows="10" placeholder="Enter Comments" value={payload.form.comments} placeholder="Enter Comments" onInput={(e) => {
                                        setPayload({
                                            ...payload,
                                            form: {
                                                ...payload.form,
                                                comments: e.target.value
                                            }
                                        })
                                    }}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 pad-5 text-center">
                            <button className="btn btn-theme btn-sm" onClick={
                                () => setStage(2)
                            }>Next</button>
                        </div>
                    </div>
                </div>
            )
        }
        else if(stage === 2) {
            return (
                <div className="row margin-0">
                    <div className="col-12 pad-10" style={{borderBottom: '1px solid #ddd'}}>
                        {course.title}
                    </div>
                    <div className="col-12 pad-10" style={{minHeight:'30vh'}}>
                        <ul className="list-inline">
                            <li>
                                Is this course is suitable for you?
                                &nbsp; &nbsp; &nbsp; &nbsp;<input className="f-right" type="checkbox" name="" id="" />
                            </li>
                            <li>
                                Is this course is suitable for you?
                                &nbsp; &nbsp; &nbsp; &nbsp;<input className="f-right" type="checkbox" name="" id="" />
                            </li>
                            <li>
                                Is this course is suitable for you?
                                &nbsp; &nbsp; &nbsp; &nbsp;<input className="f-right" type="checkbox" name="" id="" />
                            </li>
                            <li>
                                Is this course is suitable for you?
                                &nbsp; &nbsp; &nbsp; &nbsp;<input className="f-right" type="checkbox" name="" id="" />
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 pad-10 text-center">
                        {
                            profile!==null?(
                                profile.user_type==='STUDENT'?(
                                    <button className="btn btn-theme btn-sm" onClick={()=>{
                                        postEnquiry();
                                    }}>Post Enquiry</button>
                                ):(
                                    <span>Please enroll as a student to enquire about this course.<br/> <span className="text-primary c-pointer">Create Student Profile</span></span>
                                )
                            ):(
                                <button className="btn btn-theme btn-sm" onClick={
                                    () => setStage(3)
                                }>Next</button>
                            )
                        }
                    </div>
                </div>
            )
        } else if(stage === 3) {
            return <Authenticate mode="plain" handleAction={handleAction} />
        }
        return (
            <div className="text-center pad-10 d-flex justify-content-center align-items-enter">
                Loading...
            </div>
        )
    }

    return (
        <div className="row margin-0 justify-content-center" style={{padding: '5vh', minHeight: '100vh'}}>
            <div className="col-2 text-right pad-top-10">
                <span onClick={() => {
                    history.goBack()
                }} className="fa fa-arrow-left c-pointer"></span>
            </div>
            <div className="col-8 bg-white pad-0">
                {renderSteps()}
            </div>
            <div className="col-2">
            </div>
        </div>
    );
}

export default Course;
