import InputRating from "./InputRating";

const RatingDetail = (props) => {

    const { rating, showValue, mode='custom' } = props;

    return (
        <div className="ratings-head">
            <InputRating mode={'view'} showValue={showValue} value={rating?rating.rating:0} />
                {
                    rating && (
                        <div className="rating-details">
                            {
                                Object.keys(rating).filter(key => ['total', 'rating', 'ratings'].indexOf(key)===-1).map((key, idx) => (
                                    <div className='text-left' key={idx}>
                                        {
                                            mode==='custom'?<InputRating label={key} mode={'view'} showValue={true} value={rating[key]/rating.total} />:<InputRating label={key} mode={'view'} showValue={true} value={rating[key]} />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
        </div>
    )

};

export default RatingDetail;