import { useEffect } from "react";
import { useState } from "react";
// import PhoneInput from 'react-phone-number-input'
// import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
// import en from 'react-phone-number-input/locale/en.json'
import $cs from '../services';

const InputFile = (props) => {
  const { type, label, error, id, name, value, placeholder = 'Enter Text', setValue, isRequired, action } = props;
  const myID = Math.random().toString(36).substring(7)

  const handleFileUpdload = () => {
    const File = document.getElementById(myID).files[0];
    if(File !== undefined && File.size > 2000000) {
      alert('Unable to upload, File size should not exceed 2MB.');
    } else if (File !== undefined) {
      $cs.img.upload(File, "profile").then(
        (url) => {
          setValue({
            name: File.name,
            url
          });
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  return (
    <div>
      {
        value===null?<div className="form-control ig-form-input" onClick={() => {
            var eleRef = document.getElementById(myID);
            eleRef.click();
          }}>
          <span className="c-pointer" style={{}}><span className="fa fa-paperclip"></span> Select File</span>
        </div>:<div className="form-control ig-form-input">
          {value && value.url ? <a className='txt-theme' href={value.url} target="_blank"><span className="fa fa-paperclip"></span> {value.name||'File'}</a>:<p>-</p>}
          <span className="fa fa-refresh f-right margin-top-5 c-pointer" onClick={() => {
            var eleRef = document.getElementById(myID);
            eleRef.click();
          }}></span>
        </div>
      }

      <input
        type="file"
        style={{ display: "none" }}
        name=""
        className="form-control ig-form-input "
        id={myID}
        onChange={handleFileUpdload}
      />
    </div>
  )

};

export default InputFile;
