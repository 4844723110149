import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  lookupsSelector,
  appSettingsSelector,
  discountsSelector,
  bannersSelector,
} from "../store/appReducer";
import {
  realTimeProfileSelector,
  actionsObjSelector,
  getActions,
  getProfile,
  setProfile,
  userTypeSelector,
  getEnquires,
  enquiriesSelector,
} from "../store/userReducer";
import $cs from "../services.js";
import moment from "moment";
import Banner from "../skeltons/components/Banner";
import CouponList from "./components/CouponsList";
import { Link, useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { IGInput } from "../ignitor-react";
import { initatePayment, preparePricing } from "../mixins.js";
import ModalAction from "./components/modalActions";

const MyWallet = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(realTimeProfileSelector);
  const lookups = useSelector(lookupsSelector);
  const discounts = useSelector(discountsSelector);
  const banners = useSelector(bannersSelector);
  const [coins, setCoins] = useState(100);
  const [popupMode, setPopupMode] = useState(null);
  const [error, setError] = useState({});
  const actions = useSelector(actionsObjSelector);
  const [disputeList, setDisputeList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [transactions, setTransactions] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const [tab, setTab] = useState("TRANSACTIONS");
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [payload, setPayload] = useState({
    coins: 100,
    type: "COINS",
    coupon_code: null,
    pricing: null,
  });
  const [dispute, setDispute] = useState({
    reason: "OTHERS",
    action: "COIN_DISPUTE",
    transaction: null,
  });

  useEffect(() => {
    if (isLoading && transactions === null) {
      getTransactions();
      calculatePricing();
    }
  });

  useEffect(() => {
    calculatePricing();
  }, [coupon]);

  // useEffect(() => {
  //     calculatePricing();
  // }, [coins]);

  useEffect(() => {
    document.body.className = "dashboard-page";
    if (actions && actions.COIN_DISPUTE) {
      const disputes = actions.COIN_DISPUTE.map(
        (item) => item.data["transaction"]
      );
      setDisputeList(disputes);
    }
  }, [actions]);

  const getTransactions = (resource = "") => {
    $cs.transaction
      .list({
        resource,
        pageSize,
      })
      .then(
        (res) => {
          setTransactions(res.data);
          setLoading(false);
        },
        (err) => {}
      );
  };

  const calculatePricing = (cons = null) => {
    let pay = payload;
    if (cons !== null) {
      pay.coins = cons;
    }
    $cs.pricing
      .create({
        data: pay,
      })
      .then(
        (res) => {
          let coupon_code = payload.coupon_code;
          if (
            res.data.discount === 0 &&
            pay.coupon_code &&
            pay.coupon_code.type === "COUPON"
          ) {
            setCoupon(null);
            coupon_code = null;
          }
          if (
            res.data.extra_label === null &&
            pay.coupon_code &&
            pay.coupon_code.type === "DISCOUNT"
          ) {
            setCoupon(null);
            coupon_code = null;
          }
          setPayload({
            ...payload,
            pricing: res.data,
            coupon_code,
          });
        },
        (err) => {}
      );
  };

  const checkout = () => {
    $cs.transaction
      .create({
        data: payload,
      })
      .then(
        (res) => {
          if (res.data && res.data.step === "PAYMENT") {
            if (res.data.payment_link) {
              initatePayment(res.data.payment_link.payment_link, profile).then(
                (status) => {
                  updatePaymentStatus(status);
                }
              );
            }
          } else {
            dispatch(getProfile());
            getTransactions();
            setTab("TRANSACTIONS");
            setPayload({
              coins: 100,
              type: "COINS",
              coupon_code: null,
              pricing: null,
            });
            setProcessing(false);
          }
        },
        (err) => {}
      );
  };

  const updatePaymentStatus = (data) => {
    $cs.transaction
      .create({
        resource: `${$cs.transaction.api}${data.transaction_id}/updatepayment/`,
        data,
      })
      .then(
        (res) => {
          dispatch(getProfile());
          getTransactions();
          setTab("TRANSACTIONS");
          setPayload({
            coins: 100,
            type: "COINS",
            coupon_code: null,
            pricing: null,
          });
          setProcessing(false);
          console.log(res.data);
        },
        (err) => {}
      );
  };

  const createDispute = () => {
    $cs.profile
      .update({
        data: dispute,
      })
      .then(
        (res) => {
          setPopupMode(null);
          dispatch(getActions());
          history.push("/inbox");
        },
        (err) => {}
      );
  };

  const renderPagination = () => {
    if (transactions && transactions.results.length > 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={10} className="text-center pad-0">
              {transactions.previous !== null && (
                <span
                  className="txt-theme margin-right-10 c-pointer"
                  onClick={() => {
                    getTransactions(transactions.previous);
                  }}
                >
                  Prev
                </span>
              )}
              {transactions.next !== null && (
                <span
                  className="txt-theme c-pointer"
                  onClick={() => {
                    getTransactions(transactions.next);
                  }}
                >
                  Next
                </span>
              )}
            </td>
          </tr>
        </tbody>
      );
    } else {
      return;
    }
  };

  const renderTransactions = () => {
    if (transactions === null) {
      return (
        <tr>
          <td>Loading...</td>
        </tr>
      );
    } else {
      if (transactions.results.length > 0) {
        return transactions.results.map((item, index) => (
          <tr key={index}>
            <td data-column="Date">
              {moment(item.created_on).format("DD MMM, YYYY")}
            </td>
            <td data-column="Message">{item.message}</td>
            {item.transaction_type === "DEPOSIT" ? (
              <td
                data-column="Credit/Debit"
                className={`${item.status === "SUCCESS" ? "text-success" : ""}`}
              >
                +{item.coins}
              </td>
            ) : (
              <td data-column="Credit/Debit" className="text-danger">
                -{item.coins}
              </td>
            )}
            <td data-column="Expires on">
              {item.transaction_type === "DEPOSIT"
                ? item.expire_on
                  ? moment(item.expire_on).format("DD MMM, YYYY")
                  : "-"
                : "-"}
            </td>
            <td data-column="Status">{item.status}</td>
            <td data-column="Action">
              {moment(item.created_on)
                .add("days", 7)
                .isAfter(moment(new Date())) &&
                item.coins > 0 &&
                disputeList.indexOf(item.id) === -1 && (
                  <span
                    className="fa fa-hand-paper margin-right-5 c-pointer"
                    onClick={() => {
                      setDispute({
                        ...dispute,
                        transaction: item.id,
                      });
                      setPopupMode("dispute");
                    }}
                  ></span>
                )}
              <span
                onClick={() => {
                  setSelectedTransaction(item);
                }}
                className="fa fa-print c-pointer"
              ></span>
            </td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan="6" className="text-center">
              No Trasactions
            </td>
          </tr>
        );
      }
    }
  };

  const renderTransactionsTab = () => {
    return (
      <div className="row margin-0">
        <div className="col-12 pad-0 mb-2">
          <h4>
            Recent Transactions{" "}
            <small className="f-right" style={{ fontSize: "0.8rem" }}>
              <span className="text-danger">*</span>Disputes are allowed only
              within 7 days of transaction. Check dispute status{" "}
              <Link
                className="txt-theme"
                style={{ fontSize: "0.8rem" }}
                to="/inbox"
              >
                here
              </Link>
              .
            </small>
          </h4>
        </div>
        <div className="col-12 pad-0">
          <div className="custom-table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ minWidth: "110px" }}>Date</th>
                  <th scope="col">Message</th>
                  <th style={{ minWidth: "150px" }}>Credit/Debit</th>
                  <th style={{ minWidth: "110px" }}>Expires on</th>
                  <th scope="col">Status</th>
                  <th style={{ minWidth: "90px" }}>Action</th>
                </tr>
              </thead>
              <tbody>{renderTransactions()}</tbody>
              {(transactions && (transactions.next || transactions.previous)) && renderPagination()}
            </table>
          </div>
        </div>
      </div>
    );
  };

  const renderCoinsTab = () => {
    return (
      <div id="add-coins">
        <div className="row">
          <div className="col-xl-6">
            <div className="coin-choose-block">
              <h3 className="title">Purchase Summary</h3>
              <h4>Choose Coins:</h4>
              <ul className="choose-list">
                <li>
                  <button
                    className="btn btn-danger btn-sm"
                    disabled={coins < 101}
                    onClick={() => {
                      setCoins(coins - 100);
                      calculatePricing(coins - 100);
                    }}
                  >
                    -
                  </button>
                </li>
                <li>
                  <p>{coins}</p>
                </li>
                <li>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      setCoins(coins + 100);
                      calculatePricing(coins + 100);
                    }}
                  >
                    +
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="coins-summary-block">
              {payload.pricing ? (
                <div>
                  <h3 className="title">
                    Pricing Summary
                    <span>{payload.pricing.symbol}</span>
                  </h3>
                  <ul className="pricing-list">
                    <li>
                      <p className="name">Amount</p>
                      <p className="value">{payload.pricing.amount}</p>
                    </li>
                    <li>
                      <p className="name">Discount(-)</p>
                      <p className="value">{payload.pricing.discount}</p>
                    </li>
                    <li>
                      <p className="name">Sub Total</p>
                      <p className="value">{payload.pricing.sub_total}</p>
                    </li>
                    {payload.pricing.tax.entries.map((item) => (
                      <li>
                        <p className="name">{item.label}(+)</p>
                        <p className="value">{item.amount}</p>
                      </li>
                    ))}
                    {payload.pricing && payload.pricing.extra_label && (
                      <li className="text-danger">
                        {payload.pricing.extra_label}
                      </li>
                    )}
                    <li className="coupon-list">
                      <CouponList
                        type="ALL"
                        value={payload.coupon_code}
                        coins={coins}
                        amount={payload.pricing ? payload.pricing.sub_total : 0}
                        setCoupoun={(val) => {
                          setPayload({
                            ...payload,
                            coupon_code: val,
                          });
                          setCoupon(val);
                          console.log(val);
                        }}
                      />
                    </li>
                    <li className="total">
                      <p className="name">Total</p>
                      <p className="value">{payload.pricing.total}</p>
                    </li>
                    {
                      profile.status === 'ACTIVE' && <li>
                        <div className="continue-button">
                          <button
                            onClick={() => {
                              checkout();
                            }}
                          >
                            {" "}
                            Continue to Pay{" "}
                            {payload.pricing && payload.pricing.total && (
                              <span>{payload.pricing.total}</span>
                            )}
                          </button>
                        </div>
                      </li>
                    }
                  </ul>
                </div>
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderOffersTab = () => {};

  return (
    <section id="my-wallet">
      <Banner />
      <div className="main-title">
        Available
        <img src="/coins.png" alt="" style={{ height: "2rem" }} />:{" "}
        {profile.credits}
      </div>
      <div>
        <div className="row margin-0">
          {/* <div className="col-12 pad-0">
            <p style={{ fontSize: "0.9rem" }}>
              <span className="text-danger">*</span>Coins will be lapsed after
              valid days, if premium membership expires.
            </p>
          </div> */}
          <div className="col-12 pad-0">
            <ul className="default-tabs-horizontal">
              <li className={tab === "TRANSACTIONS" ? "active" : ""}>
                <a
                  onClick={() => {
                    setTab("TRANSACTIONS");
                  }}
                >
                  TRANSACTIONS
                </a>
              </li>
              <li className={tab === "COINS" ? "active" : ""}>
                <a
                  onClick={() => {
                    setTab("COINS");
                    setCoins(100);
                    calculatePricing(100);
                  }}
                >
                  + ADD COINS
                </a>
              </li>
            </ul>
            <div className="tab-content mt-4">
              {tab === "TRANSACTIONS" && renderTransactionsTab()}
              {tab === "COINS" && renderCoinsTab()}
              {tab === "OFFERS" && renderOffersTab()}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={popupMode !== null}
          onHide={() => {
            setPopupMode(null);
          }}
        >
          <Modal.Body>
            <div className="row margin-0">
              {popupMode === "dispute" && (
                <div className="col-12 pad-0 text-center">
                  <h4>Select Your Dispute Reason</h4>
                  <div className="form-group">
                    <label>Reason</label>
                    <select
                      name=""
                      id=""
                      className="form-control form-control-sm"
                      value={dispute.reason}
                      placeholder="Select"
                      onChange={(e) => {
                        setDispute({
                          ...dispute,
                          reason: e.target.value,
                        });
                      }}
                    >
                      <option value="null">Select</option>
                      {lookups &&
                        lookups.DISPUTE_REASON &&
                        lookups.DISPUTE_REASON.codes.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.description}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="text-center pad-10">
                    <button
                      className="btn btn-theme btn-sm margin-right-10"
                      onClick={() => {
                        createDispute();
                      }}
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-plain btn-sm"
                      onClick={() => {
                        setPopupMode(null);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <div>
          {selectedTransaction && (
            <ModalAction
              action={{
                type: "TRANSACTION_INVOICE",
                data: selectedTransaction,
              }}
              close={() => {
                setSelectedTransaction(null);
              }}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default MyWallet;
