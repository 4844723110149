import Modal from "react-bootstrap/Modal";

const IGModal = (props) => {
  const { visible, close, body, isLogin } = props;
  return (
    <Modal
      show={visible}
      onHide={close}
      size={isLogin !== undefined ? "lg" : ""}
      // fullscreen={true}
      // dialogClassName="modal-90w"
      // aria-labelledby="example-custom-modal-styling-title"
      style={{
        marginTop: isLogin ? "4%" : "6%",
        marginLeft: isLogin ? "-9%" : "0%",
      }}
    >
      {/* <Modal.Dialog style={{maxWidth:"60%"}}> */}
      <Modal.Body className="pad-0 auth-modal">
        <div
          className="container-fluid ig-modal-background"
          style={{ padding: "15px 20px 22px" }}
        >
          <div className="row" style={{ paddingRight: "1%" }}>
            <div className="col pad-0 text-right">
              <img
                src="/ic_close.png"
                style={{ width: "25px" }}
                className="float-right c-pointer"
                alt=""
                onClick={() => {
                  close();
                }}
              />
            </div>
          </div>
          <div>{body()}</div>
        </div>
      </Modal.Body>
      {/* </Modal.Dialog> */}
    </Modal>
  );
};

export default IGModal;
