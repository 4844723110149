import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner, Container, Row, Col, Card } from "react-bootstrap";
import HomeCourses from "../components/HomeCourses";

const Courses = (props) => {
  return (
    <div>
      <div className="page-banner">
        <div className="inner">
          <h2 className="page-name">Courses</h2>
          <img src="/index-block-top.png" alt="shape" className="img-fluid" />
        </div>
        <span className="slider-shape"></span>
      </div>
      <section id="learn-any-thing">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading text-center">
                <img src="/top-yellow.png" alt="" />
                <h2 className="txt-theme bold">You can learn anything</h2>
                <span>
                  Build a deep, solid understanding in math, science, and more.
                </span>
              </div>
            </div>
          </div>
          <HomeCourses />
        </div>
      </section>
    </div>
  );
};

export default Courses;
