import { useEffect, useState } from "react";
import "./About.css";

const About = (props) => {
  // const dispatch = useDispatch();
  // const valueSelector = useSelector();
  // const [isLoading, setLoading] = useState(true);

  // useEffect(() => {
  // });

  return (
    <section id="about-page">
      <div className="page-banner">
        <div className="inner">
          <h2 className="page-name">About Us</h2>
          <img src="/index-block-top.png" alt="shape" className="img-fluid" />
        </div>
        <span className="slider-shape"></span>
      </div>
      <div className="inner pad-top-40">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div className="about-content">
                <h3 className="main-heading">About us</h3>
                <p>
                  Go Teacher Pro is an aggregating platform, having Global
                  Presence, brings professional teachers and students together.
                  We strive to achieve for the success stories of both teachers
                  and students with utmost learning satisfaction.{" "}
                </p>
                <p>
                  This portal helps the students to search the professional
                  teacher online for the desired course requirement.
                </p>
                <p>
                  Teacher can notify or callout the students or participants
                  about the training/ sessions/ presentations/ pod cast.
                </p>
                <h4 className="sub-heading mt-4">About the promoters</h4>
                <p>
                  We are group of four female engineering graduates, having
                  extensive experience in different domains. The idea of
                  this aggregating platform came in to picture, as a personal
                  experience of finding right tutors. The need for
                  quality and genuine platform made us to think and take the
                  initiative of creating this website and mobile app and made
                  available to all.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="about-image">
                <img
                  src="/Group_2.png"
                  alt="about-image"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
