// import "./RightMenu.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import $cs from "../../services.js";
import RatingDetail from "../../pages/components/RatingDetail";
import ModalAction from "../../pages/components/modalActions";
import {
  realTimeProfileSelector,
  notificationSelector,
  planSelector,
  setProfile,
  getProfile,
  getProfiles,
  resetUserState,
  currentProfileSelector,
  setCurrentProfile,
  actionsObjSelector,
  userTypeSelector,
} from "../../store/userReducer";

const RightMenu = (props) => {
  const { handleHide } = props;
  let { path, url } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const profile = useSelector(realTimeProfileSelector);
  const plan = useSelector(planSelector);
  const actions = useSelector(actionsObjSelector);
  const notifications = useSelector(notificationSelector);
  const [appWidth, setappWidth] = useState(document.body.offsetWidth);
  const userType = useSelector(userTypeSelector);
  const currentProfile = useSelector(currentProfileSelector);
  const [searchResults, setSearchResults] = useState(null);
  const [skillMode, setSkillMode] = useState("view");
  const [action, setAction] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [payload, setPayload] = useState({
    action: "thumbnail",
    value: null,
  });

  const logout = () => {
    localStorage.clear();
    dispatch(resetUserState());
    history.push("/");
  };

  const renderCourses = () => {
    if (
      currentProfile &&
      currentProfile.courses &&
      currentProfile.courses.length > 0
    ) {
      return (
        <div className="pad-0">
          <ul className="rmenu-skills-list">
            {currentProfile.courses_detail.map((skill, index) => (
              <li key={index} className="">
                {skill.parent_detail ? skill.parent_detail.topic + " - " : ""}
                {skill.title}
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return <p>No Skills added till now.</p>;
  };

  const renderNotifications = () => {
    if (notifications === null) {
      return (
        <div className="text-center pad-20">
          <span>Loading...</span>
        </div>
      );
    } else {
      if (notifications.results.length === 0) {
        return (
          <div className="text-center pad-20">
            <span>No Alerts Till now</span>
          </div>
        );
      } else {
        return (
          <ul className="notifications-list">
            {notifications.results.map((item, index) => {
              return <li key={index}>Test</li>;
            })}
          </ul>
        );
      }
    }
  };

  const handleProfilePic = () => {
    const File = document.getElementById("profilePic").files[0];
    if (File !== undefined) {
      setUploadStatus('Uploading...');
      $cs.img.upload(File, "profile").then(
        (url) => {
          updateProfile(url);
          setUploadStatus(null);
        },
        (err) => {
          setUploadStatus('Failed to upload. Retry Again.');
        }
      );
    }
  };

  const updateProfile = (val = null) => {
    $cs.profile
      .update({
        data: {
          action: "thumbnail",
          value: val,
        },
      })
      .then(
        (res) => {
          dispatch(getProfile());
        },
        (err) => { }
      );
  };

  return (
    <section className="profile-card">
      <div className="user-img">
        <img
          src={profile.thumbnail || "/user.webp"}
          className="img-circle img-fluid"
          alt=""
        />
        <div
          className="user-img-layer"
          onClick={() => {
            var eleRef = document.getElementById("profilePic");
            eleRef.click();
          }}
        >
          <span className="fa fa-camera"></span> Update
        </div>
        <input
          type="file"
          style={{ display: "none" }}
          name=""
          id="profilePic"
          accept="image/*"
          onChange={handleProfilePic}
        />
      </div>
      {
        uploadStatus && <div className="text-center">
          <span className="text-danger">
            {uploadStatus}
          </span>
        </div>
      }
      {actions &&
        actions.UPDATE_THUMBNAIL &&
        actions.UPDATE_THUMBNAIL.filter((item) => item.status === "OPEN")
          .length > 0 && (
          <div className="text-center">
            <span className="text-danger">
              <span className="fa fa-clock-o"></span> Thumbnail is under review
            </span>
          </div>
        )}
      <div className="details">
        <h4 className="name">
          {profile.first_name} {profile.last_name}
        </h4>
        {profile.user_type === "TEACHER" && (
          <h5 className="name plan">
            {profile.plan === null ? "Basic" : profile.plan.plan_details.title}
          </h5>
        )}
        {profile.mobile_verified ? (
          <h6 className="phone-verify">
            Phone Verified:{" "}
            <span
              className="fa fa-check-circle txt-theme"
              style={{ fontSize: "1rem" }}
            ></span>
          </h6>
        ) : (
          <h6 className="phone-verify">
            Phone Verified :{" "}
            <span
              onClick={() => {
                // if(appWidth < 1000) {
                //   handleHide();
                // }
                setAction("mobile");
              }}
              className="icon fa fa-exclamation-triangle text-danger c-pointer"
            ></span>
          </h6>
        )}
      </div>
      <div className="rating-block">
        <ul>
          <li className="count">
            {profile.rating ? profile.rating.rating.toFixed(1) : "0"}
          </li>
          <li>
            <RatingDetail rating={profile.rating} />
          </li>
          <li className="current-count">
            ({profile.rating ? profile.rating.total : "0"})
          </li>
        </ul>
      </div>
      <div className="credits">
        <ul>
          <li>
            <button
              className="creadit-button"
              onClick={() => {
                if (appWidth < 1000) {
                  handleHide();
                }
                history.push("/my-wallet");
              }}
            >
              {" "}
              <img src="/coins.png" alt="" />
              {profile.credits}
            </button>
          </li>
          <li>
            {profile.user_type === "TEACHER" && (
              <button
                className="btn btn-yellow btn-sm"
                onClick={() => {
                  if (appWidth < 1000) {
                    handleHide();
                  }
                  history.push("/membership");
                }}
              >
                Go Premium <span className="fa fa-chevron-right"></span>
              </button>
            )}
          </li>
        </ul>
      </div>
      <div className="goto-premium"></div>
      <div className="wishlist">
        <ul>
          <li
            className="name"
            style={{
              backgroundColor: "#2874A6",
              color: "#fff",
              padding: 5,
              margin: 0,
            }}
          >
            {profile.user_type === "STUDENT" ? "Wishlist" : "Skill Set"}{" "}
          </li>
          <li>
            <small
              className="theme-badge"
              style={{
                backgroundColor: "#f9ed35",
                color: "#000",
                padding: 5,

                margin: 0,
              }}
            >
              {currentProfile && currentProfile.courses
                ? currentProfile.courses.length
                : 0}
            </small>
          </li>
          <li>
            <span
              className="edit-link c-pointer"
              onClick={() => {
                if (appWidth < 1000) {
                  handleHide();
                }
                if (profile.user_type === "STUDENT") {
                  history.push("/wishlist");
                } else {
                  history.push("/skill-set");
                }
              }}
            >
              {" "}
              EDIT
              <span className="fa fa-pencil"></span>
            </span>
          </li>
        </ul>
        <div className="courses">{renderCourses()}</div>
      </div>

      <div className="row">
        {action && (
          <ModalAction
            action={{ type: action }}
            close={() => {
              setAction(null);
            }}
          />
        )}
      </div>
    </section>
  );
};

export default RightMenu;
