import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import $cs from "../services.js";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import {
  realTimeProfileSelector,
  notificationSelector,
  unreadNotificationSelector
} from "../store/userReducer";

const Enrollments = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(realTimeProfileSelector);
  const alerts = useSelector(notificationSelector);
  const unread = useSelector(unreadNotificationSelector);

  const notificationLabels = {
    REQUEST: "Enrollment Confirmation",
    ANNOUNCEMENT: "Enrollment Confirmation",
  };

  const renderNotifications = () => {
    if (alerts === null) {
      return (
        <div className="text-center pad-20">
          <span>Loading...</span>
        </div>
      );
    } else {
      if (alerts && alerts.results.length === 0) {
        return (
          <div className="text-center pad-20">
            <span>No Alerts Till now</span>
          </div>
        );
      } else {
        return (
          <ul className="alerts-list">
            {alerts.results.map((item, index) => {
              return (
                <li key={index}>
                  <div className="alert-img">
                    <img src="/noty.png" alt="" />
                  </div>
                  <div className="alert-content">
                    <label className="d-block" htmlFor="">
                      {notificationLabels[item.alert_type] || item.alert_type}{" "}
                      <span className="txt-grey f-right">
                        {moment(item.created_on).format("DD MMM")}
                      </span>
                    </label>
                    <p>{item.message}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        );
      }
    }
  };

  return (
    <div>
      <section className="enrollment-view">
        <div className="enrollments-list-group">{renderNotifications()}</div>
      </section>
    </div>
  );
};

export default Enrollments;
