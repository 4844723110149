import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import $cs from "../../services.js";
import moment from "moment";
import {
  lookupsSelector,
  appSettingsSelector,
  discountsSelector,
  couponsSelector,
  bannersSelector,
} from "../../store/appReducer";
import {
  realTimeProfileSelector,
  setProfile,
  userTypeSelector,
  enquiriesSelector,
} from "../../store/userReducer";

const CouponList = (props) => {
  const { value, setCoupoun, type = "COUPONS", amount = 0, coins = 0 } = props;
  const profile = useSelector(realTimeProfileSelector);
  const discounts = useSelector(discountsSelector);
  const coupons = useSelector(couponsSelector);
  const [mode, setMode] = useState("closed");

  return (
    <div>
      <div className="coupon-block">
        {value ? (
          <div>
            <span className="pr-2">Applied :</span>
            <span className="applied-coupon">
              {value.label}{" "}
              <span
                className="fa fa-times c-pointer"
                onClick={() => {
                  setCoupoun(null);
                  setMode("open");
                }}
              ></span>
            </span>
          </div>
        ) : (
          <span
            className="txt-theme c-pointer d-block use-coupon"
            onClick={() => {
              setMode(mode === "open" ? "closed" : "open");
            }}
          >
            Use Coupon
          </span>
        )}
      </div>
      {mode === "open" && (
        <div className="col-12 p-0">
          <div>
            <ul className="coupons-list">
              {type !== "COUPONS" &&
                discounts.map((item, idx) => (
                  <li key={"ex_" + idx}>
                    <h6 className="d-block">
                      Get {parseInt(item.value)}% EXTRA COINS{" "}
                      {coins >= item.coins && (
                        <span
                          className="f-right c-pointer txt-theme "
                          onClick={() => {
                            setCoupoun({
                              type: "DISCOUNT",
                              id: item.id,
                              value: item.coins,
                              label: `Get ${item.value}% Extra Coins`,
                            });
                            setMode("closed");
                          }}
                        >
                          Apply
                        </span>
                      )}
                    </h6>
                    <span>Valid on total coins {item.coins} or more.</span>
                    {coins < item.coins && (
                      <span className="text-danger d-block">
                        Needed {item.coins - coins} more coins to apply.
                      </span>
                    )}
                  </li>
                ))}
              {coupons.filter(item => item.applicable==='ALL' || ((type==='COUPONS'&&item.applicable==='MEMBERSHIP') || type==='ALL'&&item.applicable==='COINS')).map((item, idx) => (
                <li key={"cp_" + idx}>
                  <h6 className="d-block">
                    {item.code}{" "}
                    {amount >= item.min_order && (
                      <span
                        className="txt-theme f-right c-pointer apply-btn"
                        onClick={() => {
                          setCoupoun({
                            type: "COUPON",
                            id: item.id,
                            value: item.code,
                            label: item.code,
                          });
                          setMode("closed");
                        }}
                      >
                        Apply
                      </span>
                    )}
                  </h6>
                  <span>
                    Valid on total amount {parseInt(item.min_order)} or more.
                  </span>
                  {amount < item.min_order && (
                    <span className="text-danger d-block">
                      Needed {parseInt(item.min_order - amount)} more amount to
                      apply.
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default CouponList;
