import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner, Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import InputCourse from "./components/InputCourse";
import HomeCourses from "./components/HomeCourses";
import $cs from "../services.js";
import "./styles/Home.css";
import Banner from "../skeltons/components/Banner";
import {
  profileSelector,
  setProfile,
  userTypeSelector,
  getEnquires,
  enquiriesSelector,
  getRealTimeProfile,
  getProfiles,
  realTimeProfileSelector,
} from "../store/userReducer";

const Home = (props) => {
  // const dispatch = useDispatch();
  // const valueSelector = useSelector();
  const history = useHistory();
  const profile = useSelector(realTimeProfileSelector);
  const [isLoading, setLoading] = useState(true);
  const [seletedCourse, setSeletedCourse] = useState(null);

  const handleCourseSelection = (course) => {
    if (profile) {
      if (profile.user_type === "STUDENT") {
        history.push(
          `/find-teacher/?topic=${course.id}&keyword=${course.title.replace(
            /\s+/g,
            "_"
          )}`
        );
      } else if (profile.user_type === "TEACHER") {
        history.push(
          `/find-students/?topic=${course.id}&keyword=${course.title.replace(
            /\s+/g,
            "_"
          )}`
        );
      }
    } else {
      history.push(`/login?topic=${course.id}`);
    }
  };

  return (
    <div id="home-page">
      <section id="hero-slider">
        <div className="container">
          <div className="slider-grid">
            <div className="content-block">
              <div className="heading">
                <div className="shape">
                  <img
                    className="img-fluid"
                    src="/index-block-top.png"
                    alt="shape"
                  />
                  <h1>Its easy now to find best teacher!</h1>
                  <p className="description">
                    <b>Go Teacher Pro</b> is an handshake platform, having
                    Global Presence,
                    <br /> brings professional teachers and students together.
                  </p>
                </div>
              </div>
              <div className="search-input">
                <InputCourse
                  value={seletedCourse}
                  isRequired={true}
                  setValue={(c) => {
                    handleCourseSelection(c);
                    setSeletedCourse(c);
                  }}
                  mode={"INPUT"}
                />
                <i className="fa fa-search"></i>
              </div>
              <div
                className="pad-left-15 margin-bottom-30"
                style={{ fontSize: "0.8em" }}
              >
                Course not found? Write to info@goteacherpro.com
              </div>
              <div className="join-now">
                <div className="heading">
                  <img
                    src="/top-yellow.png"
                    alt="shape"
                    className="img-fluid"
                  />
                  <h2>Join now</h2>
                  <p>
                    Bring a willing student and a devoted teacher together.
                    Enroll now and get joining bonus.
                  </p>
                </div>
                <ul className="action-buttons">
                  <li>
                    <img
                      src="/left-white.png"
                      className="margin-right-10"
                      alt=""
                    />
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        history.push("/signup?user=STUDENT");
                      }}
                    >
                      Student
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        history.push("/signup?user=TEACHER");
                      }}
                    >
                      Teacher
                    </button>
                  </li>
                  <li>
                    <img
                      src="/right-white.png"
                      className="margin-left-10"
                      alt=""
                    />
                  </li>
                </ul>
              </div>
              <div className="action-buttons-group">
                <div className="action-heading">
                  <h4 className="txt-white d-block bold margin-bottom-0"></h4>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="image-block">
              <img src="/sliders/slider-3.svg" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
        <span className="slider-shape"></span>
      </section>

      <section id="learn-any-thing">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading text-center">
                <img src="/top-yellow.png" alt="" />
                <h2 className="txt-theme bold">You can learn anything</h2>
                <span>Whoever you are &amp; wherever you are</span>
              </div>
            </div>
          </div>
          <HomeCourses />
        </div>
      </section>
      <section id="how-it-works">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading text-center">
                <img src="/top-yellow.png" alt="" />
                <h2 className="txt-theme bold">How Go Teacher Pro Works</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="each-work">
                <div className="image">
                  <img
                    src="/works/work-1.svg"
                    alt="work-image"
                    className="img-fluid"
                  />
                </div>
                <div className="content">
                  <h4>Choose a subject</h4>
                  <p>Select a subject &amp; enhance your learning</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-4 mt-md-0">
              <div className="each-work">
                <div className="image">
                  <img
                    src="/works/work-2.svg"
                    alt="work-image"
                    className="img-fluid"
                  />
                </div>
                <div className="content">
                  <h4>Find a Tutor</h4>
                  <p>
                    Explore various teachers for your personal Learning needs
                  </p>
                  <p className="text-center pad-0 margin-10">
                    <b>(or)</b>
                  </p>
                  <div className="other">
                    <h4>Post an Enquiry</h4>
                    <p>
                      Post your Enquiry which will reach teachers of your
                      learning request.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
              <div className="each-work">
                <div className="image">
                  <img
                    src="/works/work-3.svg"
                    alt="work-image"
                    className="img-fluid"
                  />
                </div>
                <div className="content">
                  <h4>Request a Demo</h4>
                  <p>Get connected with teachers and be a Smart Learner</p>
                  <p className="text-center pad-0 margin-10">
                    <b>(or)</b>
                  </p>
                  <div className="other">
                    <h4>Get response from Teachers</h4>
                    <p>
                      Teachers will reach you over your enquiry Get Connected
                      &amp; be a smart Learner
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="why-matrix" className="d-none">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading text-center">
                <img src="/index-block-top.png" alt="" />
                <h2 className="txt-theme bold">Why Mathrix works</h2>
              </div>
            </div>
          </div>
          <div className="row margin-0">
            <div className="col-4 text-center">
              <img src="/mathrix-1.png" alt="" className="img-fluid" />
              <span className="bold d-block">We have official professors</span>
            </div>
            <div className="col-4 text-center">
              <img src="/mathrix-2.png" alt="" className="img-fluid" />
              <span className="bold d-block">We have official professors</span>
            </div>
            <div className="col-4 text-center">
              <img src="/mathrix-3.png" alt="" className="img-fluid" />
              <span className="bold d-block">We have official professors</span>
            </div>
          </div>
          <div className="text-center pad-top-20">
            <img src="/left.png" alt="" />
            <button
              onClick={() => {
                history.push("/signup");
              }}
              className="btn btn-sm btn-yellow margin-left-15 margin-right-15"
            >
              Join now
            </button>
            <img src="/right.png" alt="" />
          </div>
        </div>
      </section>

      <section className="who-get-benefited d-none d-md-block">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading text-center mb-5">
                <img src="/top-yellow.png" alt="" />
                <h2 className="txt-theme bold">Our Value Propositions</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center justify-content-md-between align-items-center">
            <div className="col-lg-3 col-md-3 col-sm-10 col-8">
              <div className="left-block text-center">
                <a href="/signup?user=STUDENT" className="primary-button">
                  Student
                </a>
                <div className="image">
                  <img
                    src="/who/student.svg"
                    alt="image"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-10 col-8">
              <div className="center-block text-center">
                <div className="image">
                  <img src="/who/arrow.svg" alt="image" className="img-fluid" />
                </div>
                <a href="/signup" className="primary-button">
                  Join Now
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-10 col-8 text-center">
              <div className="left-block">
                <a href="/signup?user=TEACHER" className="primary-button">
                  Teacher
                </a>
                <div className="image">
                  <img
                    src="/who/teacher.svg"
                    alt="image"
                    className="img-fluid"
                  />
                </div>
                <a href="benfits/" className="primary-button">
                  Know More...
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="row margin-0 pad-top-30 justify-content-center">
        <div className="col-12 text-center">
          <img src="/top-yellow.png" alt="" />
          <h2 className="txt-theme bold">Latest Info</h2>
        </div>
        <div className="col-11 pad-0">
          <Banner target={"HOMEPAGE"} />
        </div>
      </div>
    </div>
  );
};

export default Home;
