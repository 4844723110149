import { configureStore } from '@reduxjs/toolkit';
import appReducer from './appReducer';
import userReducer from './userReducer';

export default configureStore({
    reducer: {
      user: userReducer,
      app: appReducer,
    },
})
