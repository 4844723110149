import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner, Container, Row, Col } from "react-bootstrap";

const Privacy = (props) => {
  // const dispatch = useDispatch();
  // const valueSelector = useSelector();
  // const [isLoading, setLoading] = useState(true);

  // useEffect(() => {
  // });

  return (
    <section id="privacy-policy-page">
      <div className="page-banner">
        <div className="inner">
          <h2 className="page-name">Privacy Policy</h2>
          <img src="/index-block-top.png" alt="shape" className="img-fluid" />
        </div>
        <span className="slider-shape"></span>
      </div>
      <div className="inner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="main-heading">Privacy Policy</h3>
              <p>
                We don’t sell your data. But being the information is available
                in platform, ensure to keep your personal details safe and secure. We don’t claim any
                responsibility of the same.
              </p>
              <p>
                We use cookies, session data, IP address of end users who visits
                to portal or app or through any other means for our audit or
                operational purpose.
              </p>
              <p>
                We don’t take responsibility of exposing information by any
                search engine and if you observe this, end user can take initiative
                to hide the information.
              </p>
              <p>
                By visiting or registering in the platform you agree for the
                above.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
