import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import $cs from "../services.js";
import { Link, useHistory } from "react-router-dom";
import InputCourse from "./components/InputCourse";
import RatingDetail from "./components/RatingDetail";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import {
  realTimeProfileSelector,
  enrollmentsSelector,
  profilesSelector,
  skillsSelector,
  getProfiles,
  setProfile,
  userTypeSelector,
  getEnquires,
  enquiriesSelector,
  getEnrollments,
} from "../store/userReducer";

const Enrollments = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(realTimeProfileSelector);
  // const enrollments = useSelector(enrollmentsSelector);
  const [enrollments, setEnrollments] = useState(null);
  const [stateLoaded, setStateLoaded] = useState(false);
  const [enrollData, setEnrollData] = useState(null);

  useEffect(() => {
    document.body.className = "dashboard-page";
    if (enrollments === null) {
      getEnrollments();
    }
    if(enrollData===null) {
      getEnrollmentsData();
    }
  });

  const getEnrollments = (page=null) => {
    $cs.enquiry
      .list({
        resource: page || `${$cs.enquiry.api}enrollments/`,
        pageSize: 5,
      })
      .then((res) => {
        setEnrollments(res.data);
      });
  }

  const getEnrollmentsData = () => {
    $cs.enrollment.list({pageSize:1}).then(res=>{
      console.log(res.data);
      setEnrollData(res.data);
    });
  };

  const openDetail = (item) => {
    if (item.enquiry_type === "REQUEST") {
      history.push(`/enquiry/${item.id}?ref=ENROLLMENTS`);
    } else if(item.enquiry_type === "ANNOUNCEMENT") {
      history.push(`/announcement/${item.id}?ref=ENROLLMENTS`);
    } else if(item.enquiry_type === "DEMO") {
      history.push(`/demo/${item.id}?ref=ENROLLMENTS`);
    } else {
      history.push("/dashboard");
    }
  };

  const renderEnrollments = () => {
    if (enrollments === null) {
      return <div>Loading...</div>;
    } else {
      if (enrollments && enrollments.results.length > 0) {
        return enrollments.results.map((item, index) => (
          <div className="enrollments-data">
            <div
              className="row"
              key={index}
            >
              <div className="col-lg-12 col-md-12">
                <label className="c-pointer" onClick={() => {
                  openDetail(item);
                }}>{item.enquiry_id}: {item.course.parent_detail? item.course.parent_detail.topic + " - ": ""}{" "}{item.course ? item.course.title : "-"}</label> - <span className="text-danger bold">{item.enrollments.length}</span>
                <div className="row margin-0 mb-visible">
                  <div className="col-12">
                    <label htmlFor="">Details</label>
                  </div>
                </div>
                <div className="mb-hide">
                  <div className="row margin-0">
                    <div className="col-3">
                      <label>
                        {profile.user_type === "STUDENT" ? "Teacher" : "Student"} Name
                      </label>
                    </div>
                    <div className="col-3">
                      <label>
                        Feedback for{" "}
                        {profile.user_type === "STUDENT" ? "Teacher" : "Student"}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-3">
                      <label>
                        Enrolled On
                      </label>
                    </div>
                  </div>
                </div>
                {
                  item.enrollments && item.enrollments.map((itm, idx) => <div className="row margin-0">
                    <div className="col-lg-3 col-md-3">
                      <p className="student-name">
                        {itm.owner_details.first_name || "********"}{" "}
                        {itm.owner_details.last_name
                          ? itm.owner_details.last_name
                          : ""}
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-3">
                      {profile.user_type === "STUDENT" ? (
                        <div>
                          {itm.teacher_feedback ? (
                            <RatingDetail
                              mode={"custom1"}
                              showValue={true}
                              rating={{
                                ...itm.teacher_feedback.data,
                                rating: itm.teacher_feedback.rating,
                                total: itm.teacher_feedback.rating,
                              }}
                            />
                          ) : (
                            <p className="status">Not Submitted</p>
                          )}
                        </div>
                      ) : (
                        <div>
                          {itm.student_feedback ? (
                            <RatingDetail
                              mode={"custom1"}
                              showValue={true}
                              rating={{
                                ...itm.student_feedback.data,
                                rating: itm.student_feedback.rating,
                                total: itm.student_feedback.rating,
                              }}
                            />
                          ) : (
                            <p className="status">Not Submitted</p>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4 col-md-3">
                      <p className="enrolled-date">
                        {moment(itm.created_on).format("DD MMM, YYYY") || "-"}
                      </p>
                    </div>
                  </div>)
                }
              </div>
            </div>
          </div>
        ));
      } else {
        return <li className="pad-20">No Enrollments</li>;
      }
    }
  };

  return (
    <div>
      <section className="enrollment-view">
        <div className="title-block margin-bottom-15">
          <div className="tab-inner-title">
            <h4>{profile.user_type === "STUDENT"?"My Teachers List":"My Students List"}</h4>
            <span className="count">{(enrollData && enrollData.count)?enrollData.count:'0'}</span>
          </div>
        </div>
        <div className="enrollments-list-group">{renderEnrollments()}</div>
      </section>
    </div>
  );
};

export default Enrollments;
