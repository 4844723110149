import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Button, Spinner, Container, Row, Col } from 'react-bootstrap';
import InputRating from "./InputRating";
import IGModal from "../../ignitor-react/IGModal";
import { IGInput } from "../../ignitor-react";
import RatingDetail from "./RatingDetail";
import $cs from '../../services.js';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { lookupsSelector, appSettingsSelector } from '../../store/appReducer';
import { realTimeProfileSelector, getRealTimeProfile, setProfile, getProfile, getProfiles, currentProfileSelector, setCurrentProfile } from '../../store/userReducer';

const UserCard = (props) => {
    const { item, enquiryId, course, pageMode, module = null } = props;
    const { user_details, is_favorite, demo_data = null } = item;
    const profile = useSelector(realTimeProfileSelector);
    const lookups = useSelector(lookupsSelector);
    const [appWidth, setappWidth] = useState(document.body.offsetWidth);
    const dispatch = useDispatch();
    const history = useHistory();
    // const valueSelector = useSelector();
    const [userDetails, setUserDetails] = useState(user_details);
    const [isFavorite, setIsFavorite] = useState(is_favorite);
    const [popupMode, setPopupMode] = useState(null);
    const [descDetail, setDescDetail] = useState(200);
    const [error, setError] = useState({});
    const [demoUseData, setDemoUseData] = useState(null);
    const [demoData, setDemoData] = useState(demo_data);
    const [payload, setPayload] = useState({
        course: course ? course.id : null,
        enquiry_type: 'DEMO',
        participants: item.id,
        form: {
            preference: null
        }
    });

    // pre checks
    // state handlers
    // helpers
    const validateDemoForm = () => {
        let proceed = true;
        let errorObj = {};
        if (!payload.form.preference || payload.form.preference === "") {
            errorObj.preference = 'Preference is required';
            proceed = false;
        }
        setError(errorObj);
        return proceed;
    }

    const getAccessLink = (url) => {
        if(url && url.indexOf('http')===-1) {
            return `https://${url}`;
          } else {
            return url;
          }
    }

    // api calls
    const unlockEnquiry = () => {
        $cs.enquiry.list({
            resource: module === 'HAPPENINGS' ? `${$cs.course.api}${course.id}/unlock/${item.owner.id}/` : `${$cs.course.api}${course.id}/unlock/${item.id}/`,
        }).then(res => {
            setUserDetails(res.data);
            dispatch(getRealTimeProfile());
        }, err => {
            if (
                err.response.status === 400 &&
                err.response.data === "INSUFFICIENT_COINS"
            ) {
                setPopupMode("INSUFFICIENT_COINS");
            }
        });
    };

    const postDemoRequest = () => {
        if (validateDemoForm()) {
            $cs.enquiry.create({
                data: payload
            }).then(res => {
                setDemoData(res.data);
            }, err => {
            });
        }
    };

    const updateFavorite = (user, action) => {
        $cs.enquiry.create({
            resource: `${$cs.enquiry.api}${enquiryId}/updatefavorite/`,
            data: {
                action,
                user
            }
        }).then(res => {
            if (action === 'remove') {
                setIsFavorite(false);
            } else {
                setIsFavorite(true);
            }
        }, err => {
        });
    };

    // render functions
    const renderModalContent = () => {
        return (
            <div className="row margin-0 justify-content-center">
                {
                    popupMode !== null && popupMode === 'DEMO' && <div className="col-10">
                        <div className="row margin-0">
                            <div className="col-12 text-center">
                                <h4>Provide Availability Range</h4>
                            </div>
                            {
                                demoData === null ? <div className="col-12">
                                    <div className="info-block">
                                        <label htmlFor="">Teacher Timezone</label>
                                        <p>{demoUseData ? demoUseData.timezone : 'America/New_York'}</p>
                                    </div>
                                    <IGInput
                                        label={lookups && lookups.DEMO_AVAILABILITY ? lookups.DEMO_AVAILABILITY.description : "Demo Availability"}
                                        type="select"
                                        options={(demoUseData && demoUseData.demo_preference) ? demoUseData.demo_preference : lookups.DEMO_AVAILABILITY.codes.map((item, index) => [item.code, item.description])}
                                        isRequired={true}
                                        placeholder="Demo"
                                        value={payload.form.preference}
                                        error={error.preference}
                                        setValue={(val) => {
                                            setPayload({
                                                ...payload,
                                                form: {
                                                    ...payload.form,
                                                    preference: val
                                                }
                                            });
                                        }}
                                    />
                                    <div className="text-center pad-top-10">
                                        <button title="Request for Demo" className="btn btn-sm btn-yellow" onClick={() => {
                                            postDemoRequest();
                                        }}>Request for Demo</button>
                                    </div>
                                </div> : <div className="col-12 pad-top-20 text-center">
                                    <h5 className='txt-theme'>Demo Request Sent Successfully.</h5>
                                    <span className="btn-underline" onClick={() => { setPopupMode(null) }}>Close</span>
                                </div>
                            }
                        </div>
                    </div>
                }
                {popupMode !== null && popupMode === "INSUFFICIENT_COINS" && (
                    <div className="col-12 pad-0 text-center">
                        <h4>Insufficient coins balance.</h4>
                        <div className="text-center pad-10">
                            <button
                                className="btn btn-theme btn-sm margin-right-10"
                                onClick={() => {
                                    history.push("/my-wallet");
                                }}
                            >
                                Add Coins
                            </button>
                            <button
                                className="btn btn-plain btn-sm"
                                onClick={() => {
                                    setPopupMode(null);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </div>
        )
    };

    return (
        <div className='row margin-0 user-tile'>
            <div className="col user-tile-img">
                <img src={userDetails && userDetails.thumbnail ? userDetails.thumbnail : "/user.webp"} className="img-fluid" alt="" />
                <div className="text-center">
                    <RatingDetail mode={'view'} showValue={true} rating={item.rating} />
                </div>
            </div>
            <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 pad-0 mb-pad-10">
                <div className="row margin-0">
                    <div className={appWidth>1000?'col pad-0':'col-8 pad-0'}>
                        <div className="info-snip">
                            <label htmlFor="">Location</label>
                            <p className='margin-0'>{item.address ? item.address.name || item.address : '-'}</p>
                        </div>
                    </div>
                    <div className={appWidth>1000?'col pad-0':'col-4 pad-0'}>
                        <div className="info-snip">
                            <label htmlFor="">Gender</label>
                            <p className='margin-0'>{item.gender}</p>
                        </div>
                    </div>
                    <div className="col pad-0">
                        <div className="info-snip">
                            <label htmlFor="">Entity Type</label>
                            <p className='margin-0'>{item.entity_type}</p>
                        </div>
                    </div>
                    <div className="col pad-0">
                        <div className="info-snip">
                            <label htmlFor="">Teaching Mode</label>
                            <p className='margin-0'>{item.mode || '-'}</p>
                        </div>
                    </div>
                    {
                        pageMode === 'BROWSE' && <div className="col pad-10 text-right">
                            {
                                pageMode === 'BROWSE' && (isFavorite === true ? <span className="fa fa-heart c-pointer txt-theme" onClick={() => {
                                    updateFavorite(item.id, 'remove');
                                }}></span> : <span className="fa fa-heart-o c-pointer txt-theme" onClick={() => {
                                    updateFavorite(item.id, 'add');
                                }}></span>)
                            }
                        </div>
                    }
                    <div className="col-12"></div>
                    <div className="col-12 pad-5" style={{ backgroundColor: '#f7f7f7' }}>
                        {
                            item.credentials && item.credentials.education && item.credentials.education.map((item1, index) => (
                                <span className='margin-bottom-5' key={index + 'ac'}>- {item1.title || '-'} <br /></span>
                            ))
                        }
                        {
                            item.credentials && item.credentials.experience && item.credentials.experience.map((item1, index) => (
                                <span className='margin-bottom-5' key={index + 'ab'}>- {item1.title || '-'} Experience<br /></span>
                            ))
                        }
                        {
                            item.credentials && item.credentials.certification && item.credentials.certification.map((item1, index) => (
                                <span className='margin-bottom-5' key={index + 'a'}>- {item1.title || '-'}<br /></span>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="col-12" style={{ borderTop: '1px solid #ddd' }}>
                <div className="info-snip">
                    <label htmlFor="">About Me</label>
                    <p className='margin-0'>{item.description.slice(0, descDetail) || '-'}
                        {descDetail < item.description.length && <span className='text-primary' onClick={()=>{setDescDetail(item.description.length)}}> View More</span>}
                    </p>
                </div>
            </div>
            <div className="col-12 pad-0" style={{ border: '1px solid #ddd' }}>
                <div className="row margin-0">
                    <div className={`${(userDetails === null || userDetails === undefined) ? 'col-12 d-max-fit-content mb-pad-10' : 'col-lg-2 col-sm-2 col-md-2 mb-pad-10'} pad-right-0 `}>
                        <span>More About Me:
                            {(userDetails === null || userDetails === undefined) && (<span>(Unlock to view details)</span>)}
                        </span>
                        {
                            userDetails !== null && userDetails !== undefined && userDetails.data && <ul className='list-inline social-links-browse'>
                                <li>
                                    {
                                        (userDetails.data && userDetails.data.website) ? <a href={getAccessLink(userDetails.data.website)} target="_blank"><span className="fa fa-globe text-theme"></span></a>:<span className='fa fa-globe text-secondary'></span>
                                    }
                                </li>
                                <li>
                                    {
                                        (userDetails.data && userDetails.data.facebook) ? <a href={getAccessLink(userDetails.data.facebook)} target="_blank"><span className="fa fa-facebook text-primary"></span></a>:<span className="fa fa-facebook text-secondary"></span>
                                    }
                                </li>
                                <li>
                                    {
                                        (userDetails.data && userDetails.data.linkedIn) ? <a href={getAccessLink(userDetails.data.linkedIn)} target="_blank"><span className="fa fa-linkedin txt-theme"></span></a>:<span className="fa fa-linkedin text-secondary"></span>
                                    }
                                </li>
                                <li>
                                    {
                                        (userDetails.data && userDetails.data.video) ? <a href={getAccessLink(userDetails.data.video)} target="_blank"><span className="fa fa-youtube text-danger"></span></a>:<span className="fa fa-youtube text-secondary"></span>
                                    }
                                </li>
                            </ul>
                        }
                    </div>
                    {
                        (userDetails !== null && userDetails !== undefined && userDetails !== 'INDIVIDUAL') && (
                            <div className="col col-sm-12 col-lg-3 col-md-3 pad-0 mb-pad-10">
                                <div className="info-snip">
                                    <label htmlFor="">Institute/Academy Name</label>
                                    <p>{userDetails.consultant || '-'}</p>
                                </div>
                            </div>
                        )
                    }
                    {
                        (userDetails !== null && userDetails !== undefined) && (
                            <div className="col col-sm-6 col-lg-2 col-md-2 pad-0">
                                <div className="info-snip">
                                    <label htmlFor="">Name</label>
                                    <p>{userDetails.name || '-'}</p>
                                </div>
                            </div>
                        )
                    }
                    {
                        (userDetails !== null && userDetails !== undefined) && (
                            <div className="col col-sm-6 col-lg-3 col-md-3 pad-0 mb-pad-10">
                                <div className="info-snip">
                                    <label htmlFor="">Email</label>
                                    {userDetails.email ? <p className="text-danger">{userDetails.email}</p> : <span className="fa fa-lock"></span>}
                                </div>
                            </div>
                        )
                    }
                    {
                        (userDetails !== null && userDetails !== undefined) && (
                            <div className="col col-sm-6 col-lg-2 col-md-2 pad-0">
                                <div className="info-snip">
                                    <label htmlFor="">Mobile</label>
                                    {userDetails.mobile ? <p className="text-danger">{userDetails.mobile}</p> : <span className="fa fa-lock"></span>}
                                </div>
                            </div>
                        )
                    }
                    {
                        (userDetails === null || userDetails === undefined) && profile.status === 'ACTIVE' && (
                            <div className="col pad-10 text-right">
                                <button title="Unlock reveals contact, teacher persona and more." className="btn btn-sm btn-danger" onClick={() => {
                                    unlockEnquiry();
                                }}>Unlock Details with {item.coins ? item.coins.enquiry_coins : 100} Coins</button>
                                {
                                    module !== 'HAPPENINGS' && !enquiryId && item.demo === 'Yes' && (
                                        demoData === null ? <button title="Request for Demo" className="btn btn-sm btn-success margin-left-10" onClick={() => {
                                            setDemoUseData(item);
                                            setPopupMode('DEMO');
                                        }}>Request Demo</button> : <span className='padding-left-10'> <span className="fa fa-check"></span> Demo Request sent</span>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
            <IGModal visible={popupMode !== null} body={renderModalContent} close={() => { setPopupMode(null) }} />
        </div>
    );
}

export default UserCard;
