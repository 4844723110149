import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import $cs from "../services.js";
import "./styles/Membership.css";
import moment from "moment";
import Banner from "../skeltons/components/Banner";
import { IGInput } from "../ignitor-react";
import {
  taxSelector,
  appSettingsSelector,
  lookupsSelector,
} from "../store/appReducer";
import {
  realTimeProfileSelector,
  planSelector,
  getActions,
  setProfile,
  getRealTimeProfile,
} from "../store/userReducer";
import CouponList from "./components/CouponsList";
import { initatePayment, preparePricing } from "../mixins.js";
import IGModal from "../ignitor-react/IGModal";
import Modal from "react-bootstrap/Modal";
import ModalAction from "./components/modalActions";

const Membership = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lookups = useSelector(lookupsSelector);
  const profile = useSelector(realTimeProfileSelector);
  const plan = useSelector(planSelector);
  const [popupMode, setPopupMode] = useState(null);
  const settings = useSelector(appSettingsSelector);
  const [disputeList, setDisputeList] = useState([]);
  const [dispute, setDispute] = useState({
    reason: "OTHERS",
    action: "SUBSCRIPTION_DISPUTE",
    transaction: null,
  });
  const taxes = useSelector(taxSelector);
  const [tab, setTab] = useState("HISTORY");
  const [pageSize, setPageSize] = useState(5);
  const [transactions, setTransactions] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [plans, setPlans] = useState(null);
  const [packs, setPacks] = useState(1);
  const [error, setError] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [coupon, setCoupon] = useState(null);
  const [payload, setPayload] = useState({
    plan: null,
    packs: 1,
    type: "SUBSCRIPTION",
    use_wallet: false,
    coupon_code: null,
    pricing: null,
    currency: "INR",
  });

  // cycles
  useEffect(() => {
    document.body.className = "dashboard-page";
    if (isLoading && plans === null) {
      getPlans();
      getSubscriptions();
    } else if (payload.plan !== null && payload.pricing === null) {
      calculatePricing(payload.use_wallet);
    }
  });

  useEffect(() => {
    if (payload.plan !== null) {
      calculatePricing(payload.use_wallet);
    }
  }, [coupon]);

  // helpers
  const getPlans = () => {
    $cs.plan
      .list({
        params: {
          sort: "-order",
        },
      })
      .then(
        (res) => {
          setPlans(res.data);
          setLoading(false);
        },
        (err) => {}
      );
  };

  const getSubscriptions = (resource = "") => {
    $cs.subscription
      .list({
        resource,
        pageSize,
        params: {
          sort: "-created_on",
        },
      })
      .then(
        (res) => {
          setTransactions(res.data);
          setLoading(false);
        },
        (err) => {}
      );
  };

  const renderPagination = () => {
    if (transactions && transactions.results.length > 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={10} className="text-center pad-0">
              {transactions.previous !== null && (
                <span
                  className="txt-theme margin-right-10 c-pointer"
                  onClick={() => {
                    getSubscriptions(transactions.previous);
                  }}
                >
                  Prev
                </span>
              )}
              {transactions.next !== null && (
                <span
                  className="txt-theme c-pointer"
                  onClick={() => {
                    getSubscriptions(transactions.next);
                  }}
                >
                  Next
                </span>
              )}
            </td>
          </tr>
        </tbody>
      );
    } else {
      return;
    }
  };

  const checkout = () => {
    $cs.plan
      .create({
        resource: `${$cs.plan.api}${selectedPlan.id}/subscribe/`,
        data: {
          ...payload,
          packs,
        },
      })
      .then(
        (res) => {
          if (res.data && res.data.step === "PAYMENT") {
            if (res.data.payment_link) {
              initatePayment(res.data.payment_link.payment_link, profile).then(
                (status) => {
                  updatePaymentStatus(status);
                }
              );
            } else {
              dispatch(getRealTimeProfile());
            }
          } else {
            setSelectedPlan(null);
            dispatch(getRealTimeProfile());
            getSubscriptions();
            setTab("HISTORY");
          }
        },
        (err) => {}
      );
  };

  const updatePaymentStatus = (data) => {
    $cs.subscription
      .create({
        resource: `${$cs.subscription.api}${data.subscription_id}/updatepayment/`,
        data,
      })
      .then(
        (res) => {
          setSelectedPlan(null);
          dispatch(getRealTimeProfile());
          getSubscriptions();
          setTab("HISTORY");
        },
        (err) => {
          setSelectedPlan(null);
          dispatch(getRealTimeProfile());
          getSubscriptions();
          setTab("HISTORY");
        }
      );
  };

  const setPlan = (plan) => {
    setSelectedPlan(plan);
    setPacks(1);
    const plana = plan;
    setPayload({
      packs: 1,
      type: "SUBSCRIPTION",
      use_wallet: false,
      coupon_code: null,
      pricing: null,
      currency: null,
      plan: plana.id,
    });
  };

  const calculatePricing = (
    use_wallet = payload.use_wallet,
    packsl = packs
  ) => {
    $cs.pricing
      .create({
        data: {
          ...payload,
          use_wallet,
          packs: packsl,
        },
      })
      .then(
        (res) => {
          let coupon_code = payload.coupon_code;
          if (
            res.data.discount === 0 &&
            payload.coupon_code &&
            payload.coupon_code.type === "COUPON"
          ) {
            setCoupon(null);
            coupon_code = null;
          }
          setPayload({
            ...payload,
            use_wallet,
            pricing: res.data,
            coupon_code,
          });
        },
        (err) => {}
      );
  };

  const createDispute = () => {
    $cs.profile
      .update({
        data: dispute,
      })
      .then(
        (res) => {
          setPopupMode(null);
          dispatch(getActions());
          history.push("/inbox");
        },
        (err) => {}
      );
  };

  const getCoinsValue = (amount) => {
    let coin_price = profile.coin_price || 1;
    return Math.round(amount/coin_price);
  };

  const renderCurrentPlanLabel = (plan) => {
    if(profile.plan === null) {
      if(plan.title === "Basic") {
        return <span className="badge badge-warning margin-bottom-20 current-plan">
          Current Plan
        </span>
      }
    } else {
      if(profile.plan && profile.plan.plan && profile.plan.plan===plan.id) {
        return <span className="badge badge-warning margin-bottom-20 current-plan">
          Current Plan
        </span>
      }
    }
  }

  // renders
  const renderHistory = () => {
    if (isLoading) {
      return <h6>Loading...</h6>;
    }
    return (
      <div className="subscription-history">
        <div className="title-block">
          <h3 className="title">Subscription History</h3>
          <span>
            <span className="text-danger">*</span>Disputes are allowed only
            within 7 days of transaction. Check dispute status{" "}
            <Link
              className="txt-theme"
              style={{ fontSize: "0.8rem" }}
              to="/inbox"
            >
              here
            </Link>
            .
          </span>
        </div>

        <div className="col-12 pad-0">
          {/* Web Version Table */}
          <div className="custom-table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Subscribed On</th>
                  <th scope="col">Plan</th>
                  <th scope="col">Packs</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">Expires on</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {transactions && transactions.results.length > 0 ? (
                  transactions.results.map((item, index) => (
                    <tr key={index}>
                      <td data-column="Subscribed On">
                        {moment(item.created_on).format("DD MMM, YYYY")}
                      </td>
                      <td data-column="Plan">
                        {item.plan_details
                          ? item.plan_details.title || "-"
                          : "-"}
                      </td>
                      <td data-column="Packs">{item.packs || "-"}</td>
                      <td data-column="Start Date">
                        {item.start_date?moment(item.start_date).format("DD MMM, YYYY"):'-'}
                      </td>
                      <td data-column="Expires on">
                        {item.end_date?moment(item.end_date).format("DD MMM, YYYY"):'-'}
                      </td>
                      <td data-column="Status">
                        {item.status === "NOTSTARTED"
                          ? "NOT STARTED"
                          : item.status || "-"}
                      </td>
                      <td data-column="Action">
                        <span
                          onClick={() => {
                            setSelectedSubscription(item);
                          }}
                          className="fa fa-print c-pointer margin-right-5"
                        ></span>
                        {moment(item.created_on)
                          .add("days", 7)
                          .isAfter(moment(new Date())) &&
                          disputeList.indexOf(item.id) === -1 && (
                            <span
                              className="fa fa-hand-paper margin-right-5 c-pointer"
                              onClick={() => {
                                setDispute({
                                  ...dispute,
                                  subscription: item.id,
                                });
                                setPopupMode("dispute");
                              }}
                            ></span>
                          )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Subscriptions
                    </td>
                  </tr>
                )}
              </tbody>
              {(transactions && (transactions.next || transactions.previous)) && renderPagination()}
            </table>
          </div>
        </div>
      </div>
    );
  };

  const renderSummary = () => {
    return (
      selectedPlan && (
        <section id="my-wallet" className="my-wallet-plan-choose">
          <div className="payment-block payment-block-modal" id="add-coins">
            <div className="row">
              <div className="col-lg-6 border-right">
                <div className="summary-view">
                  <h3 className="title">
                    Plan Summary{" "}
                    <span>{payload.pricing ? payload.pricing.symbol : ""}</span>
                  </h3>
                  <ul className="pricing-list">
                    <li>
                      <p className="name">Plan</p>
                      <p className="value">{selectedPlan.title}</p>
                    </li>
                    <li>
                      <p className="name">Validity</p>
                      <p className="value">{selectedPlan.validity} days</p>
                    </li>
                    <li>
                      <p className="name">Price</p>
                      <p className="value">{(selectedPlan.pricing && selectedPlan.pricing[profile.region] && selectedPlan.pricing[profile.region].price!==0)?selectedPlan.pricing[profile.region].price:selectedPlan.price}</p>
                    </li>
                    <li>
                      <p className="name">Valid for</p>
                      <p className="value">
                        {selectedPlan.validity * packs} days
                      </p>
                    </li>
                  </ul>
                  <ul className="choose-list">
                    <li>
                      <button
                        className="btn btn-danger btn-sm"
                        disabled={packs <= 1}
                        onClick={() => {
                          setPacks(packs - 1);
                          calculatePricing(payload.use_wallet, packs - 1);
                        }}
                      >
                        -
                      </button>
                    </li>
                    <li>
                      <p>{packs}</p>
                    </li>
                    <li>
                      <button
                        className="btn btn-danger btn-sm"
                        disabled={packs >= 3}
                        onClick={() => {
                          setPacks(packs + 1);
                          calculatePricing(payload.use_wallet, packs + 1);
                        }}
                      >
                        +
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="price-view">
                  <h3 className="title">
                    Price Summary
                    <span>{payload.pricing ? payload.pricing.symbol : ""}</span>
                  </h3>
                  {payload.pricing ? (
                    <div>
                      <ul className="pricing-list">
                        <li key={1}>
                          <p className="name">Amount</p>
                          <p className="value">{payload.pricing.amount}</p>
                        </li>
                        <li key={2}>
                          <p className="name">Discount(-)</p>
                          <p className="value">{payload.pricing.discount}</p>
                        </li>
                        <li key={3}>
                          <p className="name">Wallet(-Cash Equivalent)</p>
                          <p className="value">{payload.pricing.wallet}</p>
                        </li>
                        <li key={4}>
                          <p className="name">Sub Total</p>
                          <p className="value">{payload.pricing.sub_total}</p>
                        </li>
                        {payload.pricing.tax.entries.map((item, idx) => (
                          <li key={5+idx}>
                            <p className="name">{item.label}(+)</p>
                            <p className="value">{item.amount}</p>
                          </li>
                        ))}
                      </ul>
                      <div className="use-checkbox">
                        <input
                          type="checkbox"
                          value={payload.use_wallet}
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              use_wallet: e.target.checked,
                            });
                            calculatePricing(e.target.checked);
                          }}
                          name=""
                          id=""
                        />{" "}
                        Use Go Coins balance
                      </div>
                      {payload.pricing && payload.pricing.extra_label && (
                        <span className="text-danger">
                          {payload.pricing.extra_label}
                        </span>
                      )}
                      <div>
                        <CouponList
                          type={"COUPONS"}
                          value={payload.coupon_code}
                          amount={
                            payload.pricing ? payload.pricing.sub_total : 0
                          }
                          setCoupoun={(val) => {
                            setPayload({
                              ...payload,
                              coupon_code: val,
                            });
                            setCoupon(val);
                          }}
                        />
                      </div>
                      <hr />
                      <div className="row margin-0">
                        <div className="col pad-0">Total:</div>
                        <div className="col pad-0 text-right bold">
                          {payload.pricing.total}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>Loading...</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row margin-0">
              <div className="col-12">
                {profile.status === "ACTIVE" && (
                  <div className="continue-button modal-continue-button">
                    {payload.pricing && (
                      <button
                        className="btn btn-yellow btn-sm"
                        onClick={checkout}
                      >
                        Continue to Pay {payload.pricing.total}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )
    );
  };

  const renderPlans = () => {
    if (isLoading) {
      return <h6>Loading...</h6>;
    }
    return (
      <div className="row margin-0">
        <ul className="membership-plans-prices">
          {plans.results.map((item, index) => (
            <li
              key={index}
              className={
                selectedPlan && selectedPlan.id === item.id ? "selected" : ""
              }
            >
              <div className="membership-plan-header">
                <div className="inner">
                  <div className="icon">
                    <img src="/top-yellow.png" className="img-fluid" alt="" />
                  </div>
                  <h3 className="plan-name">{item.title}</h3>
                  <h2 className="plan-validity">
                    {item.title !== "Basic" ? (
                      `For ${item.validity} days`
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </h2>
                </div>
              </div>
              <div className="membership-plan-body">
                {renderCurrentPlanLabel(item)}
                {" "}
                {item.title === "Basic" ? (
                  <h2 className="txt-theme bold d-block"> &nbsp; </h2>
                ) : (
                  <h2 className="pricing margin-bottom-0">
                    <span className="text-danger txt-strike">
                      {profile.region || "USD"} {(item.pricing && item.pricing[profile.region] && item.pricing[profile.region].original_price!==0)?item.pricing[profile.region].original_price:item.original_price}
                    </span>{" "}
                    {profile.region || "USD"} {(item.pricing && item.pricing[profile.region] && item.pricing[profile.region].price!==0)?item.pricing[profile.region].price:item.price}
                  </h2>
                )}
                {item.title !== "Basic"?<span>Or</span>:<h3 className="text-danger">Free Plan</h3>}
                {item.title === "Basic" ? (
                  <h2 className="txt-theme bold d-block" style={{minHeight: '10px'}}> &nbsp; </h2>
                ) : (
                  <h2 className="coins-price">
                    <span className="text-danger txt-strike">
                      {getCoinsValue((item.pricing && item.pricing[profile.region] && item.pricing[profile.region].original_price!==0)?item.pricing[profile.region].original_price:item.original_price)} Coins
                    </span>{" "}
                    {getCoinsValue((item.pricing && item.pricing[profile.region] && item.pricing[profile.region].price!==0)?item.pricing[profile.region].price:item.price)} Coins
                  </h2>
                )}
                <ul className="plan-features">
                  {item.options.map((itm, iddx) => (
                    <li key={iddx}>
                      <span className="fa fa-arrow-right"></span>
                      <p>{itm}</p>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="membership-plan-footer">
                {item.title === "Basic" ? (
                  ""
                ) : selectedPlan && selectedPlan.id === item.id ? (
                  <button className="go-button">
                    <span className="fa fa-check"></span> Selected
                  </button>
                ) : (
                  <button
                    className="go-button"
                    onClick={() => {
                      setPlan(item);
                    }}
                  >
                    Go
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
        <div className="col-12 pad-0">
          <IGModal
            visible={selectedPlan}
            body={renderSummary}
            close={() => setSelectedPlan(null)}
            isLogin={false}
          />
        </div>
      </div>
    );
  };

  return (
    <section id="membership-page">
      <Banner target={"MEMBERSHIP"} />
      <div className="row margin-0 shadow-theme">
        <div className="mt-2">
          <div className="row margin-0">
            {profile.plan === null ? (
              <div className="each-item">
                <h3 className="main-title">
                  <span class="name">Current Plan :</span>
                  <span class="value">{profile.basic.title}</span>
                </h3>
                <div className="benefits">
                  <h5>Benefits:</h5>
                  <ul>
                    {profile.basic !== null &&
                      profile.basic.options.map((itm, iddx) => (
                        <li key={iddx}>
                          <span className="fa fa-arrow-right txt-theme"></span>
                          <p>{itm}</p>
                        </li>
                      ))}
                    <li></li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className="each-item">
                <h3 className="main-title">
                  <span class="name">Current Plan :</span>
                  <span className="value">
                    {profile.plan === null
                      ? "Basic"
                      : profile.plan.plan_details.title}{" "}
                  </span>
                  {profile.plan !== null && (
                    <span className="date">
                      ({moment(profile.plan.start_date).format("DD MMM, YYYY")}{" "}
                      - {moment(profile.plan.end_date).format("DD MMM, YYYY")})
                    </span>
                  )}
                </h3>
                <div className="benefits">
                  <h5>Benefits:</h5>
                  <ul>
                    {profile.plan !== null &&
                      profile.plan.plan_details.options.map((itm, iddx) => (
                        <li key={iddx}>
                          <span className="fa fa-arrow-right txt-theme"></span>
                          <p>{itm}</p>
                        </li>
                      ))}
                    <li></li>
                  </ul>
                </div>
              </div>
            )}
            {profile.next_plan && (
              <div className="each-item">
                <h3 className="main-title">
                  <span className="name">Upcoming Plan :</span>
                  <span className="value">
                    {profile.next_plan.plan_details.title}
                  </span>{" "}
                  <small className="date">
                    (
                    {moment(profile.next_plan.start_date).format(
                      "DD MMM, YYYY"
                    )}{" "}
                    -{" "}
                    {moment(profile.next_plan.end_date).format("DD MMM, YYYY")})
                  </small>
                </h3>
                <div className="benefits">
                  <h5>Benefits:</h5>
                  <ul>
                    {profile.next_plan.plan_details.options.map((itm, iddx) => (
                      <li key={iddx}>
                        <span className="fa fa-arrow-right txt-theme"></span>
                        <p>{itm}</p>
                      </li>
                    ))}
                    <li></li>
                  </ul>
                </div>
              </div>
            )}
            <div className="history-plans">
              <ul className="default-tabs-horizontal">
                <li className={`${tab === "HISTORY" ? "active" : ""}`}>
                  <a
                    onClick={() => {
                      setTab("HISTORY");
                    }}
                  >
                    History
                  </a>
                </li>
                <li className={`${tab === "PLANS" ? "active" : ""}`}>
                  <a
                    onClick={() => {
                      setTab("PLANS");
                    }}
                  >
                    Plans
                  </a>
                </li>
              </ul>
              <div className="tab-data">
                {tab === "HISTORY" && renderHistory()}
                {tab === "PLANS" && renderPlans()}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Modal
            show={popupMode !== null}
            onHide={() => {
              setPopupMode(null);
            }}
          >
            <Modal.Body>
              <div className="row margin-0">
                {popupMode === "dispute" && (
                  <div className="col-12 pad-0 text-center">
                    <h4>Select Your Dispute Reason</h4>
                    <div className="form-group">
                      <label>Reason</label>
                      <select
                        name=""
                        id=""
                        className="form-control form-control-sm"
                        value={dispute.reason}
                        placeholder="Select"
                        onChange={(e) => {
                          setDispute({
                            ...dispute,
                            reason: e.target.value,
                          });
                        }}
                      >
                        <option value="null">Select</option>
                        {lookups &&
                          lookups.DISPUTE_REASON &&
                          lookups.DISPUTE_REASON.codes.map((item, index) => (
                            <option key={index} value={item.code}>
                              {item.description}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="text-center pad-10">
                      <button
                        className="btn btn-theme btn-sm margin-right-10"
                        onClick={() => {
                          createDispute();
                        }}
                      >
                        Submit
                      </button>
                      <button
                        className="btn btn-plain btn-sm"
                        onClick={() => {
                          setPopupMode(null);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <div class="invoice-modal">
          {selectedSubscription && (
            <ModalAction
              action={{
                type: "SUBSCRIPTION_INVOICE",
                data: selectedSubscription,
              }}
              close={() => {
                setSelectedSubscription(null);
              }}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Membership;
