import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { IGInput } from "../../ignitor-react";
import { validateEmail } from '../../constants';
import { Link, useHistory } from "react-router-dom";
import $cs from '../../services.js'
import { setProfile } from '../../store/userReducer';
import "../styles/Login.css";
import IGModal from "../../ignitor-react/IGModal";

const LoginComponent = (props) => {
    const dispatch = useDispatch();
    const { mode, handleAction, setPage } = props;
    const [error, setError] = useState({});
    const history = useHistory();
    const [payload, setPayload] = useState({
        email: '',
        password: ''
    });
    const [show, setShow] = useState(true);
    const [pageTopic, setPageTopic] = useState(new URLSearchParams(window.location.search).get("topic") || null);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShow(false);
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const validateLogin = () => {
        let proceed = true;
        let errorObj = {};
        if (!payload.email || payload.email === "") {
            errorObj.email = 'Email Address is required';
            proceed = false;
        }
        if (payload.email && !validateEmail(payload.email)) {
            errorObj.email = 'Enter valid Email Address';
            proceed = false;
        }
        if (!payload.password || payload.password === "") {
            errorObj.password = 'Password is required';
            proceed = false;
        }
        setError(errorObj);
        return proceed;
    }

    const Login = (e) => {
        e.preventDefault();
        const finalPayload = {
            ...payload,
            email: payload.email.toLowerCase()
        }
        if (validateLogin()) {
            $cs.auth.login(finalPayload).then(res => {
                if(res.data.status && res.data.status === 'failed') {
                    setError({
                        plain: res.data.message
                    });
                } else {
                    localStorage.setItem('Token', res.data.token);
                    localStorage.setItem('profile', JSON.stringify(res.data.user));
                    dispatch(
                        setProfile(res.data.user)
                    );
                    if (mode === "plain") {
                        handleAction('stage', 2);
                    } else {
                        const topic = new URLSearchParams(window.location.search).get("topic");
                        if(topic) {
                            if (res.data.user.user_type === "STUDENT") {
                                history.push(
                                  `/find-teacher/?topic=${topic}`
                                );
                              } else if (res.data.user.user_type === "TEACHER") {
                                history.push(
                                  `/find-students/?topic=${topic}`
                                );
                              }
                        } else {
                            history.push("/dashboard");
                        }
                    }
                }
            }, err => {
                setError({
                    plain: 'Invalid Credentials'
                })
            });
        }
    }

    const socialResponseHandler = (data) => {
        console.log("socialResponseHandler", data);
        $cs.anyAction.create({
            data: {
                ...data,
                action: 'SOCIAL_LOGIN'
            }
        }).then(res => {
            localStorage.setItem('Token', res.data.token);
            localStorage.setItem('profile', JSON.stringify(res.data.user));
            dispatch(
                setProfile(res.data.user)
            );
            history.push("/dashboard");
        }, err => {
            setError({
                plain: 'Invalid Credentials'
            });
        });
    }

    const renderBody = () => {
        return (
            <div className="row margin-0 justify-content-center">
                <div className="col-12">
                    <div className="row margin-0">
                        <div className="col text-center">
                            <img src="/logo-ico.png" style={{ maxHeight: '50px' }} alt="" />
                        </div>
                        <div className="col-12 text-center">
                            <h4 className="bold txt-theme margin-top-15">Login</h4>
                            <div className="">
                                <span>
                                    {
                                        mode === "plain" ? (<span className="txt-theme-link c-pointer" onClick={() => {
                                            setPage('signup')
                                        }}>Create an account</span>) : (<Link className="txt-theme-link c-pointer" to={`/signup${pageTopic?"?topic="+pageTopic:''}`}>Create an account</Link>)
                                    } for free</span>
                            </div>
                        </div>
                        <div className="col-12 pad-top-15 pad-bottom-20">
                            {
                                error && error.plain && <div className="text-center">
                                    <span className="text-danger">{error.plain}</span>
                                </div>
                            }
                            <form onSubmit={Login}>
                                <IGInput
                                    type="email"
                                    // ref={emailValue}
                                    label={'Email Address'}
                                    autofocus
                                    isRequired={true}
                                    placeholder="Email Address"
                                    value={payload.email}
                                    error={error.email}
                                    maxLength={100}
                                    setValue={(val) => {
                                        setPayload({
                                            ...payload,
                                            email: val?val.toLowerCase():val,
                                        });
                                    }}
                                />
                                <IGInput
                                    type="password"
                                    label="Password"
                                    placeholder="Password"
                                    isRequired={true}
                                    value={payload.password}
                                    error={error.password}
                                    setValue={(val) => {
                                        setPayload({
                                            ...payload,
                                            password: val
                                        });
                                    }}
                                />
                                <div className="text-right" >
                                    <span><Link className="txt-theme-link c-pointer" to="/forgot-password"> Forgot Password?</Link></span>
                                </div>
                                <div className="pad-top-15 text-center">
                                    <button type='submit' className="btn btn-yellow w-100 btn-size-theme">Log in</button>
                                </div>
                            </form>


                            {/* <div className="pad-15 text-center">
                                <div className="row margin-0">
                                    <div className="col pad-0"><hr style={{ borderColor: '#229A87' }} /></div>
                                    <div className="col-12 max-fit-content txt-theme" style={{ paddingTop: '0.25rem' }}>Or Continue with</div>
                                    <div className="col pad-0"><hr style={{ borderColor: '#229A87' }} /></div>
                                </div>
                            </div>
                            <div className='pad-15'>
                                <div className="row margin-0 justify-content-center">
                                    <div className="col max-fit-content">
                                        <SocialLogins app='google' label="Sign in" responseHandler={socialResponseHandler} />
                                    </div>
                                    <div className="col max-fit-content">
                                        <SocialLogins app='facebook' label="Sign in" responseHandler={socialResponseHandler} />
                                    </div>
                                    <div className="col max-fit-content">
                                        <SocialLogins app='linkedin' label="Sign in" responseHandler={socialResponseHandler} />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return <IGModal visible={true} body={renderBody} close={() => {
        history.push("/");
    }} />
}

export default LoginComponent;
