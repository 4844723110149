import $cs from "./services";


const initatePayment = (payment_link, profile) => {
    return new Promise((resolve, reject) => {
        var options = payment_link;
        options.prefill = {
            name: `${profile.first_name} ${profile.last_name}`,
            email: profile.email,
            contact: profile.mobile,
        };
        // after payment success handler
        options.handler = function(response) {
            console.log(response);
            resolve({
                ...response,
                ...payment_link.notes,
                status: 'Completed',
            });
        };
        options.modal = {
            ondismiss: function(response) {
                console.log(response, "Checkout form closed");
                resolve({
                    ...response,
                    ...payment_link.notes,
                    status: 'Failed',
                });
            },
        };
        // Razorpay pop for payment
        var rzp = new window.Razorpay(options);
        rzp.open();
        // event.preventDefault();
    });
};

const preparePricing = (sub_total = 0, discount = 0, taxes = []) => {
    let total = sub_total - discount;
    let tax = {
        total: 0,
        deductable: 0,
        entries: []
    };
    taxes.forEach(item => {
        let taxObj = {
            label: item.label,
            tax_type: item.tax_type,
            value: item.value,
            amount: 0
        };
        if(item.tax_type === "PERCENTAGE") {
            taxObj.amount = (total * (parseFloat(item.value) / 100));
        } else {
            taxObj.amount = parseFloat(item.value);
        }
        if(item.applicable_on === "EXCLUSIVE") {
            tax.deductable += taxObj.amount;
        }
        tax.total += taxObj.amount;
        tax.entries.push(taxObj);
    });
    total = total + tax.deductable;
    return {
        sub_total,
        discount,
        tax,
        total
    }
};

export {
    initatePayment,
    preparePricing
}