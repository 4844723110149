import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import $cs from "../services.js";
import { Link, useHistory } from "react-router-dom";
import InputCourse from "./components/InputCourse";
import RatingDetail from "./components/RatingDetail";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import {
  realTimeProfileSelector,
  enrollmentsSelector,
  profilesSelector,
  skillsSelector,
  getProfiles,
  setProfile,
  userTypeSelector,
  getEnquires,
  enquiriesSelector,
  getEnrollments,
} from "../store/userReducer";
import EnquiriesList from "./components/EnquiriesList";

const UpcomingAnnouncements = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(realTimeProfileSelector);
  const enrollments = useSelector(enrollmentsSelector);

  useEffect(() => {
    document.body.className = "dashboard-page";
  });

  return (
    <section id="about-page">
      <div className="page-banner">
        <div className="inner">
          <h2 className="page-name">Announcements</h2>
          <img src="/index-block-top.png" alt="shape" className="img-fluid" />
        </div>
        <span className="slider-shape"></span>
      </div>
      <div className="inner">
        <div className="container">
          <div className="row justify-content-between pad-top-40 pad-bottom-40">
            <div className="col-lg-6">
              <div className="about-content">
                <h3 className="main-heading margin-bottom-0">Happening! <br /><span className="text-secondary" style={{fontSize: '0.7em'}}>Upcoming training sessions offered.</span></h3>
              </div>
            </div>
            {
              (!profile || (profile && profile.user_type !== 'STUDENT')) && <div className="col-lg-12">
                <span className="pad-bottom-15">Do you want to post announcements of your upcoming trainings. {(profile && profile.email)?<Link className="txt-theme" style={{fontSize: '1em'}} to="/new-announcement">Post Announcement</Link>:<Link className="txt-theme" style={{fontSize: '1em'}} to="/signup">Login/Register as Teacher</Link>}</span>
              </div>
            }
            <div className="col-lg-12 pad-0">
              <EnquiriesList
                mode="home"
                params={{
                  topic: (new URLSearchParams(window.location.search)).get("topic") || null
                }}
                enquiryType={"UPCOMING"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpcomingAnnouncements;
