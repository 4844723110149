import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import $cs from "../services.js";
import { Button, Spinner, Container, Row, Col } from "react-bootstrap";
import { lookupsSelector, appSettingsSelector } from "../store/appReducer";
import {
  realTimeProfileSelector,
  profileSelector,
  notificationSelector,
  planSelector,
  setProfile,
  getProfile,
  getProfiles,
  resetUserState,
  currentProfileSelector,
  setCurrentProfile,
  userTypeSelector,
  actionsSelector,
  getActions,
} from "../store/userReducer";

const Inbox = (props) => {
  const dispatch = useDispatch();
  console.log(props);
  const profile = useSelector(realTimeProfileSelector);
  const actions = useSelector(actionsSelector);
  const lookups = useSelector(lookupsSelector);
  const [error, setError] = useState({});
  const [newRequest, setNewRequest] = useState(
    new URLSearchParams(window.location.search).get("action") ? true : false
  );

  // const [isLoading, setLoading] = useState(true);

  const getDefaultValue = (optionKey) => {
    let defaultValue = [];
    if (lookups && lookups[optionKey]) {
      defaultValue = lookups[optionKey].codes.filter((item) => item.is_default);
    }
    console.log(defaultValue);
    return defaultValue.length > 0 ? defaultValue[0].code : "";
  };

  const [payload, setPayload] = useState({
    action:
      new URLSearchParams(window.location.search).get("action") ||
      getDefaultValue("SERVICE_COMPLAINT_TYPE"),
    user: profile.id,
    data: {
      comment: "",
    },
  });

  useEffect(() => {
    if (lookups !== null && payload.action === null) {
      setPayload({
        action:
          new URLSearchParams(window.location.search).get("action") ||
          getDefaultValue("SERVICE_COMPLAINT_TYPE"),
        user: profile.id,
        data: {
          comment: "",
        },
      });
    }
  }, [lookups]);

  const getErrorMessage = (field) => {
    if (error && error[field]) {
      return <span className="error">{error[field]}</span>;
    }
    return "";
  };

  const createRequest = () => {
    $cs.action
      .create({
        data: payload,
      })
      .then(
        (res) => {
          dispatch(getActions());
          setPayload({
            action: getDefaultValue("SERVICE_COMPLAINT_TYPE"),
            user: profile.id,
            data: {
              comment: "",
            },
          });
          setNewRequest(false);
        },
        (err) => { }
      );
  };

  const getTrasactionDetail = (item) => {
    switch (item.action) {
      case "COIN_DISPUTE":
        if (item.data_details) {
          return `(${moment(item.data_details.created_on).format(
            "DD MMM, YYYY"
          )}, ${item.data_details.coins})`;
        }
        break;
      case "ADD_NEW_COURSE":
      case "SERVICE_REQUEST":
        if (item.data_details) {
          return `(${item.data.comment})`;
        }
        break;
    }
    return "";
  };

  const renderActions = () => {
    if (actions === null) {
      return (
        <tr>
          <td>Loading...</td>
        </tr>
      );
    } else {
      if (actions && actions.length > 0) {
        return actions.map((item, index) => (
          <tr key={index}>
            <td>{moment(item.created_on).format("DD MMM, YYYY")}</td>
            <td>
              {item.action} {getTrasactionDetail(item)}
            </td>
            <td>{item.status}</td>
            <td>{item.comments || "-"}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan="4" className="text-center">
              No Actions
            </td>
          </tr>
        );
      }
    }
  };

  return (
    <section id="my-inbox">
      <div className="row margin-0">
        <div className="col-12 pad-0">
          <div className="shadow-theme pad-15">
            <div className="row margin-0">
              <div className="col-6 pad-0">
                <h4 className="txt-theme bold margin-bottom-0">My Inbox</h4>
              </div>
              {
                  !newRequest && <div className="col-6 pad-0 text-right mb-visible">
                    <button
                      className="btn btn-sm btn-yellow"
                      onClick={() => {
                        setNewRequest(true);
                      }}
                    >
                      + New
                    </button>
                  </div>
                }
            </div>
            {newRequest && (
              <div
                className="margin-top-30 pad-20 margin-bottom-20"
                style={{ border: "1px solid #ddd" }}
              >
                <label className="bold margin-0">New Request</label>
                <div className="row margin-0 pad-top-10">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 pad-0">
                    <div className="form-group">
                      <label>
                        {lookups && lookups.SERVICE_COMPLAINT_TYPE.description
                          ? lookups.SERVICE_COMPLAINT_TYPE.description
                          : "Request Type"}
                      </label>
                      <select
                        name=""
                        id=""
                        className="form-control form-control-sm"
                        value={payload.action}
                        placeholder="Select"
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            action: e.target.value,
                          });
                        }}
                      >
                        <option value="null">Select</option>
                        {lookups &&
                          lookups.SERVICE_COMPLAINT_TYPE &&
                          lookups.SERVICE_COMPLAINT_TYPE.codes.map(
                            (item, index) => (
                              <option key={index} value={item.code}>
                                {item.description}
                              </option>
                            )
                          )}
                      </select>
                      {getErrorMessage("skill")}
                    </div>
                  </div>
                  <div className="col-12 pad-0">
                    <div className="form-group">
                      <label>Comments</label>
                      <br />
                      <textarea
                        name=""
                        id=""
                        className="form-control form-control-sm"
                        maxLength={100}
                        style={{ width: "100%" }}
                        rows="4"
                        placeholder="Enter Comments"
                        value={payload.data.comment}
                        placeholder="Enter Comments"
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            data: {
                              ...payload.data,
                              comment: e.target.value,
                            },
                          });
                        }}
                      ></textarea>
                      <div className="text-right">
                        <span>{payload.data.comment.length}/100</span>
                      </div>
                      {getErrorMessage("comment")}
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      className="btn btn-sm btn-theme"
                      onClick={() => {
                        createRequest();
                      }}
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-sm btn-plain margin-left-10"
                      onClick={() => {
                        setNewRequest(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="margin-top-20">
              <div className="row margin-0">
                
                <div className="col-md-6 pad-left-0 pad-bottom-10">
                  <h5 className="bold">Service Requests</h5>
                  <span className="text-secondary">
                    For any technical issue, send details and screenshot to
                    info@goteacherpro.com
                  </span>
                </div>
                {
                  !newRequest && <div className="col-md-6 pad-0 text-right pad-bottom-10 mb-hide">
                    <button
                      className="btn btn-sm btn-yellow"
                      onClick={() => {
                        setNewRequest(true);
                      }}
                    >
                      + New
                    </button>
                  </div>
                }
                <div className="col-12 pad-0">
                  <div className="table-responsive table-theme">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Request Date</th>
                          <th scope="col">Request Type</th>
                          <th scope="col">Status</th>
                          <th scope="col">Admin Feedback</th>
                        </tr>
                      </thead>
                      <tbody>{renderActions()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Inbox;
