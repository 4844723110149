import { useEffect, useState } from "react";

const Benfits = (props) => {
  return (
    <section id="befits-page">
      <div className="page-banner">
        <div className="inner">
          <h2 className="page-name">News &amp; Articles</h2>
          <img src="/index-block-top.png" alt="shape" className="img-fluid" />
        </div>
        <span className="slider-shape"></span>
      </div>
      <div className="page-start">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <iframe src="/StartupIndia_DPIITCertification.pdf#toolbar=0" frameborder="0" style={{width: '100%', height: '100vh'}}>
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benfits;
