import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner, Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import $cs from "../services.js";
import moment from "moment";
import * as momenttz from "moment-timezone";
import { IGInput } from "../ignitor-react";
import ModalAction from "./components/modalActions";
import { lookupsSelector, appSettingsSelector } from "../store/appReducer";
import {
  realTimeProfileSelector,
  actionsObjSelector,
  setProfile,
  getProfile,
  getRealTimeProfile,
  getActions,
} from "../store/userReducer";

const Profile = (props) => {
  const dispatch = useDispatch();
  // const valueSelector = useSelector();
  const timezones = momenttz.tz.names();
  const [isLoading, setLoading] = useState(true);
  const [mode, setMode] = useState("view");
  const [popupMode, setPopupMode] = useState(null);
  const profile = useSelector(realTimeProfileSelector);
  const actions = useSelector(actionsObjSelector);
  const lookups = useSelector(lookupsSelector);
  const [payload, setPayload] = useState(null);
  const [action, setAction] = useState(null);

  const [error, setError] = useState({});

  const validateProfile = () => {
    let proceed = true;
    let errorObj = {};
    if (!payload.dob || payload.dob === "") {
      errorObj.dob = "Date of Birth is required";
      proceed = false;
    }
    if (
      payload.dob &&
      moment(new Date(payload.dob)).isAfter(moment(new Date()).add(-1, "days"))
    ) {
      errorObj.dob = "Date of Birth should not be today or greater than today";
      proceed = false;
    }
    if (profile.user_type === "TEACHER") {
      payload.credentials.education.forEach((item) => {
        if (!item.title || item.title === "") {
          errorObj.education = "Education is required";
          proceed = false;
        }
      });
      payload.credentials.experience.forEach((item) => {
        if (!item.title || item.title === "") {
          errorObj.experience = "Experience is required";
          proceed = false;
        }
      });
      if (!payload.description || payload.description === "") {
        errorObj.description = "Description is required";
        proceed = false;
      }
      if (payload.description && payload.description.length < 200) {
        errorObj.description = "Description should be minimum 200 characters";
        proceed = false;
      }
      if (!payload.gender || payload.gender === "") {
        errorObj.gender = "Gender is required";
        proceed = false;
      }
      // if (!payload.dob || payload.dob === "") {
      //   errorObj.dob = "Date of Birth is required";
      //   proceed = false;
      // }
      // if (payload.dob && moment(new Date(payload.dob)).isAfter(moment(new Date()).add(-1, "days"))) {
      //   errorObj.dob = "Date of Birth should not be greater than today";
      //   proceed = false;
      // }
      // if (payload.dob && new Date(payload.dob) > new Date()) {
      //   errorObj.dob = "Date of Birth should not be greater than today";
      //   proceed = false;
      // }
      if (!payload.demo || payload.demo === "") {
        errorObj.demo = "Demo is required";
        proceed = false;
      }
      if (!payload.mode || payload.mode === "") {
        errorObj.mode = "Teaching Mode is required";
        proceed = false;
      }
      if (payload.demo === "Yes") {
        if (!payload.timezone || payload.timezone === "") {
          errorObj.timezone = "Timezone is required";
          proceed = false;
        }
        if (!payload.demo_preference || payload.demo_preference.length < 1) {
          errorObj.demo_preference = "Atleast 1 preference is required";
          proceed = false;
        }
      }
    }
    setError(errorObj);
    return proceed;
  };

  const updateProfile = (dt) => {
    if (validateProfile()) {
      let finalPayload = dt || payload;
      if (finalPayload.dob) {
        finalPayload.dob = moment(finalPayload.dob).format("MM-DD-YYYY");
      }
      $cs.profile
        .update({
          data: finalPayload,
        })
        .then(
          (res) => {
            dispatch(getRealTimeProfile());
            dispatch(getActions());
            setMode("view");
          },
          (err) => {}
        );
    }
  };

  const getDefaultValue = (optionKey) => {
    console.log(`getDefaultValue:${optionKey}`);
    let defaultValue = [];
    if (lookups && lookups[optionKey]) {
      defaultValue = lookups[optionKey].codes.filter((item) => item.is_default);
    }
    return defaultValue.length > 0 ? defaultValue[0].code : "";
  };

  //   const renderDynamicInput = (label, key, optionKey) => {
  //     return (
  //         <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
  //             <IGInput
  //                 type="select"
  //                 label={lookups[optionKey].description || label}
  //                 placeholder={lookups[optionKey].description || label}
  //                 value={payload.form[key]}
  //                 options={lookups && lookups[optionKey]?lookups[optionKey].codes.map((item, index) => [item.code, item.description]):[]}
  //                 error={error[key]}
  //                 setValue={(val) => {
  //                     setPayload({
  //                         ...payload,
  //                         form: {
  //                             ...payload.form,
  //                             [key]: val
  //                         }
  //                     })
  //                 }}
  //             />
  //         </div>
  //     )
  // }

  // background: rgb(9,82,121);
  // background: linear-gradient(320deg, rgba(9,82,121,0) 27%, rgba(0,212,255,1) 100%);
  useEffect(() => {
    if (profile !== null) {
      let newPayload = Object.assign({}, profile);
      if (!newPayload.data || newPayload.data === undefined) {
        newPayload.data = null;
      }
      if (newPayload.gender === null || newPayload.gender === "") {
        newPayload.gender = getDefaultValue("GENDER");
      }
      if (newPayload.mode === null || newPayload.mode === "") {
        newPayload.mode = getDefaultValue("ENQUIRY_MODE");
      }
      if (newPayload.data === null) {
        newPayload.data = {
          linkedIn: null,
          facebook: null,
          video: null,
          website: null,
        };
      }
      if (!newPayload.credentials || !newPayload.credentials.experience) {
        newPayload.credentials = {
          education: [
            {
              title: null,
              file: null,
            },
          ],
          experience: [
            {
              title: null,
              file: null,
            },
          ],
          certification: [
            {
              title: null,
              file: null,
            },
            {
              title: null,
              file: null,
            },
          ],
        };
      }
      setPayload(newPayload);
    }
  }, [profile, mode]);

  const getErrorMessage = (field) => {
    if (error && error[field]) {
      return <span className="error">{error[field]}</span>;
    }
    return "";
  };

  // const handleUpload = () => {
  //     const File = document.getElementById('certificateFile').files[0];
  //     if (File !== undefined) {
  //         $cs.img.upload(File, 'documents').then(url => {
  //             setDegreePayload({
  //                 ...degreePayload,
  //                 file: url
  //             });
  //         }, err => {
  //             debugger;
  //         });
  //     }
  // };

  const renderProfileBasic = () => {
    if (payload !== null && mode === "edit") {
      return (
        <section class="profile-data-view">
          <div className="row margin-0">
            <div className="col-12">
              <h5 className="sub-heading">
                Basic Info
              </h5>
            </div>
            <div className="col-lg-4 col-sm-6">
              <IGInput
                label="First Name"
                isRequired={true}
                placeholder="First Name"
                value={payload.first_name}
                error={error.first_name}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    first_name: val,
                  });
                }}
              />
            </div>
            <div className="col-lg-4 col-sm-6">
              <IGInput
                label="Last Name"
                placeholder="Last Name"
                value={payload.last_name}
                error={error.last_name}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    last_name: val,
                  });
                }}
              />
            </div>
            <div className="col-lg-4 col-sm-6">
              <IGInput
                type={payload.entity_type === "INDIVIDUAL" ? "label" : "text"}
                label="Institute/Academy Name"
                isRequired={true}
                placeholder="Institute/Academy"
                value={payload.consultant}
                error={error.consultant}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    consultant: val,
                  });
                }}
              />
            </div>
            <div className="col-lg-4 col-sm-6">
              <IGInput
                type="label"
                label="Entity Type"
                isRequired={true}
                placeholder="Entity Type"
                value={payload.entity_type}
                error={error.entity_type}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    entity_type: val,
                  });
                }}
              />
            </div>
            <div className="col-lg-4 col-sm-6">
              <IGInput
                type={"date"}
                label="Date Of Birth/ Incorporation"
                isRequired={true}
                placeholder="Date Of Birth/ Incorporation"
                value={payload.dob}
                error={error.dob}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    dob: val,
                  });
                }}
              />
            </div>
            <div className="col-lg-4 col-sm-6">
              <IGInput
                label={
                  lookups && lookups.GENDER
                    ? lookups.GENDER.description
                    : "Gender"
                }
                type="select"
                options={
                  lookups && lookups.GENDER
                    ? lookups.GENDER.codes.map((item, index) => [
                        item.code,
                        item.description,
                      ])
                    : []
                }
                isRequired={true}
                placeholder="Gender"
                value={payload.gender}
                error={error.gender}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    gender: val,
                  });
                }}
              />
            </div>
            <div className="col-lg-4 col-sm-6">
              {/* {JSON.parse(payload.address)} */}
              <IGInput
                type="location"
                label="Location"
                isRequired={true}
                placeholder="Location"
                value={payload.address}
                error={error.address}
                setValue={(val) => {
                  console.log(val);
                  setPayload({
                    ...payload,
                    address: val,
                  });
                }}
              />
            </div>
            <div className="col-lg-4 col-sm-6">
              <IGInput
                type="label"
                label="Currency"
                isRequired={true}
                placeholder="Currency"
                value={payload.region}
                error={error.region}
              />
            </div>
            <div className="col-lg-4 col-sm-6">
              <IGInput
                type="label"
                label="Email Address"
                isRequired={true}
                placeholder="Email Address"
                value={payload.email}
                error={error.email}
              />
            </div>
            {/* <div className="col-6">
                      <IGInput
                          type="phone"
                          label="Mobile Number"
                          isRequired={true}
                          action={!payload.mobile_verified?()=>{
                              setAction('mobile')
                          }:undefined}
                          placeholder="Mobile Number"
                          value={payload.mobile}
                          error={error.mobile}
                          setValue={(val) => {
                              setPayload({
                                  ...payload,
                                  mobile: val
                              });
                          }}
                      />
                  </div> */}
            {payload.user_type === "TEACHER" && (
              <div className="col-6">
                <IGInput
                  label={
                    lookups && lookups.ENQUIRY_MODE
                      ? lookups.ENQUIRY_MODE.description
                      : "Teaching Mode"
                  }
                  type="select"
                  options={
                    lookups && lookups.ENQUIRY_MODE
                      ? lookups.ENQUIRY_MODE.codes.map((item, index) => [
                          item.code,
                          item.description,
                        ])
                      : []
                  }
                  isRequired={true}
                  placeholder="Teaching Mode"
                  value={payload.mode}
                  error={error.mode}
                  setValue={(val) => {
                    setPayload({
                      ...payload,
                      mode: val,
                    });
                  }}
                />
              </div>
            )}
            {payload.user_type === "TEACHER" && (
              <div className="col-6">
                <IGInput
                  label={
                    lookups && lookups.ENQUIRY_FREE_DEMO
                      ? lookups.ENQUIRY_FREE_DEMO.description
                      : "Provides Free Demo"
                  }
                  type="select"
                  options={
                    lookups && lookups.ENQUIRY_FREE_DEMO
                      ? lookups.ENQUIRY_FREE_DEMO.codes.map((item, index) => [
                          item.code,
                          item.description,
                        ])
                      : []
                  }
                  isRequired={true}
                  placeholder="Provides Free Demo"
                  value={payload.demo}
                  error={error.demo}
                  setValue={(val) => {
                    setPayload({
                      ...payload,
                      demo: val,
                    });
                  }}
                />
              </div>
            )}
            {payload.demo === "Yes" && (
              <div className="col-6">
                <IGInput
                  label={"Timezone"}
                  type="select"
                  options={timezones || []}
                  isRequired={true}
                  placeholder="Timezone"
                  value={payload.timezone}
                  error={error.timezone}
                  setValue={(val) => {
                    setPayload({
                      ...payload,
                      timezone: val,
                    });
                  }}
                />
              </div>
            )}
            {payload.demo === "Yes" && (
              <div className="col-6">
                <IGInput
                  label={
                    lookups && lookups.DEMO_AVAILABILITY
                      ? lookups.DEMO_AVAILABILITY.description
                      : "Demo Availability"
                  }
                  infoLabel={"Select multiple timings"}
                  type="select"
                  options={
                    lookups && lookups.DEMO_AVAILABILITY
                      ? lookups.DEMO_AVAILABILITY.codes.map((item, index) => [
                          item.code,
                          item.description,
                        ])
                      : []
                  }
                  isRequired={true}
                  multiple={true}
                  placeholder="Demo Availability"
                  value={payload.demo_preference}
                  error={error.demo_preference}
                  setValue={(val) => {
                    setPayload({
                      ...payload,
                      demo_preference: val,
                    });
                  }}
                />
                <br /> <br />
              </div>
            )}
            {payload.user_type === "TEACHER" && (
              <div className="col-12">
                <IGInput
                  type="textarea"
                  label="About Me"
                  isRequired={true}
                  placeholder="Let the students know what you are best in! In this block never share your emailID, phone number or any other contact information."
                  value={payload.description}
                  error={error.description}
                  setValue={(val) => {
                    setPayload({
                      ...payload,
                      description:
                        val && val.length > 1000 ? val.slice(0, 1000) : val,
                    });
                  }}
                />
                <div className="text-right" style={{ fontSize: "0.7rem" }}>
                  {payload.description ? payload.description.length : 0}/1000
                </div>
              </div>
            )}
          </div>
        </section>
      );
    } else if (payload !== null && mode === "view") {
      return (
        <section class="profile-data-view">
          <div className="row margin-0">
            <div className="col-12">
              <h5 class="sub-heading">Basic Info</h5>
            </div>
            <div className="col-lg-4 col-sm-6 col-6">
              <div className="info-block">
                <label htmlFor="">
                  First Name<span className="text-danger">*</span>
                </label>
                <p>{profile.first_name || "-"}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="info-block">
                <label htmlFor="">Last Name</label>
                <p>{profile.last_name || "-"}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="info-block">
                <label htmlFor="">Institute/Academy Name</label>
                <p>{profile.consultant || "-"}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="info-block">
                <label htmlFor="">
                  Entity Type<span className="text-danger">*</span>
                </label>
                <p>{profile.entity_type || "-"}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="info-block">
                <label htmlFor="">
                  Date Of Birth/ Incorporation
                  <span className="text-danger">*</span>
                </label>
                <p>
                  {profile.dob
                    ? moment(profile.dob).format("DD MMM, YYYY")
                    : "-"}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="info-block">
                <label htmlFor="">
                  Gender<span className="text-danger">*</span>
                </label>
                <p>{profile.gender || "-"}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="info-block">
                <label htmlFor="">
                  Location<span className="text-danger">*</span>
                </label>
                <p>
                  {profile.address
                    ? profile.address.name || profile.address
                    : "-"}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="info-block">
                <label htmlFor="">
                  Currency<span className="text-danger">*</span>
                </label>
                <p>{profile.region || "-"}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="info-block">
                <label htmlFor="">
                  Email Address<span className="text-danger">*</span>
                </label>
                <p>{profile.email || "-"}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="info-block">
                <label htmlFor="">
                  Mobile Number<span className="text-danger">*</span>
                </label>
                <p>
                  {profile.mobile || "-"}
                  {!payload.mobile_verified ? (
                    <span
                      className="btn-underline bold margin-left-20"
                      onClick={() => {
                        setAction("mobile");
                      }}
                    >
                      Verify
                    </span>
                  ) : (
                    <span
                      className="btn-underline bold margin-left-20"
                      onClick={() => {
                        setAction("mobile");
                      }}
                    >
                      Change Number
                    </span>
                  )}
                </p>
              </div>
            </div>
            {payload.user_type === "TEACHER" && (
              <div className="col-lg-4 col-sm-6">
                <div className="info-block">
                  <label htmlFor="">
                    Teaching Mode<span className="text-danger">*</span>
                  </label>
                  <p>{profile.mode || "-"}</p>
                </div>
              </div>
            )}
            {payload.user_type === "TEACHER" && (
              <div className="col-lg-4 col-sm-6">
                <div className="info-block">
                  <label htmlFor="">
                    Provides Free Demo<span className="text-danger">*</span>
                  </label>
                  <p>{profile.demo || "-"}</p>
                </div>
              </div>
            )}
            {payload.demo === "Yes" && (
              <div className="col-lg-4 col-sm-6">
                <div className="info-block">
                  <label htmlFor="">
                    Timezone<span className="text-danger">*</span>
                  </label>
                  <p>{profile.timezone || "-"}</p>
                </div>
              </div>
            )}
            {payload.demo === "Yes" && (
              <div className="col-lg-4 col-sm-6">
                <div className="info-block">
                  <label htmlFor="">
                    Demo Preference<span className="text-danger">*</span>
                  </label>{" "}
                  <br />
                  <p>{profile.demo_preference || "-"}</p>
                </div>
              </div>
            )}
            {payload.user_type === "TEACHER" && (
              <div className="col-lg-12 col-sm-12">
                <div className="info-block">
                  <label htmlFor="">
                    About Me<span className="text-danger">*</span>
                  </label>
                  <p>{profile.description || "-"}</p>
                </div>
              </div>
            )}
          </div>
        </section>
      );
    }
  };

  const renderExperiences = () => {
    if (payload !== null && mode === "edit") {
      return (
        <section class="profile-data-view">
          <div className="row margin-0">
            <div className="col-12">
              <h5 className="sub-heading">
                Academic Persona
              </h5>
              <p
                className="text-secondary margin-bottom-5 text-right"
                style={{ fontSize: "0.75rem" }}
              >
                Size of each file should not exceed 2MB.
              </p>
            </div>
            <div className="col-12 pad-0">
              {payload.credentials &&
                payload.credentials.education &&
                payload.credentials.education.map((item, index) => (
                  <div className="row margin-0 margin-bottom-0" key={index}>
                    <div className="col-sm-6">
                      <IGInput
                        label="Highest Education/ Accreditation"
                        isRequired={true}
                        placeholder="Highest Education/ Accreditation"
                        value={item.title}
                        error={error.education}
                        setValue={(val) => {
                          let education = JSON.parse(
                            JSON.stringify(payload.credentials.education)
                          );
                          education[index].title = val;
                          setPayload({
                            ...payload,
                            credentials: {
                              ...payload.credentials,
                              education,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <IGInput
                        label="Highest Education/ Accreditation Document"
                        type="file"
                        placeholder="Highest Education/ Accreditation Document"
                        value={item.file}
                        error={error.educationfile}
                        setValue={(val) => {
                          let education = JSON.parse(
                            JSON.stringify(payload.credentials.education)
                          );
                          education[index].file = val;
                          setPayload({
                            ...payload,
                            credentials: {
                              ...payload.credentials,
                              education,
                            },
                          });
                        }}
                      />
                      {/* <br />
                                      <input type="file" className="form-control form-control-sm" /> */}
                    </div>
                  </div>
                ))}
              {payload.credentials &&
                payload.credentials.experience &&
                payload.credentials.experience.map((item, index) => (
                  <div className="row margin-0 margin-bottom-0" key={index}>
                    <div className="col-sm-6">
                      <IGInput
                        label="Work/Teaching Experience"
                        isRequired={true}
                        maxLength={100}
                        placeholder="Work/Teaching Experience"
                        value={item.title}
                        error={error.experience}
                        setValue={(val) => {
                          var experience = JSON.parse(
                            JSON.stringify(payload.credentials.experience)
                          );
                          experience[index].title = val;
                          setPayload({
                            ...payload,
                            credentials: {
                              ...payload.credentials,
                              experience,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <IGInput
                        label="Work/Teaching Experience"
                        type="file"
                        placeholder="Work/Teaching Experience"
                        value={item.file}
                        error={error.experiencefile}
                        setValue={(val) => {
                          var experience = JSON.parse(
                            JSON.stringify(payload.credentials.experience)
                          );
                          experience[index].file = val;
                          setPayload({
                            ...payload,
                            credentials: {
                              ...payload.credentials,
                              experience,
                            },
                          });
                        }}
                      />

                      {/* <br />
                                      <input type="file" className="form-control form-control-sm" /> */}
                    </div>
                  </div>
                ))}
              {payload.credentials &&
                payload.credentials.certification &&
                payload.credentials.certification.map((item, index) => (
                  <div className="row margin-0 margin-bottom-0" key={index}>
                    <div className="col-sm-6">
                      <IGInput
                        label={`Certification${index + 1}`}
                        placeholder="Certification"
                        value={item.title}
                        error={error.title}
                        maxLength={100}
                        setValue={(val) => {
                          let certification = JSON.parse(
                            JSON.stringify(payload.credentials.certification)
                          );
                          certification[index].title = val;
                          setPayload({
                            ...payload,
                            credentials: {
                              ...payload.credentials,
                              certification,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col pad-right-0">
                      {/* <br />
                                      <input type="file" className="form-control form-control-sm" /> */}
                      <IGInput
                        label={`Certification${index + 1}`}
                        type="file"
                        placeholder="Certification"
                        value={item.file}
                        error={error.file}
                        setValue={(val) => {
                          let certification = JSON.parse(
                            JSON.stringify(payload.credentials.certification)
                          );
                          certification[index].file = val;
                          setPayload({
                            ...payload,
                            credentials: {
                              ...payload.credentials,
                              certification,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      );
    } else if (payload !== null && mode === "view") {
      return (
        <section class="profile-data-view">
          <div className="row margin-0">
            <div className="col-12">
              <h5 className="sub-heading">Academic Persona</h5>
            </div>
            <div className="col-12">
              {payload.credentials &&
                payload.credentials.education &&
                payload.credentials.education.map((item, index) => (
                  <div className="row" key={index}>
                    <div className="col-lg-4 col-sm-6 col-6">
                      <div className="info-block">
                        <label htmlFor="">
                          Highest Education/ Accreditation
                          <span className="text-danger">*</span>
                        </label>
                        <p>{item.title || "-"}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-6">
                      <div className="info-block">
                        <label htmlFor="">Highest Education/ Accreditation Document</label>
                        <p>
                          {item.file && item.file.url ? (
                            <a
                              className="txt-theme"
                              href={item.file.url}
                              target="_blank"
                            >
                              <span className="fa fa-file-alt text-danger"></span>{" "}
                              {item.file.name || "File"}
                            </a>
                          ) : (
                            <p>-</p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              {payload.credentials &&
                payload.credentials.experience &&
                payload.credentials.experience.map((item, index) => (
                  <div className="row" key={index}>
                    <div className="col-lg-4 col-sm-6 col-6">
                      <div className="info-block">
                        <label htmlFor="">
                          Work/Teaching Experience
                          <span className="text-danger">*</span>
                        </label>
                        <p>{item.title || "-"}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-6">
                      <div className="info-block">
                        <label htmlFor="">
                          Work/Teaching Experience Document
                        </label>
                        <p>
                          {item.file && item.file.url ? (
                            <a
                              className="txt-theme"
                              href={item.file.url}
                              target="_blank"
                            >
                              <span className="fa fa-file-alt text-danger"></span>{" "}
                              {item.file.name || "File"}
                            </a>
                          ) : (
                            <p>-</p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              {payload.credentials &&
                payload.credentials.certification &&
                payload.credentials.certification.map((item, index) => (
                  <div className="row" key={index}>
                    <div className="col-lg-4 col-sm-6 col-6">
                      <div className="info-block">
                        <label htmlFor="">Certification</label>
                        <p>{item.title || "-"}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-6">
                      <div className="info-block">
                        <label htmlFor="">Certification Document</label>
                        <p>
                          {item.file && item.file.url ? (
                            <a
                              className="txt-theme"
                              href={item.file.url}
                              target="_blank"
                            >
                              <span className="fa fa-file-alt text-danger"></span>{" "}
                              {item.file.name || "File"}
                            </a>
                          ) : (
                            <p>-</p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      );
    }
  };

  const renderSocialProfile = () => {
    if (payload !== null && mode === "edit") {
      return (
        <section class="profile-data-view">
          <div className="row margin-0">
            <div className="col-12">
              <h5 className="sub-heading">Social Presence</h5>
              <span className="text-secondary">
                Restricted to 100 characters and hence recommended to use short
                URLs
              </span>
            </div>
            <div className="col-sm-6">
              <IGInput
                label="Website"
                placeholder="Website URL"
                value={payload.data.website}
                maxLength={100}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    data: {
                      ...payload.data,
                      website: val,
                    },
                  });
                }}
              />
            </div>
            <div className="col-sm-6">
              <IGInput
                label="Youtube"
                placeholder="Youtube video publicly accessible link."
                value={payload.data.video}
                maxLength={100}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    data: {
                      ...payload.data,
                      video: val,
                    },
                  });
                }}
              />
            </div>
            <div className="col-sm-6">
              <IGInput
                label="Facebook"
                placeholder="Facebook URL"
                value={payload.data.facebook}
                maxLength={100}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    data: {
                      ...payload.data,
                      facebook: val,
                    },
                  });
                }}
              />
            </div>
            <div className="col-sm-6">
              <IGInput
                label="LinkedIn"
                placeholder="LinkedIn URL"
                value={payload.data.linkedIn}
                maxLength={100}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    data: {
                      ...payload.data,
                      linkedIn: val,
                    },
                  });
                }}
              />
            </div>
          </div>
        </section>
      );
    } else if (payload !== null && mode === "view") {
      return (
        <section class="profile-data-view">
          <div className="row margin-0">
            <div className="col-12">
              <h5 className="sub-heading">Social Presence</h5>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="info-block">
                <label htmlFor="">Website</label>
                <p>{payload.data.website || "-"}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="info-block">
                <label htmlFor="">Youtube</label>
                <p>{payload.data.video || "-"}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="info-block">
                <label htmlFor="">Facebook</label>
                <p>{payload.data.facebook || "-"}</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="info-block">
                <label htmlFor="">LinkedIn</label>
                <p>{payload.data.linkedIn || "-"}</p>
              </div>
            </div>
          </div>
        </section>
      );
    }
  };

  return (
    <section id="profile-component">
      <div className="row margin-0 margin-bottom-10">
        <div className="col-md-7 col-9">
          <div className="heading-block">
            <h4 className="heading">Profile</h4>
            <p className="des">
              Keep your profile detailed and genuine to get best responses.
            </p>
          </div>
        </div>
        <div className="col-md-5 col-3">
          {(!actions || !actions.PROFILE_UPDATE) && mode === "view" && (
            <button
              className="edit-button"
              type="button"
              onClick={() => {
                setMode("edit");
              }}
            >
              Edit
              <span className="fa fa-pencil f-right c-pointer"></span>
            </button>
          )}
          {actions && actions.PROFILE_UPDATE && (
            <div
              className="gt-status gt-status-orange f-right"
              style={{ marginRight: "-15px" }}
            >
              <div className="max-fit-content pad-right-5 check-block">
                <img src="/check-orange.png" alt="" />
              </div>
              <div className="">
                <p className="margin-0">
                  Congrats! Profile update requested on{" "}
                  {moment(actions.PROFILE_UPDATE[0].created_on).format(
                    "DD MMM, YYYY"
                  ) || "-"}
                </p>
                <span>Changes will be reflected post review.</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {renderProfileBasic()}
      <div className="row margin-0">
        <div className="col-12 pad-0"></div>
        {profile.user_type === "TEACHER" && (
          <div className="col-12 pad-0 pad-top-15">
            <div className="pad-left-20 pad-right-20">
              <hr className="margin-top-0" />
            </div>
            {payload && renderExperiences()}
          </div>
        )}
        {profile.user_type === "TEACHER" && (
          <div className="col-12 pad-0 pad-top-15">
            <div className="pad-left-20 pad-right-20">
              <hr className="margin-top-0" />
            </div>
            {payload && renderSocialProfile()}
          </div>
        )}
        {mode === "edit" && (
          <div className="text-center pad-top-20 pad-bottom-10 col-12">
            {profile.user_type === "STUDENT" ? (
              <button
                className="btn btn-sm btn-yellow"
                onClick={() => {
                  updateProfile();
                }}
              >
                Update
              </button>
            ) : (
              <button
                className="btn btn-sm btn-yellow"
                onClick={() => {
                  updateProfile();
                }}
              >
                {profile.status === "UNDER_VERIFICATION"
                  ? "Update"
                  : "Send For Approval"}
              </button>
            )}
            <button
              className="btn btn-sm btn-plain margin-left-10"
              onClick={() => {
                setMode("view");
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
      {/* popups & labels */}
      <div>
        {action && (
          <ModalAction
            action={{ type: action }}
            close={() => {
              setAction(null);
            }}
          />
        )}
      </div>
    </section>
  );
};

export default Profile;
