import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { profileSelector } from "../../store/userReducer";
import { Nav, Navbar } from "react-bootstrap";
import IGModal from "../../ignitor-react/IGModal";

const StaticFrame = ({ childComponent }) => {
  const profile = useSelector(profileSelector);
  const [appScrollState, setScrollState] = useState("full");
  const [popupMode, setPopupMode] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [appWidth, setappWidth] = useState(document.body.offsetWidth);
  const renderPopupBody = () => {
    return <div>Welcome</div>;
  };

  useEffect(() => {
    if (isLoaded === false) {
      setIsLoaded(true);
      window.addEventListener("scroll", function () {
        if (window.scrollY > 0) {
          setScrollState("half");
        } else {
          setScrollState("full");
        }
      });
    }
  });

  const renderNavBar = () => {
    return (
      <div>
        <Navbar collapseOnSelect expand="lg">
          <Navbar.Brand href="/">
            <img
              alt="Go Teacher Pro"
              src="/logo-white.svg"
              className="header-logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <ul className="navbar-nav ml-auto">
              {/* <li>
              <NavDropdown title="Explore" id="basic-nav-dropdown">
                <NavDropdown.Item href="/courses">Courses</NavDropdown.Item>
                <NavDropdown.Item href="/benfits">Benefits</NavDropdown.Item>
              </NavDropdown>
            </li> */}
              <li className="nav-item">
                <Nav.Link href="/courses">Courses</Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link href="/benfits">Benefits</Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link href="/about">Who are We</Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link href="/contact">Reach Us</Nav.Link>
              </li>
              {/* <li className="nav-item">
              <Nav.Link href="/">Press Releases</Nav.Link>
            </li> */}
              {profile && (
                <li className="nav-item">
                  <Nav.Link href="/dashboard">
                    <button className="btn btn-sm btn-yellow margin-right-20">
                      Go Board
                    </button>
                  </Nav.Link>
                </li>
              )}
              {!profile && (
                <li className="nav-item">
                  <Nav.Link href="/login">
                    <button className="btn btn-sm btn-yellow margin-right-20">
                      Login
                    </button>
                  </Nav.Link>
                </li>
              )}
            </ul>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="footer-group">
        <footer className="w-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-8 mb-4 mb-lg-0">
                <div className="each-footer-block description">
                  <div className="logo">
                    <img
                      alt="Go Teacher Pro"
                      src="logo-white.svg"
                      className="footer-logo img-fluid"
                    />
                  </div>
                  <p>
                    Go Teacher Pro is an aggregating platform, having Global
                    Presence, brings professional teachers and students
                    together.
                  </p>

                  <ul className="app-links">
                    <li className="text-center">
                      <img src="/PlayStore-Android.jpg" alt="" srcset="" style={{maxWidth: '60%', marginBottom: 10}} />
                      <a href="https://play.google.com/store/apps/details?id=com.goteacherpro.goteacherpro" target={'_blank'}>
                        <img
                          src="/play-store.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li className="text-center">
                      <img src="/AppStore-IOS.jpg" alt="" srcset="" style={{maxWidth: '60%', marginBottom: 10}} />
                      <a href="https://apps.apple.com/us/app/itunes-connect/id376771144" target={'_blank'}>
                        <img
                          src="/app-store.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 pad-0">
                <div className="row margin-0">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <div className="each-footer-block">
                      <h5 className="footer-title">Quick Links</h5>
                      <ul className="list-inline footer-menu">
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/courses">Courses</a>
                        </li>
                        <li>
                          <a href="/about">About Us</a>
                        </li>
                        <li>
                          <a href="/contact">Contact</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex justify-content-xl-end">
                    <div className="each-footer-block">
                      <h5 className="footer-title opacity-o">Favorites</h5>
                      <ul className="list-inline footer-menu">
                        <li>
                          <a href="/blog">Blog</a>
                        </li>
                        <li>
                          <a href="/terms">Terms &amp; Conditions</a>
                        </li>
                        <li>
                          <a href="/privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                          <a href="/news">News &amp; Articles</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 pad-top-10">
                    <p style={{fontSize: '13px'}}>Best viewed in browsers: Chrome, Safari, Opera, Firefox</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 d-flex justify-content-xl-end mt-4 mt-lg-0">
                <div className="each-footer-block">
                  <h5 className="footer-title">Reach us</h5>
                  <address>
                    <div className="each">
                      <h5>Address</h5>
                      <p>
                        Chanda Nagar, Hyderabad, <br /> Telangana, India,
                        500050.
                      </p>
                    </div>
                    <div className="each mt-1">
                      <h5>Contact</h5>
                      <a href="mailto:info@goteacherpro.com">
                        info@goteacherpro.com
                      </a>
                      {/* <a href="tel:+918985948415">+91 8985948415</a> */}
                    </div>
                  </address>
                  <ul className="social-links">
                    <li>
                      <a
                        href="https://www.facebook.com/GoTeacherPro"
                        target="_blank"
                      >
                        <span
                          className="fa fa-facebook"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/goteacherpro?ref_src=twsrc%5Etfw"
                        target="_blank"
                      >
                        <span
                          className="fa fa-twitter"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/goteacherpro/"
                        target="_blank"
                      >
                        <span
                          className="fa fa-instagram"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/goteacherpro/about/"
                        target="_blank"
                      >
                        <span
                          className="fa fa-linkedin"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </li>
                    <li>
                      <a href="/" target="_blank">
                        <span
                          className="fa fa-youtube"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <span className="slider-shape"></span>
        </footer>
      </div>
    );
  };

  return (
    <div>
      <div
        className={
          "header-fixed user-header-block " +
          (appScrollState === "full" ? "" : "user-header-bg")
        }
      >
        <div className="inner">
          <div className="container">{renderNavBar()}</div>
        </div>
      </div>
      <div>{childComponent}</div>
      {renderFooter()}
      <IGModal visible={popupMode !== null} body={renderPopupBody} />
    </div>
  );
};

export default StaticFrame;
