import { useEffect, useState } from "react";
import { IGInput } from "../../ignitor-react";
import { Link, useHistory } from "react-router-dom";
import { validateEmail } from "../../constants";
import $cs from "../../services.js";
import Modal from "react-bootstrap/Modal";

const ForgotPassword = (props) => {
  const { mode, handleAction, setPage } = props;
  const [pageMode, setPageMode] = useState("signup");
  const [error, setError] = useState({});

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(true);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setShow(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const validateLogin = () => {
    let proceed = true;
    let errorObj = {};
    if (!email || email === "") {
      errorObj.email = "Email Address is required";
      proceed = false;
    }
    if (email && !validateEmail(email)) {
      errorObj.email = "Enter valid Email Address";
      proceed = false;
    }
    setError(errorObj);
    return proceed;
  };

  const Login = () => {
    if (validateLogin()) {
      $cs.auth.forgotPassword(email).then(
        (res) => {
          setPageMode("verify");
        },
        (err) => {
          if (err && err.response && err.response.status === 400) {
            setError({
              email: err.response.data,
            });
          }
        }
      );
    }
  };

  return (
    <>
      <Modal style={{ marginTop: "140px" }} show={show}>
        {/* // <Card style={{marginTop:"20px", borderRadius:"0.5rem", height:"60%"}}> */}
        <div className="row margin-0">
          {pageMode === "signup" && (
            <div className="col-12">
              <div className="text-center pad-top-15">
                <img
                  src="/logo-ico.png"
                  style={{ maxHeight: "50px" }}
                  alt=""
                  className="img-fluid"
                ></img>
                {/* <img src="/ico-1.png" style={{ maxHeight: '50px' }} alt="" /> */}
                <img
                  src="/ic_close.png"
                  style={{ width: "20px" }}
                  className="float-right"
                  alt=""
                  onClick={() => {
                    setShow(false);
                    history.push("/");
                  }}
                />
                <h4 className="bold txt-theme margin-top-15">
                  Forgot Password
                </h4>
                <div className="">
                  <span>
                    No account yet?
                    {mode === "plain" ? (
                      <span
                        className="txt-theme-link c-pointer"
                        onClick={() => {
                          setPage("signup");
                        }}
                      >
                        {" "}
                        Register
                      </span>
                    ) : (
                      <Link className="txt-theme-link c-pointer" to="/signup">
                        {" "}
                        Register
                      </Link>
                    )}
                  </span>
                </div>
              </div>
              <div className="pad-15">
                <IGInput
                  type="email"
                  label={"Email Address"}
                  placeholder="Email Address"
                  value={email}
                  maxLength={100}
                  error={error.email}
                  setValue={(val) => {
                    setEmail(val?val.toLowerCase():val);
                  }}
                />
                <div className="pad-top-15 text-center google-sign-in">
                  <button
                    onClick={Login}
                    className="btn btn-yellow w-100 btn-size-theme"
                  >
                    Submit
                  </button>
                </div>
                <div className="pad-top-35 text-center">
                  <Link to="/login" className="txt-theme-link">
                    Return to the login page
                  </Link>
                </div>
              </div>
            </div>
            // </Card>
          )}
          {pageMode === "verify" && (
            <div className="col-12 pad-25 text-center">
              <div className="text-center pad-top-15 pad-bottom-10">
                <img src="/ico-1.png" style={{ maxHeight: "50px" }} alt="" />
              </div>
              <h4 className="txt-theme">
                We have sent reset password to your email{" "}
                {email || "dummy@example.com"}
              </h4>
              <Link to="/login" className="txt-theme-link">
                Return to the login page
              </Link>
            </div>
          )}
        </div>
        {/* </Card> */}
      </Modal>
    </>
  );
};

export default ForgotPassword;
