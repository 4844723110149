import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner, Container, Row, Col } from "react-bootstrap";

const Blog = (props) => {
  // const dispatch = useDispatch();
  // const valueSelector = useSelector();
  // const [isLoading, setLoading] = useState(true);

  // useEffect(() => {
  // });

  const renderView = () => {
    // if(isLoading) {
    // }
    return (
      <section id="blog-page">
        <div className="page-banner">
          <div className="inner">
            <h2 className="page-name">Blog</h2>
            <img src="/index-block-top.png" alt="shape" className="img-fluid" />
          </div>
        </div>
        <h6 className="emty">This is Blog</h6>
      </section>
    );
  };

  return <div>{renderView()}</div>;
};

export default Blog;
