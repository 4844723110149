import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { realTimeProfileSelector, actionsObjSelector, setProfile, getProfile, getProfiles, currentProfileSelector, setCurrentProfile, getActions, getRealTimeProfile } from '../store/userReducer';
import $cs from '../services.js'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation,
    useRouteMatch
} from "react-router-dom";

const Settings = (props) => {
    const dispatch = useDispatch();
    const profile = useSelector(realTimeProfileSelector);
    const actions = useSelector(actionsObjSelector);

    const requestAction = (action=null, extra={}) => {
        $cs.profile.update({
            data: {
                action,
                ...extra
            }
        }).then(res => {
            dispatch(getRealTimeProfile());
            dispatch(getActions());
        }, err => {
        });
    };

    const decisionFun = (activity) => {
        switch(activity) {
            case "DEACTIVATE_ACCOUNT":
                if(profile.status === 'SUSPEND') {
                    return <button className="btn btn-sm btn-theme f-right" onClick={()=>{
                        requestAction('ACTIVE');
                    }}>Request to Activate</button>
                }
                if(actions && (actions.SUSPEND_ACCOUNT && actions.SUSPEND_ACCOUNT.filter(item => item.status==='OPEN').length > 0)) {
                    return <small className='f-right text-secondary'> <span className="fa fa-clock-o"></span> Your request is under review.</small>
                }
                return <button className="btn btn-sm btn-theme f-right" onClick={()=>{
                    requestAction('SUSPEND');
                }}>Request to Deactivate</button>
            case "CLOSE_ACCOUNT":
                if(profile.status === 'CLOSED') {
                    return <small className='f-right text-danger'> Your account is closed.</small>
                }
                if(actions && (actions.CLOSE_ACCOUNT && actions.CLOSE_ACCOUNT.filter(item => item.status==='OPEN').length > 0)) {
                    return <small className='f-right text-secondary'> <span className="fa fa-clock-o"></span> Your request is under review.</small>
                }
                return <button className="btn btn-sm btn-theme f-right" onClick={()=>{
                    requestAction('CLOSE');
                }}>Request to Close</button>
                break;
            default:
        }
    }

    return (
        <div className="row margin-0">
            <div className="col-12 pad-0 shadow-theme pad-15">
                <div className="row margin-0">
                    <div className="col-12 pad-0">
                        <h4 className="txt-theme bold margin-bottom-15">Settings</h4>
                    </div>
                    <div className="col-12 pad-0">
                        <div className="plain-border-bottom pad-top-10 pad-bottom-10">
                            {/* <h4 className='bold'>Email Preferences</h4> */}
                            <div>
                                <div className="form-check d-block">
                                    <input className="form-check-input" type="checkbox" onChange={(e)=>{
                                        if(e.target.checked) {
                                            requestAction('EMAIL_SUBSCRIPTION', {value: 'ACTIVE'});
                                        } else {
                                            requestAction('EMAIL_SUBSCRIPTION', {value: 'UNSUBSCRIBED'});
                                        }
                                    }} name="exampleRadios" id="exampleRadios1" value="option1" checked={profile.email_subscription === 'ACTIVE'} />
                                    <label className="form-check-label" for="exampleRadios1">
                                        <b>Email Subscription</b><br />
                                        <span>Receive email notifications by subscribing to email notifications.</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="plain-border-bottom pad-top-10 pad-bottom-10">
                            <h4 className='bold'>Change Password</h4>
                            <p className='margin-0'>Use <Link to="/forgot-password" className='txt-theme'>forgot password</Link> feature to reset or change password.</p>
                        </div>
                        <div className="plain-border-bottom pad-top-10 pad-bottom-10">
                            <h4 className='bold'>Deactivate Account 
                                {decisionFun('DEACTIVATE_ACCOUNT')}
                            </h4>
                            <p className='margin-0'>This action request will change status of account to suspend, unless you request to change back the status to active. You will not receice any alerts, requests, notifications for this status. It may take couple of days to reflect your status.</p>
                            <span><b>Note:</b> In case of inactivity for certain time, any how account goes dormant and no alerts would be posted to your account.</span>
                        </div>
                        <div className="plain-border-bottom pad-top-10 pad-bottom-10">
                            <h4 className='bold'>Close Account 
                                {decisionFun('CLOSE_ACCOUNT')}
                            </h4>
                            <p className='margin-0'>This action request will change status of account to closed and this is <b>not reversable</b>. It may take couple of days to reflect your status.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Settings;
