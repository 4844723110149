import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import IGModal from "../../ignitor-react/IGModal";
import { IGInput } from "../../ignitor-react";
import moment from "moment";
import $cs from "../../services.js";
import {
  realTimeProfileSelector,
  getRealTimeProfile,
} from "../../store/userReducer";

const ModalAction = (props) => {
  const { action, close } = props;
  // const history = useHistory();
  const dispatch = useDispatch();
  const profile = useSelector(realTimeProfileSelector);
  const [isLoaded, setIsLoaded] = useState(false);
  const [popupMode, setPopupMode] = useState(null);
  const [stage, setStage] = useState("mobile");
  const [error, setError] = useState({});
  const [count, setCount] = useState(60);
  const [attempts, setAttempts] = useState(3);
  const [actionData, setActionData] = useState(null);
  const [invoiceMessage, setInvoiceMessage] = useState(null);
  const [payload, setPayload] = useState({
    mobile: profile.mobile,
    otp: null,
  });

  useEffect(() => {
    if (!isLoaded && action) {
      setIsLoaded(true);
      headerAction(action);
    }
  });

  var counter = 60;

  const countIt = () => {
    setTimeout(() => {
      console.log("countIt", count);
      counter -= 1;
      setCount(counter);
      if (counter > 0) {
        countIt();
      }
    }, 1500);
  };

  const requestOTP = () => {
    $cs.otp
      .create({
        data: payload,
      })
      .then(
        (res) => {
          setAttempts(attempts - 1);
          setCount(60);
          counter = 60;
          setStage("otp");
          countIt();
        },
        (err) => {}
      );
  };

  const verifyOTP = () => {
    $cs.otp
      .update({
        data: payload,
      })
      .then(
        (res) => {
          dispatch(getRealTimeProfile());
          setPopupMode(null);
          close();
        },
        (err) => {
          if (err.response && err.response.status === 400) {
            setError(err.response.data);
          }
        }
      );
  };

  const getTransactionData = () => {
    $cs.subscription
      .list({
        resource:
          action.type === "SUBSCRIPTION_INVOICE"
            ? `${$cs.subscription.api}${action.data.id}/`
            : `${$cs.transaction.api}${action.data.id}/`,
      })
      .then(
        (res) => {
          setActionData(res.data);
        },
        (err) => {
          if (err.response && err.response.status === 400) {
            setError(err.response.data);
          }
        }
      );
  };

  const requestInvoice = () => {
    $cs.subscription
      .list({
        resource:
          action.type === "SUBSCRIPTION_INVOICE"
            ? `${$cs.subscription.api}${action.data.id}/invoice`
            : `${$cs.transaction.api}${action.data.id}/invoice`,
      })
      .then(
        (res) => {
          setInvoiceMessage(res.data);
        },
        (err) => {
          if (err.response && err.response.status === 400) {
            setError(err.response.data);
          }
        }
      );
  };

  const headerAction = (action) => {
    switch (action.type || "") {
      case "mobile":
        setPopupMode("mobile");
        break;
      case "SUBSCRIPTION_INVOICE":
        setPopupMode("SUBSCRIPTION_INVOICE");
        getTransactionData();
        break;
      case "TRANSACTION_INVOICE":
        setPopupMode("TRANSACTION_INVOICE");
        getTransactionData();
        break;
      default:
        console.log(`${action.type} to be implemented...`);
    }
  };

  const modalContent = () => {
    if (popupMode === "mobile") {
      return (
        <div className="row margin-0 justify-content-center">
          <div className="col-12">
            <div className="row margin-0">
              <div className="col-12 text-center pad-bottom-15">
                <h4 className="txt-theme">Change &amp; Verify Mobile Number</h4>
              </div>
              {stage === "mobile" && (
                <div className="col-12">
                  <IGInput
                    type="phone"
                    label="Mobile Number"
                    isRequired={true}
                    placeholder="Mobile Number"
                    value={payload.mobile}
                    error={error.mobile}
                    setValue={(val) => {
                      setPayload({
                        ...payload,
                        mobile: val,
                      });
                    }}
                  />
                  <div className="text-center pad-top-10">
                    <button
                      className="btn btn-yellow btn-sm"
                      onClick={() => {
                        requestOTP();
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              )}
              {stage === "otp" && (
                <div className="col-12">
                  <p>
                    Please enter verification code sent to your mobile{" "}
                    <b> {payload.mobile}</b>
                    <button
                      className="btn btn-sm btn-plain text-primary"
                      onClick={() => {
                        setStage("mobile");
                      }}
                    >
                      (change number)
                    </button>
                  </p>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    placeholder="Verification Code"
                    value={payload.otp}
                    onInput={(e) => {
                      setPayload({
                        ...payload,
                        otp: e.target.value,
                      });
                    }}
                  />
                  {error && error.otp && (
                    <span className="text-danger">
                      {error.otp || "Invalid OTP or expired"}
                    </span>
                  )}
                  <div className="text-center pad-20">
                    <button
                      className="btn btn-theme btn-sm margin-right-10"
                      onClick={() => {
                        verifyOTP();
                      }}
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-warning btn-sm"
                      disabled={attempts < 0 || count > 0}
                      onClick={() => {
                        requestOTP();
                      }}
                    >
                      Resend {count > 0 ? `in ${count}Sec` : ""}
                    </button>
                    <span className="d-block pad-top-15">
                      You can resend upto 2 times.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else if (popupMode === "SUBSCRIPTION_INVOICE") {
      if (actionData === null) {
        return (
          <div className="row margin-0">
            <div className="col-12 text-center">Loading...</div>
          </div>
        );
      }
      return (
        <div className="row margin-0">
          <div className="col-12 pad-bottom-10">
            <h4 className="txt-theme">INVOICE</h4>
          </div>
          <div className="col-sm-6">
            <div className="info-snip">
              <label htmlFor="">DATE</label>
              <p>
                {actionData.created_on
                  ? moment(actionData.created_on).format("DD MMM, YYYY")
                  : "-"}
              </p>
            </div>
          </div>
          {/* <div className="col-6">
            <div className="info-snip">
              <label htmlFor="">TRANSACTION</label>
              <p>{actionData.transaction_type==='DEPOSIT'?'Added Coins':'Debited Coins'}</p>
            </div>
          </div>
          <div className="col-12">
            <div className="info-snip">
              <label htmlFor="">MESSAGE</label>
              <p>{actionData.message}</p>
            </div>
          </div>
          <div className="col-6">
            <div className="info-snip">
              <label htmlFor="">COINS</label>
              <p>{actionData.transaction_type==='DEPOSIT'?<span className="text-success">{actionData.coins}</span>:<span className="text-danger">-{actionData.coins}</span>}</p>
            </div>
          </div> */}
          <div className="col-sm-6">
            <div className="info-snip">
              <label htmlFor="">PLAN</label>
              <p>
                {actionData.plan_details.title} x {actionData.packs}
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="info-snip">
              <label htmlFor="">Starts On</label>
              <p>
                {actionData.start_date
                  ? moment(actionData.start_date).format("DD MMM, YYYY")
                  : "-"}
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="info-snip">
              <label htmlFor="">Ends On</label>
              <p>
                {actionData.end_date
                  ? moment(actionData.end_date).format("DD MMM, YYYY")
                  : "-"}
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="info-snip">
              <label htmlFor="">PAYMENT GATEWAY</label>
              <p>{actionData.payment ? actionData.payment.gateway : "-"}</p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="info-snip">
              <label htmlFor="">PAYMENT ID</label>
              <p>
                {actionData.payment && actionData.payment.data
                  ? actionData.payment.data.razorpay_order_id || "-"
                  : "-"}
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="info-snip">
              <label htmlFor="">STATUS</label>
              <p>{actionData.status}</p>
            </div>
          </div>
          <div className="col-12 pad-0 pad-top-10">
            {actionData.payment && (
              <div className="row margin-0">
                <div className="col-12">
                  <div className="info-snip">
                    <label className="margin-bottom-15" htmlFor="">
                      Pricing Summary(
                      {actionData.payment.pricing.currency || "-"})
                    </label>
                    <p>
                      Sub Total:{" "}
                      <b className="f-right">
                        {actionData.payment.pricing.amount || "-"}
                      </b>
                    </p>
                    <p>
                      Wallet:{" "}
                      <b className="f-right">
                        -{actionData.payment.pricing.wallet || "0"}
                      </b>
                    </p>
                    <p>
                      Discount:{" "}
                      <b className="f-right">
                        -{actionData.payment.pricing.discount || "0"}
                      </b>
                    </p>
                    <p>
                      Tax:{" "}
                      <b className="f-right">
                        {actionData.payment.pricing.tax
                          ? actionData.payment.pricing.tax.deductable
                          : 0 || "-"}
                      </b>
                    </p>
                    <p>
                      Total:{" "}
                      <b className="f-right">
                        {actionData.payment.pricing.total || "-"}
                      </b>
                    </p>
                  </div>
                </div>
                {actionData.payment && actionData.payment.status === "SUCCESS" && (
                  <div className="col-12 text-center pad-top-20">
                    {invoiceMessage && (
                      <span className="text-success margin-bottom-5">
                        <span className="fa fa-check"></span> {invoiceMessage}
                        <br />
                      </span>
                    )}
                    <button
                      className="btn btn-yellow btn-sm"
                      disabled={invoiceMessage}
                      onClick={requestInvoice}
                    >
                      <span className="fa fa-envelope-o"></span> Email Invoice
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      );
    } else if (popupMode === "TRANSACTION_INVOICE") {
      if (actionData === null) {
        return (
          <div className="row margin-0">
            <div className="col-12 text-center">Loading...</div>
          </div>
        );
      }
      return (
        <div className="row margin-0">
          <div className="col-12 pad-bottom-10">
            <h4 className="txt-theme">INVOICE</h4>
          </div>
          <div className="col-6">
            <div className="info-snip">
              <label htmlFor="">DATE</label>
              <p>
                {actionData.created_on
                  ? moment(actionData.created_on).format("DD MMM, YYYY")
                  : "-"}
              </p>
            </div>
          </div>
          <div className="col-6">
            <div className="info-snip">
              <label htmlFor="">TRANSACTION</label>
              <p>
                {actionData.transaction_type === "DEPOSIT"
                  ? "Added Coins"
                  : "Debited Coins"}
              </p>
            </div>
          </div>
          <div className="col-12">
            <div className="info-snip">
              <label htmlFor="">MESSAGE</label>
              <p>{actionData.message}</p>
            </div>
          </div>
          <div className="col-6">
            <div className="info-snip">
              <label htmlFor="">COINS</label>
              <p>
                {actionData.transaction_type === "DEPOSIT" ? (
                  <span className="text-success">{actionData.coins}</span>
                ) : (
                  <span className="text-danger">-{actionData.coins}</span>
                )}
              </p>
            </div>
          </div>
          <div className="col-6">
            <div className="info-snip">
              <label htmlFor="">STATUS</label>
              <p>{actionData.status}</p>
            </div>
          </div>
          {actionData.payment && (
            <div className="col-6">
              <div className="info-snip">
                <label htmlFor="">PAYMENT GATEWAY</label>
                <p>{actionData.payment ? actionData.payment.gateway : "-"}</p>
              </div>
            </div>
          )}
          {actionData.payment && (
            <div className="col-6">
              <div className="info-snip">
                <label htmlFor="">PAYMENT ID</label>
                <p>
                  {actionData.payment && actionData.payment.data
                    ? actionData.payment.data.razorpay_order_id || "-"
                    : "-"}
                </p>
              </div>
            </div>
          )}
          <div className="col-12 pad-0 pad-top-10">
            {actionData.payment && (
              <div className="row margin-0">
                <div className="col-12">
                  <div className="info-snip">
                    <label className="margin-bottom-15" htmlFor="">
                      Pricing Summary({actionData.data.currency || "-"})
                    </label>
                    <p>
                      Sub Total:{" "}
                      <b className="f-right">{actionData.data.amount || "-"}</b>
                    </p>
                    <p>
                      Wallet:{" "}
                      <b className="f-right">
                        -{actionData.data.wallet || "0"}
                      </b>
                    </p>
                    <p>
                      Discount:{" "}
                      <b className="f-right">
                        -{actionData.data.discount || "0"}
                      </b>
                    </p>
                    <p>
                      Tax:{" "}
                      <b className="f-right">
                        {actionData.data.tax
                          ? actionData.data.tax.deductable
                          : 0 || "-"}
                      </b>
                    </p>
                    <p>
                      Total:{" "}
                      <b className="f-right">{actionData.data.total || "-"}</b>
                    </p>
                  </div>
                </div>
                {actionData.status === "SUCCESS" && (
                  <div className="col-12 text-center pad-top-20">
                    {invoiceMessage && (
                      <span className="text-success margin-bottom-5">
                        <span className="fa fa-check"></span> {invoiceMessage}
                        <br />
                      </span>
                    )}
                    <button
                      className="btn btn-yellow btn-sm"
                      onClick={requestInvoice}
                    >
                      <span className="fa fa-envelope-o"></span> Email Invoice
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      );
    }
    return <div className="pad-10">Coming Soon</div>;
  };

  return (
    <IGModal visible={popupMode !== null} body={modalContent} close={close} />
  );
};

export default ModalAction;
