import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner, Container, Row, Col } from "react-bootstrap";
import InputRating from "./InputRating";
import $cs from "../../services.js";
import { feedbackLabels, feedbackList } from "../../constants";

const Feedback = (props) => {
  const { enrollment, userType, course, keyPath, user, handleRefresh=null } = props;
  // const dispatch = useDispatch();
  // const valueSelector = useSelector();
  // const [isLoading, setLoading] = useState(true);
  const [enrollmentObj, setEnrollmentObj] = useState(enrollment);
  const [error, setError] = useState({});
  const [feedback, setFeedback] = useState(
    userType === "STUDENT"
      ? {
          rating: 0,
          course: course.id,
          data: {
            Promptness: 0,
            Payment: 0,
            Understanding: 0,
          },
          comments: "",
        }
      : {
          rating: 0,
          course: course.id,
          data: {
            Promptness: 0,
            Knowledge: 0,
            Practicals: 0,
          },
          comments: "",
        }
  );

  const getErrorMessage = (field) => {
    if (error && error[field]) {
      return <span className="error">{error[field]}</span>;
    }
    return "";
  };

  const validateFeedback = () => {
    let proceed = true;
    let errorObj = {};
    if (!feedback.rating || feedback.rating < 1) {
      errorObj.rating = "Please select Rating";
      proceed = false;
    }
    setError(errorObj);
    return proceed;
  };

  const postFeedback = (id) => {
    if (validateFeedback()) {
      $cs.enquiry
        .create({
          resource: `${$cs.enrollment.api}${id}/feedback/`,
          data: feedback,
        })
        .then(
          (res) => {
            setEnrollmentObj(res.data);
            if(handleRefresh !== null) {
              handleRefresh();
            }
          },
          (err) => {}
        );
    }
  };

  const calculateRatings = (feedbackObj) => {
    let ratings = 0;
    const keys = Object.keys(feedbackObj.data);

    keys.forEach((item) => {
      ratings += parseInt(feedbackObj.data[item]);
    });
    setFeedback({
      ...feedbackObj,
      rating: parseFloat((ratings / keys.length).toFixed(1)),
    });
  };

  return (
    <div>
      {userType === "STUDENT" ? (
        <h6>Rate Student {user?'- '+user.first_name:''}</h6>
      ) :
      <h6>Rate Teacher</h6>
      }
      
      {enrollmentObj[keyPath] === null || !enrollmentObj[keyPath] ? (
        <div className="feedback-rating">
          <div className="row margin-0">
            <div className="col-12 pad-0">
              <ul className="feedback-rating-list">
                <li>
                  <span className="name">Attendance &amp; Promptness</span>
                  <span>
                    <InputRating
                      value={feedback.data.Promptness}
                      setValue={(val) => {
                        const feedbackObj = {
                          ...feedback,
                          data: {
                            ...feedback.data,
                            Promptness: val,
                          },
                        };
                        calculateRatings(feedbackObj);
                      }}
                    />
                    {getErrorMessage("Promptness")}
                  </span>
                </li>
                {userType === "STUDENT" && (
                  <li>
                    <span className="name">On Time Payment</span>
                    <span>
                      <InputRating
                        value={feedback.data.Payment}
                        setValue={(val) => {
                          const feedbackObj = {
                            ...feedback,
                            data: {
                              ...feedback.data,
                              Payment: val,
                            },
                          };
                          calculateRatings(feedbackObj);
                        }}
                      />
                      {getErrorMessage("Payment")}
                    </span>
                  </li>
                )}
                {userType === "STUDENT" && (
                  <li>
                    <span className="name">Subject Understanding</span>
                    <span>
                      <InputRating
                        value={feedback.data.Understanding}
                        setValue={(val) => {
                          const feedbackObj = {
                            ...feedback,
                            data: {
                              ...feedback.data,
                              Understanding: val,
                            },
                          };
                          calculateRatings(feedbackObj);
                        }}
                      />
                      {getErrorMessage("Understanding")}
                    </span>
                  </li>
                )}
                {userType === "TEACHER" && (
                  <li>
                    <span className="name">Subject Knowledge</span>
                    <span>
                      <InputRating
                        value={feedback.data.Knowledge}
                        setValue={(val) => {
                          const feedbackObj = {
                            ...feedback,
                            data: {
                              ...feedback.data,
                              Knowledge: val,
                            },
                          };
                          calculateRatings(feedbackObj);
                        }}
                      />
                      {getErrorMessage("Knowledge")}
                    </span>
                  </li>
                )}
                {userType === "TEACHER" && (
                  <li>
                    <span className="name">Practicals &amp; Hands-on</span>
                    <span>
                      <InputRating
                        value={feedback.data.Practicals}
                        setValue={(val) => {
                          const feedbackObj = {
                            ...feedback,
                            data: {
                              ...feedback.data,
                              Practicals: val,
                            },
                          };
                          calculateRatings(feedbackObj);
                        }}
                      />
                      {getErrorMessage("Practicals")}
                    </span>
                  </li>
                )}
                <li className="total-stars">
                  <span className="name">Average Ratings</span>
                  <span className="value">
                    <InputRating
                      mode={"view"}
                      showValue={true}
                      clas={"colored"}
                      value={feedback.rating}
                    />
                  </span>
                </li>
                <li>
                  <button
                    className="submit-button"
                    onClick={() => {
                      postFeedback(enrollmentObj.id);
                    }}
                  >
                    Submit Feedback
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="row margin-0">
          <div className="col-12 pad-0">
            <ul className="feedback-rating-list">
              <li>
                <span>Attendance &amp; Promptness:</span>
                <span>
                  <InputRating
                    mode={"view"}
                    value={enrollmentObj[keyPath].data.Promptness}
                  />
                </span>
              </li>
              {userType === "STUDENT" && (
                <li>
                  <span>On Time Payment:</span>
                  <span>
                    <InputRating
                      mode={"view"}
                      value={enrollmentObj[keyPath].data.Payment}
                    />
                  </span>
                </li>
              )}
              {userType === "STUDENT" && (
                <li>
                  <span>Subject Understanding:</span>
                  <span>
                    <InputRating
                      mode={"view"}
                      value={enrollmentObj[keyPath].data.Understanding}
                    />
                  </span>
                </li>
              )}
              {userType === "TEACHER" && (
                <li>
                  <span>Subject Knowledge:</span>
                  <span>
                    <InputRating
                      mode={"view"}
                      value={enrollmentObj[keyPath].data.Knowledge}
                    />
                  </span>
                </li>
              )}
              {userType === "TEACHER" && (
                <li>
                  <span>Practicals &amp; Hands-on:</span>
                  <span>
                    <InputRating
                      mode={"view"}
                      value={enrollmentObj[keyPath].data.Practicals}
                    />
                  </span>
                </li>
              )}
            </ul>
            <div className="feedback-rating-total">
              <p className="name m-0">Average Ratings: </p>
              <p className="value">
                <InputRating
                  mode={"view"}
                  showValue={true}
                  clas={"colored"}
                  value={enrollmentObj[keyPath].rating}
                />
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Feedback;
