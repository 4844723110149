import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner, Container, Row, Col } from "react-bootstrap";
import Authenticate from "./Authenticate";
import moment from "moment";
import Feedback from "./components/Feedback";
import Modal from "react-bootstrap/Modal";
import $cs from "../services.js";
import { useHistory, useLocation, useParams } from "react-router-dom";
import RatingDetail from "./components/RatingDetail";
import InputRating from "./components/InputRating";
import {
  realTimeProfileSelector,
  setProfile,
  getProfile,
  getProfiles,
  currentProfileSelector,
  setCurrentProfile,
  getRealTimeProfile,
  rulesSelector,
} from "../store/userReducer";
import IGModal from "../ignitor-react/IGModal";

const EnquiryDetail = (props) => {
  const { close } = props;

  const dispatch = useDispatch();
  // const valueSelector = useSelector();
  const profile = useSelector(realTimeProfileSelector);
  const rules = useSelector(rulesSelector);
  const history = useHistory();
  const { enquiryId } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [popupMode, setPopupMode] = useState(null);
  const [tab, setTab] = useState("");
  const [tabOptions, setTabOptions] = useState([]);
  const [course, setCourse] = useState(null);
  const [appWidth, setappWidth] = useState(document.body.offsetWidth);

  const goBack = () => {
    const ref = new URLSearchParams(window.location.search).get("ref");
    if (ref) {
      history.goBack();
    } else {
      if (course.enquiry_type === "REQUEST") {
        history.push("/dashboard?tab=ENQUIRY");
      } else if (course.enquiry_type === "ANNOUNCEMENT") {
        history.push("/dashboard?tab=ANNOUNCEMENTS");
      } else if (course.enquiry_type === "DEMO") {
        history.push("/dashboard?tab=DEMO");
      } else {
        history.push("/dashboard");
      }
    }
  };

  const getEnquiryDetail = () => {
    $cs.enquiry
      .list({
        resource: `${$cs.enquiry.api}${enquiryId}/`,
      })
      .then(
        (res) => {
          setCourse(res.data);
          setLoading(false);
        },
        (err) => { }
      );
  };

  const unlockEnquiry = () => {
    $cs.enquiry
      .list({
        resource: `${$cs.enquiry.api}${enquiryId}/unlock/`,
      })
      .then(
        (res) => {
          setCourse(res.data);
          dispatch(getRealTimeProfile());
        },
        (err) => {
          if (
            err.response.status === 400 &&
            err.response.data === "INSUFFICIENT_COINS"
          ) {
            setPopupMode("INSUFFICIENT_COINS");
          }
        }
      );
  };

  const enrollEnquiry = () => {
    $cs.enquiry
      .list({
        resource: `${$cs.enquiry.api}${enquiryId}/enroll/`,
      })
      .then(
        (res) => {
          setCourse(res.data);
        },
        (err) => { }
      );
  };

  useEffect(() => {
    if (isLoading && course === null) {
      getEnquiryDetail();
    }
    if (course !== null && enquiryId !== course.id) {
      setLoading(true);
      getEnquiryDetail();
    }
  });

  useEffect(() => {
    if (course !== null) {
      const options = [];
      if (
        course.enquiry_type === "REQUEST" &&
        course.questionnaire &&
        Object.keys(course.questionnaire).length > 0
      ) {
        options.push("Questionnaires");
      }
      if (
        course.owner === profile.id &&
        course.enrollment &&
        course.enrollment.length > 0
      ) {
        options.push("Responds");
      }
      if (
        course.enrollment &&
        course.enrollment.length > 0 &&
        course.enrollment.filter((item) => item.status === "CONFIRMED").length >
        0
      ) {
        options.push("Feedback");
      }
      if (options.length > 0) {
        setTab(options[0]);
      }
      setTabOptions(options);
    }
  }, [course]);

  const confirmResponse = (user_id, action = "confirm") => {
    $cs.enquiry
      .list({
        resource: `${$cs.enquiry.api}${enquiryId}/${action}/${user_id}/`,
      })
      .then(
        (res) => {
          setPopupMode(null);
          setCourse(res.data);
        },
        (err) => { }
      );
  };

  const renderEnquiryContent = () => {
    if (
      course.enquiry_type === "ANNOUNCEMENT" ||
      course.enquiry_type === "REQUEST"
    ) {
      return (
        <div className="row margin-0">
          {course.enquiry_type === "ANNOUNCEMENT" && (
            <div className="col-lg-4 col-sm-6 col-6">
              <div className="info-snip">
                <label>Free Demo</label>
                <p>{course.form.free_demo || "-"}</p>
              </div>
            </div>
          )}
          <div className="col-lg-4 col-sm-6 col-6">
            <div className="info-snip">
              <label>Mode</label>
              <p>{course.form.mode || "-"}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-6">
            <div className="info-snip">
              <label>Location</label>
              <p>
                {course.form.location
                  ? course.form.location.name
                  : course.form.location}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-6">
            <div className="info-snip">
              <label>Type</label>
              <p>{course.form.type || "-"}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-6">
            <div className="info-snip">
              <label>Assignment</label>
              <p>{course.form.assignment || "-"}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-6">
            <div className="info-snip">
              <label>Frequency</label>
              <p>{course.form.frequency || "-"}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-6">
            <div className="info-snip">
              <label>Language</label>
              <p>{course.form.language || "-"}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-6">
            <div className="info-snip">
              <label>Fee Type</label>
              <p>{course.form.fee_type || "-"}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-6">
            <div className="info-snip">
              <label>Estimated Fee</label>
              <p>
                {course.owner_details
                  ? course.owner_details.region || "USD"
                  : "USD"}{" "}
                {course.form.fee || "0"}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-6">
            <div className="info-snip">
              <label>Fee Negotiable</label>
              <p>{course.form.fee_negotiable || "-"}</p>
            </div>
          </div>
          {course.enquiry_type === "REQUEST" && (
            <div className="col-lg-4 col-sm-6 col-6">
              <div className="info-snip">
                <label>#Students</label>
                <p>{course.form.student_count || "1"}</p>
              </div>
            </div>
          )}
          {course.enquiry_type === "REQUEST" && (
            <div className="col-lg-4 col-sm-6 col-6">
              <div className="info-snip">
                <label>Is Urgent</label>
                <p>{course.form && course.form.is_urgent ? "Yes" : "No"}</p>
              </div>
            </div>
          )}
          {course.enquiry_type === "ANNOUNCEMENT" && (
            <div className="col-lg-4 col-sm-6 col-6">
              <div className="info-snip">
                <label>Start Date</label>
                <p>
                  {course.form && course.form.start_date
                    ? moment(course.form.start_date).format("DD MMM, YYYY")
                    : "-"}
                </p>
              </div>
            </div>
          )}
          {course.enquiry_type === "ANNOUNCEMENT" && (
            <div className="col-lg-4 col-sm-6 col-6">
              <div className="info-snip">
                <label>Respond By</label>
                <p>
                  {course.form && course.form.respond_by
                    ? moment(course.created_on)
                      .add(parseInt(course.form.respond_by) || 3, "days")
                      .format("DD MMM, YYYY")
                    : "-"}
                </p>
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="row margin-0">
          <div className="col-lg-4 col-sm-6">
            <div className="info-snip">
              <label>Preference</label>
              <p>{course.form.preference || "-"}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="info-snip">
              <label>Requested On</label>
              <p>{moment(course.created_on).format("DD MMM, YYYY") || "-"}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="info-snip">
              <label>Respond By</label>
              <p>{moment(course.archived_on).format("DD MMM, YYYY") || "-"}</p>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderFeedback = (mode = "tab") => {
    const Feedbacks = course.enrollment
      .filter((item) => item.status === "CONFIRMED")
      .map((enrollment, idx) => (
        <div className="col-12 pad-left-0" key={idx}>
          <Feedback
            handleRefresh={() => {
              getEnquiryDetail();
            }}
            enrollment={enrollment}
            course={course.course}
            user={enrollment.first_name ? enrollment : course.owner_details}
            keyPath={
              profile.user_type === "TEACHER"
                ? "student_feedback"
                : "teacher_feedback"
            }
            userType={profile.user_type === "TEACHER" ? "STUDENT" : "TEACHER"}
          />
        </div>
      ));
    if (mode === "side") {
      return (
        <div className="col-12 pad-0 border-top pad-top-10">
          <h5 className="bold">
            <span className="fa fa-comment-o"></span> Feedback &amp; Ratings
          </h5>
          {Feedbacks}
        </div>
      );
    } else {
      return Feedbacks;
    }
  };

  const renderQuestionnaires = () => {
    const questionsList = Object.keys(course.questionnaire).map((item, idx) => (
      <li key={idx}>
        <label>
          {idx + 1}. {course.questionnaire[item].question}
        </label>
        <p>
          <b>a</b>{" "}
          {course.questionnaire[item].value === true
            ? "Yes"
            : course.questionnaire[item].value === false
              ? "No"
              : course.questionnaire[item].value}
        </p>
      </li>
    ));
    return <ul className="enquiry-questions-list">{questionsList}</ul>;
  };

  const renderResponds = () => {
    let respondsList = {
      RESPONDED: [],
      CONFIRMED: [],
      REQUESTED: [],
    };
    try {
      course.enrollment.map((item, idx) => {
        if (!respondsList[item.status]) {
          console.log(item.status);
          respondsList[item.status] = [];
        }
        respondsList[item.status].push({
          ...course.enrollment[idx],
        });
      });
    } catch (error) {
      console.log(error);
    }
    return (
      <ul className="enquiry-responds-list">
        {course.enrollment
          .filter((item) => item.status !== "UNLOCKED")
          .map((item, idx) => (
            <li key={idx}>
              <h5>
                {item.first_name || "-"}
                {item.status === "REQUESTED" && (
                  <span className="badge badge-primary f-right">Requested</span>
                )}
                {item.status === "CONFIRMED" && (
                  <span className="badge badge-success f-right">Accepted</span>
                )}
                {item.status === "REJECTED" && (
                  <span className="badge badge-danger f-right">Rejected</span>
                )}
              </h5>
              {/* <p>Requested On: {moment(course.responds[key].timestamp).format("DD MMM, YYYY") || moment(course.responds[key].respond_time).format("DD MMM, YYYY")}</p> */}
              <p>
                Requested On: {moment(item.requested_on).format("DD MMM, YYYY")}
              </p>
              {item.status === "REQUESTED" &&
                (course.enquiry_type === "ANNOUNCEMENT" ||
                  respondsList.CONFIRMED.length === 0) && (
                  <div>
                    <button
                      className="btn btn-sm btn-theme margin-right-10"
                      onClick={() => {
                        confirmResponse(item.id);
                      }}
                    >
                      Confirm
                    </button>
                    <button
                      className="btn btn-sm btn-plain margin-right-10"
                      onClick={() => {
                        setPopupMode(item.id);
                      }}
                    >
                      Reject
                    </button>
                  </div>
                )}
            </li>
          ))}
      </ul>
    );
  };

  const renderEnrollmentField = () => {
    if (course.enrollment && course.enrollment.length > 0) {
      switch (course.enrollment[0].status) {
        case "UNLOCKED":
          return (
            <div>
              <button
                className="btn btn-sm btn-theme"
                onClick={(e) => {
                  enrollEnquiry();
                }}
              >
                Enroll as My{" "}
                {profile.user_type === "TEACHER" ? "Student" : "Teacher"}
              </button>
            </div>
          );
          break;
        case "REQUESTED":
          return (
            <p>
              <span className="fa fa-clock-o text-sucess"></span> Wating for{" "}
              {profile.user_type === "TEACHER" ? "Student" : "Teacher"}{" "}
              Confirmation
            </p>
          );
          break;
        case "CONFIRMED":
          return (
            <p>
              <span className="fa fa-check text-theme"></span> Enrolled as My{" "}
              {profile.user_type === "TEACHER" ? "Student" : "Teacher"}
            </p>
          );
          break;
        case "REJECTED":
          return (
            <p>
              <span className="fa fa-times text-danger"></span> Enrollment
              Request Rejected
            </p>
          );
          break;
      }
    }
  };

  const getAccessLink = (url) => {
    if (url && url.indexOf('http') === -1) {
      return `https://${url}`;
    } else {
      return url;
    }
  }

  const renderUserDetails = () => {
    if (isLoading) {
      return (
        <div className="pad-10">
          <span>Loading...</span>
        </div>
      );
    } else if (course && course.owner_details) {
      return (
        <div className="col-12 pad-0">
          <div className="row margin-0">
            <div className="col-lg-4 col-sm-6 col-6">
              <div className="info-snip">
                <label htmlFor="">Name</label>
                <p>
                  {course.owner_details.first_name || "********"}{" "}
                  {course.owner_details.last_name
                    ? course.owner_details.last_name
                    : ""}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-6">
              <div className="info-snip">
                <label htmlFor="">
                  {profile.user_type === "TEACHER" ? "Student" : "Teacher"}{" "}
                  Score
                </label>
                <RatingDetail
                  mode={"view"}
                  showValue={true}
                  rating={course.owner_details.rating}
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-6">
              <div className="info-snip">
                <label htmlFor="">Gender</label>
                <p>{course.owner_details.gender || "-"}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-6">
              <div className="info-snip">
                <label htmlFor="">Entity Type</label>
                <p>{course.owner_details.entity_type || "-"}</p>
              </div>
            </div>
            {course.owner_details &&
              course.owner_details.entity_type !== "INDIVIDUAL" && (
                <div className="col-lg-4 col-sm-6 col-6">
                  <div className="info-snip">
                    <label htmlFor="">Institute/Academy Name</label>
                    <p>{course.owner_details.consultant || "-"}</p>
                  </div>
                </div>
              )}
            <div className="col-lg-4 col-sm-6 col-6">
              <div className="info-snip">
                <label htmlFor="">Email</label>
                {course.owner_details.email ? (
                  <p className="text-danger">{course.owner_details.email}</p>
                ) : (
                  <p>
                    <span className="fa fa-lock"></span>
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-6">
              <div className="info-snip">
                <label htmlFor="">Mobile</label>
                {course.owner_details.mobile ? (
                  <p className="text-danger">{course.owner_details.mobile}</p>
                ) : (
                  <p>
                    <span className="fa fa-lock"></span>
                  </p>
                )}
              </div>
            </div>
            {course.enquiry_type === "ANNOUNCEMENT" && (
              <div className="col-lg-4 col-sm-6 col-6">
                <div className="info-snip">
                  <label htmlFor="">Experience</label>
                  <p>
                    {course.owner_details.credentials &&
                      course.owner_details.credentials.education &&
                      course.owner_details.credentials.education.map(
                        (item1, index) => (
                          <span className="margin-bottom-5" key={index + "ac"}>
                            {item1.title}
                          </span>
                        )
                      )}
                    {course.owner_details.credentials &&
                      course.owner_details.credentials.experience &&
                      course.owner_details.credentials.experience.map(
                        (item1, index) => (
                          <span className="margin-bottom-5" key={index + "ab"}>
                            {item1.title}
                          </span>
                        )
                      )}
                    {course.owner_details.credentials &&
                      course.owner_details.credentials.certification &&
                      course.owner_details.credentials.certification.map(
                        (item1, index) => (
                          <span className="margin-bottom-5" key={index + "a"}>
                            {item1.title}
                          </span>
                        )
                      )}
                  </p>
                </div>
              </div>
            )}
            {course.enquiry_type === "ANNOUNCEMENT" && (
              <div className="col-lg-4 col-sm-6 col-6">
                <div className="info-snip">
                  <label htmlFor="">More About Me:</label>
                  <p>
                    <ul className="list-inline social-links-browse">
                      <li>
                          {
                              (course && course.owner_details && course.owner_details.data && course.owner_details.data.website) ? <a href={getAccessLink(course.owner_details.data.website)} target="_blank"><span className="fa fa-globe text-theme"></span></a>:<span className='fa fa-globe text-secondary'></span>
                          }
                      </li>
                      <li>
                          {
                              (course && course.owner_details && course.owner_details.data && course.owner_details.data.facebook) ? <a href={getAccessLink(course.owner_details.data.facebook)} target="_blank"><span className="fa fa-facebook text-primary"></span></a>:<span className="fa fa-facebook text-secondary"></span>
                          }
                      </li>
                      <li>
                          {
                              (course && course.owner_details && course.owner_details.data && course.owner_details.data.linkedIn) ? <a href={getAccessLink(course.owner_details.data.linkedIn)} target="_blank"><span className="fa fa-linkedin txt-theme"></span></a>:<span className="fa fa-linkedin text-secondary"></span>
                          }
                      </li>
                      <li>
                          {
                              (course && course.owner_details && course.owner_details.data && course.owner_details.data.video) ? <a href={getAccessLink(course.owner_details.data.video)} target="_blank"><span className="fa fa-youtube text-danger"></span></a>:<span className="fa fa-youtube text-secondary"></span>
                          }
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            )}
            {course.enquiry_type === "ANNOUNCEMENT" && (
              <div className="col-lg-12 col-sm-12 col-12">
                <div className="info-snip">
                  <label htmlFor="">About</label>
                  <p>{course.owner_details.description || "-"}</p>
                </div>
              </div>
            )}
            <div className="col-12">
              {course && course.owner_details && course.owner_details.email ? (
                <div className="info-snip">
                  <label htmlFor="">Enrollment</label>
                  {renderEnrollmentField()}
                </div>
              ) : course.status === "OPEN" ? (
                <div className="info-snip">{renderUnlockBtn(course)}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderUnlockBtn = (course) => {
    if (profile.status !== "ACTIVE") {
      return <></>;
    }
    let main_coins = 0;
    let discount = 1;
    let coins = 0;
    if (rules && rules.discount) {
      discount = parseInt(rules.discount) + 0;
      discount = (100 - discount) * 0.01;
      console.log(rules);
    }
    if (
      course.enquiry_type === "ANNOUNCEMENT" &&
      course.total_enrollments < course.unlocks
    ) {
      coins = 0;
    } else if (
      course.coin &&
      course.coin.enquiry_coins &&
      course.coin.enquiry_coins > 0
    ) {
      main_coins = course.coin.enquiry_coins;
      coins = parseInt(course.coin.enquiry_coins * discount);
    }
    return (
      <button
        className="btn btn-sm btn-theme"
        disabled={
          course.status !== "OPEN" ||
          (course.enquiry_type === "REQUEST" &&
            course.total_enrollments >= course.unlocks)
        }
        onClick={() => {
          unlockEnquiry();
        }}
      >
        Unlock {course.enquiry_type === "REQUEST" ? "Student" : "Teacher"}{" "}
        {main_coins > 0 ? (
          main_coins === coins ? (
            "Details with " + coins + " coins"
          ) : (
            <span style={{ fontSize: "1em" }}>
              Details with{" "}
              <span style={{ textDecoration: "line-through", fontSize: "1em" }}>
                {main_coins}
              </span>{" "}
              {coins} coins
            </span>
          )
        ) : (
          "Details"
        )}
      </button>
    );
  };

  return (
    <div>
      {course && course.owner !== profile.id && (
        <div className="enquiry-user-details">
          <div className="custom-card">
            <div className="custom-card__header">
              <h3 className="title">
                <span className="fa fa-user"></span>{" "}
                {profile.user_type === "TEACHER" ? "Student" : "Teacher"}{" "}
                Details
              </h3>
              <div
                className="back-navigation"
                onClick={() => {
                  goBack();
                }}
              >
                <span className="fa fa-arrow-left"></span> Back
              </div>
            </div>
            <div className="custom-card__body">{renderUserDetails()}</div>
          </div>
        </div>
      )}

      <div>
        <div className="banner-block1">
          {isLoading ? (
            <div className="pad-top-15">Loading...</div>
          ) : (
            <div className="">
              <div className="custom-card">
                <div className="custom-card__header">
                  <h3 className="title">
                    {course.course.parent_detail
                      ? course.course.parent_detail.topic + " - "
                      : ""}
                    {course.course.title} <small>({course.enquiry_id})</small>
                  </h3>
                  {((profile.user_type === "TEACHER" &&
                    course.enquiry_type === "ANNOUNCEMENT") ||
                    (profile.user_type === "STUDENT" &&
                      (course.enquiry_type === "REQUEST" || course.enquiry_type === "DEMO"))) && (
                      <div
                        className="back-navigation"
                        onClick={() => {
                          goBack();
                        }}
                      >
                        <span className="fa fa-arrow-left"></span> Back
                      </div>
                    )}
                </div>
                <div className="custom-card__body">
                  {renderEnquiryContent()}
                  <div className="row margin-0">
                    {tabOptions.length > 0 && (
                      <div className="col-12">
                        <ul className="default-tabs-horizontal mt-3 mb-3">
                          {tabOptions.indexOf("Questionnaires") !== -1 && (
                            <li
                              className={
                                tab === "Questionnaires" ? "active" : ""
                              }
                            >
                              <a
                                onClick={() => {
                                  setTab("Questionnaires");
                                }}
                              >
                                More Info
                              </a>
                            </li>
                          )}
                          {tabOptions.indexOf("Responds") !== -1 && (
                            <li className={tab === "Responds" ? "active" : ""}>
                              <a
                                onClick={() => {
                                  setTab("Responds");
                                }}
                              >
                                Enrollment Confirmations
                              </a>
                            </li>
                          )}
                          {tabOptions.indexOf("Feedback") !== -1 && (
                            <li className={tab === "Feedback" ? "active" : ""}>
                              <a
                                onClick={() => {
                                  setTab("Feedback");
                                }}
                              >
                                Feedback
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    )}
                    <div className="col-12">
                      {tab === "Questionnaires" && renderQuestionnaires()}
                      {tab === "Feedback" && renderFeedback()}
                      {tab === "Responds" && renderResponds()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="react-modal">
        <Modal
          show={popupMode !== null}
          onHide={() => {
            setPopupMode(null);
          }}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="row margin-0">
              {popupMode !== null && popupMode !== "INSUFFICIENT_COINS" && (
                <div className="col-12 pad-0 text-center">
                  <h4>
                    You want to reject this Enrollment? This action is not
                    reveresible
                  </h4>
                  <div className="text-center pad-10">
                    <button
                      className="btn btn-theme btn-sm margin-right-10"
                      onClick={() => {
                        confirmResponse(popupMode, "reject");
                      }}
                    >
                      Reject
                    </button>
                    <button
                      className="btn btn-plain btn-sm"
                      onClick={() => {
                        setPopupMode(null);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              {popupMode !== null && popupMode === "INSUFFICIENT_COINS" && (
                <div className="col-12 pad-0 text-center">
                  <h4>Insufficient coins balance.</h4>
                  <div className="text-center pad-10">
                    <button
                      className="btn btn-theme btn-sm margin-right-10"
                      onClick={() => {
                        history.push("/my-wallet");
                      }}
                    >
                      Add Coins
                    </button>
                    <button
                      className="btn btn-plain btn-sm"
                      onClick={() => {
                        setPopupMode(null);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default EnquiryDetail;
