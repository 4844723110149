import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import RightMenu from "./RightMenu";
import moment from "moment";
import {
  BrowserRouter as Router,
  Link,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { Nav } from "react-bootstrap";
import {
  realTimeProfileSelector,
  notificationSelector,
  unreadNotificationSelector,
  resetUserState,
  markAllAsRead,
} from "../../store/userReducer";
import IGModal from "../../ignitor-react/IGModal";

const UserHeader = (props) => {
  const { setPageMode, pageMode, appWidth } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(realTimeProfileSelector);
  const alerts = useSelector(notificationSelector);
  const unread = useSelector(unreadNotificationSelector);
  const [popupMode, setPopupMode] = useState(null);

  const logout = () => {
    localStorage.clear();
    dispatch(resetUserState());
    history.push("/");
  };

  const notificationLabels = {
    REQUEST: "Enrollment Confirmation",
    ANNOUNCEMENT: "Enrollment Confirmation",
  };

  const renderNotifications = () => {
    if (alerts === null) {
      return (
        <div className="text-center pad-20">
          <span>Loading...</span>
        </div>
      );
    } else {
      if (alerts && alerts.results.length === 0) {
        return (
          <div className="text-center pad-20">
            <span>No Alerts Till now</span>
          </div>
        );
      } else {
        return (
          <ul className="alerts-list">
            {alerts.results.map((item, index) => {
              return (
                <li key={index}>
                  <div className="alert-img">
                    <img src="/noty.png" alt="" />
                  </div>
                  <div className="alert-content">
                    <label className="d-block" htmlFor="">
                      {notificationLabels[item.alert_type] || item.alert_type}{" "}
                      <span className="txt-grey f-right">
                        {moment(item.created_on).format("DD MMM")}
                      </span>
                    </label>
                    <p>{item.message}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        );
      }
    }
  };

  const renderPopupBody = () => {
    return (
      <div className="row margin-0 pad-bottom-20">
        <div className="col-12 text-center">
          <h4 className="txt-theme">Refer &amp; Get Coins</h4>
          <p>
            Refer student: Get &amp; Give 50 coins / Refer teacher: Get &amp;
            Give 50 coins
          </p>
        </div>
        <div className="col-12 pad-0 text-center">
          <span>Copy link and share:</span>
          <br />
          <span className="copy-link c-pointer">
            <span className="fa fa-copy"></span>
            {`${window.location.origin}/signup?referral=`}
            <span className="bold">{profile.user_id}</span>
          </span>
          <button
            className="btn btn-sm btn-yellow margin-left-10"
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/signup?referral=${profile.user_id}`
              );
            }}
          >
            Copy Link
          </button>
        </div>
      </div>
    );
  };

  return (
    <section id="dashboard-navbar">
      <div className="inner">
        <div className="row align-items-center margin-0">
          <div className="col-6 pad-0">
            <ul className="toggle-navigation">
              <li>
                <span
                  className="toggle-icon c-pointer"
                  style={{ color: "#F9ED35" }}
                  onClick={() => {
                    if (pageMode === "min") {
                      setPageMode("max");
                    } else {
                      setPageMode("min");
                    }
                  }}
                >
                  <div>
                    <i className="fa fa-solid fa-bars"></i>
                  </div>
                </span>
              </li>
              <li>
                <Link to="/" className="logo">
                  <img
                    alt="Go Teacher Pro"
                    src="/logo-white.svg"
                    className="img-fluid"
                  />
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 pad-0">
            {appWidth > 800 ? (
              <ul className="right-navigation">
                <li className="text-white margin-right-10 ">
                  <button
                    className="btn btn-sm btn-yellow"
                    onClick={() => {
                      setPopupMode("REFER");
                    }}
                  >
                    Refer &amp; Get
                  </button>
                </li>
                <li className="">
                  <Link to={"/help/"}>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 150, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          Help
                        </Tooltip>
                      )}
                    >
                      <div className="icon">
                        <span className="fa fa-info-circle"></span>
                      </div>
                    </OverlayTrigger>
                  </Link>
                </li>
                <li>
                  <Link to={"/inbox/"}>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 150, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          Inbox
                        </Tooltip>
                      )}
                    >
                      <div className="icon">
                        <span className="fa fa-envelope"></span>
                      </div>
                    </OverlayTrigger>
                  </Link>
                </li>
                <li className="notification-dropdown profile-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <div className="icon">
                        <img
                          src={unread ? "/ic_active.png" : "/ic.svg"}
                          alt=""
                          className="ico-user-menu"
                        />
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <div className="notification-card">
                          <div className="card-head">
                            <h6>
                              Alerts{" "}
                              {alerts && alerts.results.length > 0 && (
                                <span
                                  className="txt-theme f-right txt-underline cursor"
                                  onClick={() => {
                                    dispatch(markAllAsRead());
                                  }}
                                >
                                  Mark all as read
                                </span>
                              )}
                            </h6>
                            {renderNotifications()}
                          </div>
                        </div>
                        {/* <div className="notifications-block">
                        <div className="notifications-block-content">
                          <div
                            className="noty-body alerts-body"
                            style={{ display: "block" }}
                          >
                            <div className="pad-5">
                              <h6>
                                Alerts{" "}
                                {alerts && alerts.results.length > 0 && (
                                  <span
                                    className="txt-theme f-right txt-underline cursor"
                                    onClick={() => {
                                      dispatch(markAllAsRead());
                                    }}
                                  >
                                    Mark all as read
                                  </span>
                                )}
                              </h6>
                            </div>
                            {renderNotifications()}
                          </div>
                        </div>
                      </div> */}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <div className="dropdown">
                  <a
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={unread ? "/ic_active.png" : "/ic.svg"}
                      alt=""
                      className="ico-user-menu"
                    />
                  </a>
                  <ul
                    className="dropdown-menu animate slideIn"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div className="notifications-block">
                      <div className="notifications-block-content">
                        <div
                          className="noty-body alerts-body"
                          style={{ display: "block" }}
                        >
                          <div className="pad-5">
                            <h6>
                              Alerts{" "}
                              {alerts && alerts.results.length > 0 && (
                                <span
                                  className="txt-theme f-right txt-underline cursor"
                                  onClick={() => {
                                    dispatch(markAllAsRead());
                                  }}
                                >
                                  Mark all as read
                                </span>
                              )}
                            </h6>
                          </div>
                          {renderNotifications()}
                        </div>
                      </div>
                    </div>
                  </ul>
                </div> */}
                </li>
                <li className="profile-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <div className="icon">
                        <span className="fa fa-user"></span>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <Link to={"/my-account/"}>
                          <span className="fa fa-user c-pointer"></span> Profile
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to={"/settings/"}>
                          <span className="fa fa-cog c-pointer"></span> Settings
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <span
                          className="logout-menu"
                          onClick={() => {
                            logout();
                          }}
                        >
                          <span className="fa fa-power-off c-pointer pr-5"></span>{" "}
                          Logout
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <div className="dropdown">
                  <a
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="icon">
                      <span className="fa fa-user"></span>
                    </div>
                  </a>
                  <ul
                    className="dropdown-menu animate slideIn"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link to={"/my-account/"}>
                        <span className="fa fa-user c-pointer"></span> Profile
                      </Link>
                    </li>
                    <li>
                      <Link to={"/settings/"}>
                        <span className="fa fa-cog c-pointer"></span> Settings
                      </Link>
                    </li>
                    <li>
                      <span
                        className="logout-menu"
                        onClick={() => {
                          logout();
                        }}
                      >
                        <span className="fa fa-power-off c-pointer pr-5"></span>{" "}
                        Logout
                      </span>
                    </li>
                  </ul>
                </div> */}
                </li>
              </ul>
            ) : (
              <ul className="right-navigation">
                {appWidth > 700 && (
                  <li className="text-white margin-right-10 mb-hide">
                    <button
                      className="btn btn-sm btn-yellow"
                      onClick={() => {
                        setPopupMode("REFER");
                      }}
                    >
                      Refer &amp; Get
                    </button>
                  </li>
                )}
                <li>
                  <Link to={"/help/"}>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 150, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          Help
                        </Tooltip>
                      )}
                    >
                      <div className="icon">
                        <span className="fa fa-info-circle"></span>
                      </div>
                    </OverlayTrigger>
                  </Link>
                </li>
                <li>
                  <Link to={"/inbox/"}>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 150, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          Inbox
                        </Tooltip>
                      )}
                    >
                      <div className="icon">
                        <span className="fa fa-envelope"></span>
                      </div>
                    </OverlayTrigger>
                  </Link>
                </li>
                <li>
                  <Link to={"/notifications/"}>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 150, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          Notifications
                        </Tooltip>
                      )}
                    >
                      <div className="icon">
                        <span className="fa fa-bell"></span>
                      </div>
                    </OverlayTrigger>
                  </Link>
                </li>
                
                <li className="profile-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <div className="icon">
                        <span className="fa fa-user"></span>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <Link to={"/my-account/"}>
                          <span className="fa fa-user c-pointer"></span> Profile
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to={"/settings/"}>
                          <span className="fa fa-cog c-pointer"></span> Settings
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <span
                          className="logout-menu"
                          onClick={() => {
                            logout();
                          }}
                        >
                          <span className="fa fa-power-off c-pointer pr-5"></span>{" "}
                          Logout
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            )}
          </div>
          <IGModal
            visible={popupMode !== null}
            body={renderPopupBody}
            close={() => setPopupMode(null)}
            isLogin={false}
          />
        </div>
      </div>
    </section>
  );
};

const UserMobileTabs = (props) => {
  let { path, url } = useRouteMatch();
  const profile = useSelector(realTimeProfileSelector);
  return (
    <div className="mobile-navigation-menu">
      <div className="row margin-0">
        <div className="col-12 pad-0">
          <div className="row margin-0">
            <div className="col-12 pad-0">
              <ul className="user-mobile-tabs-menu">
                <li>
                  <Link
                    className={path === "/dashboard" ? "active" : ""}
                    to="/dashboard"
                  >
                    <span>
                      <i className="fa fa-tachometer"></i>
                    </span>{" "}
                    <span> Go Board</span>
                  </Link>
                </li>
                {profile.user_type === "STUDENT" ? (
                  <li>
                    <Link
                      className={path === "/find-teacher" ? "active" : ""}
                      to="/find-teacher"
                    >
                      <span>
                        <i className="fa fa-search"></i>
                      </span>{" "}
                      <span> Find</span>
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link
                      className={path === "/find-students" ? "active" : ""}
                      to="/find-students"
                    >
                      <span>
                        <i className="fa fa-search"></i>
                      </span>{" "}
                      <span> Find</span>
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    className={path === "/enrollments" ? "active" : ""}
                    to="/enrollments"
                  >
                    <span>
                      <i className="fa fa-calendar"></i>
                    </span>{" "}
                    <span>Enrollments</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={path === "/my-wallet" ? "active" : ""}
                    to="/my-wallet"
                  >
                    <span>
                      <i className="fa fa-credit-card-alt"></i>
                    </span>{" "}
                    <span> Wallet</span>
                  </Link>
                </li>
                {
                  profile.user_type === "TEACHER" && <li>
                    <Link
                      className={path === "/membership" ? "active" : ""}
                      to="/membership"
                    >
                      <span>
                        <i className="fa fa-user-plus"></i>
                      </span>{" "}
                      <span> Membership</span>
                    </Link>
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserFrame = ({ childComponent }) => {
  const [appScrollState, setScrollState] = useState("full");
  let { path, url } = useRouteMatch();
  const [tab, setTab] = useState("GoBoard");
  const history = useHistory();
  const profile = useSelector(realTimeProfileSelector);
  const [appWidth, setappWidth] = useState(document.body.offsetWidth);
  const [pageMode, setPageMode] = useState(appWidth > 1000 ? "max" : "min");

  const userFooter = () => {
    return (
      <section id="dashboard-copyright">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-xl-4">
              <div className="copyright">
                <p>
                  <b>Mac Brilliant LLP</b>{" "}
                  {moment().format("YYYY")} -{" "}
                  {moment().add(1, "year").format("YYYY")} All Rights Reserved
                </p>
              </div>
            </div>
            <div className="col-xl-4">
              <ul className="quick-links">
                <li>
                  <a target={"_blank"} href="/about">
                    About
                  </a>
                </li>
                <li>
                  <a target={"_blank"} href="/terms">
                    Terms &amp; Conditions
                  </a>
                </li>
                <li>
                  <a target={"_blank"} href="/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a target={"_blank"} href="/news">
                    News &amp; Articles
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xl-2">
              <ul className="social-links">
                <li>
                  <a
                    href="https://www.facebook.com/GoTeacherPro"
                    target={"_blank"}
                  >
                    <span className="fa fa-facebook"></span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/goteacherpro?ref_src=twsrc%5Etfw"
                    target={"_blank"}
                  >
                    <span className="fa fa-twitter"></span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/goteacherpro/"
                    target={"_blank"}
                  >
                    <span className="fa fa-instagram"></span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/goteacherpro/about/"
                    target={"_blank"}
                  >
                    <span className="fa fa-linkedin"></span>
                  </a>
                </li>
                <li>
                  <a href="https://youtube.com" target={"_blank"}>
                    <span className="fa fa-youtube"></span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xl-2">
              <ul className="footer-app-links">
                <li>
                  <a href="https://play.google.com/store/apps/details?id=com.goteacherpro.goteacherpro" target={'_blank'}>
                    <img
                      src="/play-store-logo.png"
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://apps.apple.com/us/app/itunes-connect/id376771144" target={'_blank'}>
                    <img src="/app-store.png" alt="" className="img-fluid" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 pad-0">
          <UserHeader
            appWidth={appWidth}
            pageMode={pageMode}
            setPageMode={setPageMode}
          />
        </div>
        <div className="col-12 pad-0" style={{ overflowY: "hidden" }}>
          <div
            className={
              pageMode === "max"
                ? "dashboard dashboard-parts"
                : "dashboard dashboard-responsive"
            }
          >
            {pageMode === "max" && (
              <div className="left-block">
                <RightMenu handleHide={() => {
                  setPageMode('min')
                }} />
                <div
                  className="mobile-toggle-cover"
                  onClick={() => {
                    if (pageMode === "min") {
                      setPageMode("max");
                    } else {
                      setPageMode("min");
                    }
                  }}
                ></div>
              </div>
            )}
            <div className="right-block">
              <div className="card-view h-100">
                <div className="col-12 pad-0 h-100">
                  <div
                    className="row margin-0 h-100"
                    style={{ position: "relative" }}
                  >
                    <div className={`right-content-view-scroll  bg-img`}>
                      <div className="row margin-0">
                        {appWidth > 700 && (
                          <div className="col-12 pad-0">
                            <ul className="dashboard-navbar">
                              <li>
                                <h2 className="margin-bottom-0 txt-theme bg-vector">
                                  Welcome{" "}
                                  <span className="">
                                    {profile.first_name},
                                  </span>
                                </h2>
                              </li>
                              <li>
                                <div className="account-status">
                                  <span
                                    className="d-block text-right"
                                    style={{ marginTop: "-20px" }}
                                  >
                                    Account Status:{" "}
                                    <span className="txt-theme bold">
                                      {profile.status}
                                    </span>
                                  </span>
                                  {profile.status === "UNDER_VERIFICATION" && (
                                    <div
                                      className="gt-status gt-status-danger f-right"
                                      style={{ marginRight: "-15px" }}
                                      onClick={() => {
                                        history.push("/my-account");
                                      }}
                                    >
                                      <div className="max-fit-content check-block">
                                        <img src="/check-danger.png" alt="" />
                                      </div>
                                      <div className="">
                                        <p className="margin-0 bold">
                                          Welcome to GoTeacherPro.
                                        </p>{" "}
                                        <p className="margin-0">
                                          Update your profile to post
                                          Announcements or respond to enquiries.
                                        </p>
                                        <Link
                                          to="/my-account"
                                          style={{
                                            textDecoration: "underline",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Edit Profile
                                        </Link>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </li>
                            </ul>
                            <span>
                              <br />
                            </span>
                            <ul className="app-main-menu default-tabs-horizontal top-menu-nav d-none d-lg-flex">
                              <li
                                className={
                                  path === "/dashboard" ? "active" : ""
                                }
                              >
                                <Link to="/dashboard">
                                  <span>
                                    <i className="fa fa-tachometer"></i>
                                  </span>{" "}
                                  <span> Go Board</span>
                                </Link>
                              </li>
                              {profile.user_type === "STUDENT" && (
                                <li
                                  className={
                                    path === "/find-teacher" ? "active" : ""
                                  }
                                >
                                  <Link to="/find-teacher">
                                    <span>
                                      <i className="fa fa-search"></i>
                                    </span>{" "}
                                    <span> Find Teacher</span>
                                  </Link>
                                </li>
                              )}
                              {profile.user_type === "TEACHER" && (
                                <li
                                  className={
                                    path === "/find-students" ? "active" : ""
                                  }
                                >
                                  <Link to="/find-students">
                                    <span>
                                      <i className="fa fa-search"></i>
                                    </span>{" "}
                                    <span> Find Students</span>
                                  </Link>
                                </li>
                              )}
                              <li
                                className={
                                  path === "/enrollments" ? "active" : ""
                                }
                              >
                                <Link to="/enrollments">
                                  <span>
                                    <i className="fa fa-pencil-square-o"></i>
                                  </span>{" "}
                                  <span> Enrollments</span>
                                </Link>
                              </li>
                              <li
                                className={
                                  path === "/my-wallet" ? "active" : ""
                                }
                              >
                                <Link to="/my-wallet">
                                  <span>
                                    <i className="fa fa-money"></i>
                                  </span>{" "}
                                  <span> Wallet</span>
                                </Link>
                              </li>
                              {profile.user_type === "TEACHER" && (
                                <li
                                  className={
                                    path === "/membership" ? "active" : ""
                                  }
                                >
                                  <Link to="/membership">
                                    <span>
                                      <i className="fa fa-user-plus"></i>
                                    </span>{" "}
                                    <span> Membership</span>
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                        <div className="col-12 pad-0">{childComponent}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-tabs">
          <UserMobileTabs />
        </div>
        <div className="col-12 pad-0">{userFooter()}</div>
      </div>
    </div>
  );
};

export default UserFrame;