import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import $cs from "../services.js";
import {
    realTimeProfileSelector,
    setProfile,
    userTypeSelector,
    getEnquires,
    enquiriesSelector,
} from "../store/userReducer";
import EnquiriesList from "./components/EnquiriesList";
import moment from "moment";
import Banner from "../skeltons/components/Banner";

const Dashboard = (props) => {
    const getCurrentTab = () => {
        const tab = new URLSearchParams(window.location.search).get("tab");
        if (tab && tab === "ANNOUNCEMENTS") {
            return "ANNOUNCEMENT";
        } else if (tab && tab === "ENQUIRY") {
            return "REQUEST";
        } else if (tab && tab === "DEMO") {
            return "DEMO";
        } else if (profile && profile.user_type === "TEACHER") {
            return "REQUEST";
        } else {
            return "ANNOUNCEMENT";
        }
    };
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false);
    const profile = useSelector(realTimeProfileSelector);
    const [appWidth, setappWidth] = useState(document.body.offsetWidth);
    const userType = useSelector(userTypeSelector);
    const [tab, setTab] = useState(getCurrentTab());

    useEffect(() => {
        document.body.className = "dashboard-page";
        if (isLoaded === false) {
            dispatch(getEnquires());
            setIsLoaded(true);
        }
    });

    return (
        <div className={`row margin-0`}>
            <div className="col-12 pad-0">
                <Banner target={"DASHBOARD"} />
            </div>
            <div className="go-to-board w-100">
                <div className="row margin-0">
                    <div className="col-12 pad-0">
                        <ul className="default-tabs-horizontal mt-3">
                            {profile.user_type !== "TEACHER" && (
                                <li className={tab === "ANNOUNCEMENT" ? "active" : ""}>
                                    <a
                                        onClick={() => {
                                            setTab("ANNOUNCEMENT");
                                        }}
                                    >
                                        Announcements
                                    </a>
                                </li>
                            )}
                            <li className={tab === "REQUEST" ? "active" : ""}>
                                <a
                                    onClick={() => {
                                        setTab("REQUEST");
                                    }}
                                >
                                    Enquiries
                                </a>
                            </li>
                            {profile.user_type === "TEACHER" && (
                                <li className={tab === "ANNOUNCEMENT" ? "active" : ""}>
                                    <a
                                        onClick={() => {
                                            setTab("ANNOUNCEMENT");
                                        }}
                                    >
                                        Announcements
                                    </a>
                                </li>
                            )}
                            <li className={tab === "DEMO" ? "active" : ""}>
                                <a
                                    onClick={() => {
                                        setTab("DEMO");
                                    }}
                                >
                                    {profile.user_type !== "TEACHER" ? "Demo Requests" : "Demo Requests"}
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            {profile.user_type === "STUDENT" && (
                                <p
                                    className="text-right margin-bottom-5"
                                    style={{ fontSize: "0.8rem" }}
                                >
                                    {" "}
                                    <span
                                        className="fa fa-commenting-o"
                                        style={{ fontSize: "1rem" }}
                                    ></span>{" "}
                                    Explore{" "}
                                    <Link
                                        className="txt-theme"
                                        style={{ fontSize: "0.8rem" }}
                                        to="/find-teacher"
                                    >
                                        Find Teacher
                                    </Link>{" "}
                                    {tab !== "DEMO" && "or"}{" "}
                                    {tab !== "DEMO" && (
                                        <Link
                                            className="txt-theme"
                                            style={{ fontSize: "0.8rem" }}
                                            to="/new-enquiry"
                                        >
                                            Post Enquiry
                                        </Link>
                                    )}
                                </p>
                            )}
                            {profile.user_type === "TEACHER" && (
                                <p
                                    className="text-right margin-bottom-5"
                                    style={{ fontSize: "0.8rem" }}
                                >
                                    {" "}
                                    <span
                                        className="fa fa-commenting-o"
                                        style={{ fontSize: "1rem" }}
                                    ></span>{" "}
                                    Explore{" "}
                                    <Link
                                        className="txt-theme"
                                        style={{ fontSize: "0.8rem" }}
                                        to="/find-students"
                                    >
                                        Find Students
                                    </Link>{" "}
                                    {tab !== "DEMO" && "or"}{" "}
                                    {tab !== "DEMO" && (
                                        <Link
                                            className="txt-theme"
                                            style={{ fontSize: "0.8rem" }}
                                            to="/new-announcement"
                                        >
                                            Post Announcements
                                        </Link>
                                    )}
                                </p>
                            )}
                            <div>
                                {tab === "REQUEST" && (
                                    <EnquiriesList mode="dashboard" enquiryType={"REQUEST"} />
                                )}
                                {tab === "ANNOUNCEMENT" && (
                                    <EnquiriesList mode="dashboard" enquiryType={"ANNOUNCEMENT"} />
                                )}
                                {tab === "DEMO" && (
                                    <EnquiriesList mode="dashboard" enquiryType={"DEMO"} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shape">
                    <img src="/pic.png" alt="" className="img-fluid" />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
