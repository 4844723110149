import { useEffect, useState } from 'react';
import { useRouteMatch } from "react-router-dom";
import LoginComponent from "./components/Login";
import SignupComponent from "./components/Signup";
import ForgotPassword from "./components/ForgotPassword";
import ResetPasswordNew from "./components/ResetPasswordNew";
// import ResetPassword from "./components/ResetPassword";
import Home from './Home';

const Authenticate = (props) => {
    const { path, url, params } = useRouteMatch();
    const getPageType = () => {
        const pageType =
            new URLSearchParams(window.location.search).get("code") || null;
        if (pageType) {
            debugger;
            return pageType;
        }
        return "STUDENT";
    };
    const [currentPage, setCurrentPage] = useState(getPageType());
    const { mode } = props;



    const renderMatchView = () => {
        if (mode === 'plain') {
            switch (currentPage) {
                case "signup":
                    return <SignupComponent {...props} setPage={setCurrentPage} />
                    break;
                case "login":
                    return <LoginComponent {...props} setPage={setCurrentPage} />
                    break;
            }
        } else {
            switch (path) {
                case "/signup":
                    // setCurrent Page('signup');
                    return <SignupComponent {...props} setPage={setCurrentPage} />
                    break;
                case "/login":
                    // setCurrentPage('login');
                    return <LoginComponent {...props} setPage={setCurrentPage} />
                    break;
                case "/forgot-password":
                    // setCurrentPage('login');
                    return <ForgotPassword {...props} setPage={setCurrentPage} />
                    break;
                case "/reset-password/:id":
                    // setCurrentPage('login');
                    return <ResetPasswordNew {...props} id={params.id} setPage={setCurrentPage} />
                    // return <ResetPassword {...props} id={params.id} setPage={setCurrentPage} />
                    break;
            }
        }
    };

    return mode === 'plain' ? <Home /> && renderMatchView() : (
        <>
            <Home />
            <div className="row margin-0 justify-content-center" style={{ padding: '0', minHeight: '70vh' }}>
                <div className="col-xl-4 col-lg-4 d-mobile-none d-tablet-none"></div>
                <div className="col-12 col-lg-4 col-xl-4 pad-0 bg-white" style={{}}>
                    {renderMatchView()}
                </div>
                <div className="col-xl-4 col-lg-4 d-mobile-none d-tablet-none"></div>
            </div>
        </>
    )
};

export default Authenticate;
