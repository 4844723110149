import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner, Container, Row, Col } from "react-bootstrap";

const TermsUse = (props) => {
  // const dispatch = useDispatch();
  // const valueSelector = useSelector();
  // const [isLoading, setLoading] = useState(true);

  return (
    <section id="terms-page">
      <div className="page-banner">
        <div className="inner">
          <h2 className="page-name">Terms &amp; Conditions</h2>
          <img src="/index-block-top.png" alt="shape" className="img-fluid" />
        </div>
        <span className="slider-shape"></span>
      </div>
      <div className="inner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="main-heading">Terms &amp; Conditions</h3>
              <p>
                Go Teacher Pro is an aggregation and networking platform
                connecting student, teachers, consultants and training
                institutes.{" "}
              </p>
              <p>
                Following are the terms and conditions, which implied to be
                agreed by any unregistered or registered user; while browsing
                the portal or app.
              </p>
              <ul>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    Go Teacher Pro has all rights to update the terms and
                    condition time and again with or without noticing to comply
                    with applicable rules and byelaws. End users are expected to
                    visit this section for understanding or letting themselves
                    know about latest updates.
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    Any correctness of content, views, ratings, opinions posted
                    in the website / blog are subject to perception. We will
                    attempt to ensure the accuracy of the Site in the Content
                    and or services but it does not guarantee that the
                    information is accurate or complete or current.{" "}
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    While we take utmost care to restrict use of personal data
                    and we never sell data, Go Teacher Pro or employees or team
                    cannot be held liable for any cyber attack or breach of data
                    attacks.{" "}
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    The maximum liability in case of any fraud or mistake or
                    unintended outcomes would be restricted to refund of coins
                    or credits deducted.
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    Registered users are responsible for safeguarding the user
                    and password information.
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    This platform provides a variety of forums for its members
                    to express themselves in the form of blogs, comments,
                    reviews and photographs. You truthfully assert that the
                    content being contributed is yours and that you own the
                    content and avoid to plagarize.
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    We have rights to edit, delete or block access to any
                    Content including Member Posted Content, without notice and
                    without liability; if found in appropriate.
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    End user avoid making multiple accounts for each profile
                    i.e, teacher or student. We have rights to ban/ delete/
                    consolidate accounts, if found duplicate/spamming.
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    You are responsible for your money / fee negotiations /
                    refund and safety when dealing with others.{" "}
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    End user is not supposed to share their contact details in
                    blog or request or notification posting and this violation
                    may subject to deduction of credits and upto blocking of
                    account either temporarily or permanently.
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    We reserve the right to suspend your account any time
                    without notice. This includes deducting your coins balance.
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    We reserve the right to put your name in our scam/junk list
                    / plagiarized content, if we find any reasonable proof of
                    misusing the platform or posting fake information.{" "}
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>

                  <p>
                    By uploading the logo or photograph of consultancy or
                    institute, we may use it for promotional or marketing
                    purpose and your consent is implied.
                  </p>
                </li>
              </ul>
              <h4 className="sub-heading mt-5">Legal Actions</h4>
              <ul>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    Go Teacher Pro has all rights to take any legal action, if
                    any registered or un registered user make Oral or written
                    false statements in any public domains.
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    If any automated tool or spam attack is identified by any
                    registered or unregistered users.
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    Any reproduction of content / design / concept without prior
                    consent of us.
                  </p>
                </li>
                <li>
                  <span className="fa fa-arrow-right"></span>
                  <p>
                    Students/ Teachers agreed to disclose their contact details knowing the Data Masking regulations on their own.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsUse;
