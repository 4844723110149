import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import InputCourse from "./InputCourse";
import { IGInput } from "../../ignitor-react";
import { validateEmail } from "../../constants";
import $cs from "../../services.js";
import IGModal from "../../ignitor-react/IGModal";
import SocialLogins from "../../ignitor-react/SocialLogins";
import { setProfile } from "../../store/userReducer";

const SignupComponent = (props) => {
  const { mode, handleAction, setPage } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [pageMode, setPageMode] = useState("signup");
  const [stage, setStage] = useState("welcome");

  const getUserType = () => {
    const userType =
      new URLSearchParams(window.location.search).get("user") || null;
    if (userType && ["TEACHER", "STUDENT"].indexOf(userType) !== -1) {
      return userType;
    }
    return "STUDENT";
  };

  const getPresetCourses = () => {
    const topic = new URLSearchParams(window.location.search).get("topic");
    if(topic) {
      return [topic];
    }
    return [];
  }

  const [payload, setPayload] = useState({
    full_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    password: "",
    mobile: "",
    user_type: getUserType(),
    entity_type: "INDIVIDUAL",
    gender: "",
    consultant: "",
    referral_code:
      new URLSearchParams(window.location.search).get("referral") || "",
    emailOTP: "",
    topic: [],
    address: "",
    agree: false,
  });

  const socialResponseHandler = (data) => {
    console.log("socialResponseHandler", data);
    $cs.anyAction
      .create({
        data: {
          ...data,
          action: "SOCIAL_LOGIN",
        },
      })
      .then(
        (res) => {
          localStorage.setItem("Token", res.data.token);
          localStorage.setItem("profile", JSON.stringify(res.data.user));
          dispatch(setProfile(res.data.user));
          history.push("/dashboard");
        },
        (err) => {
          if (err.response && err.response.status === 400) {
            switch (err.response.data.status || err.response.data) {
              case "INVALID_USER":
              case "INVALID_DATA":
                setPayload({
                  ...payload,
                  email: data.email,
                  full_name: data.name,
                });
                if(payload.email && payload.full_name) {
                  setStage("complete");
                }
                break;
              default:
                console.log(err.response.data);
                console.log(data);
            }
          }
        }
      );
  };

  const validatedEmail = () => {
    if (validateSignup(true)) {
      $cs.anyAction
        .create({
          data: {
            email: payload.email.toLowerCase(),
            action: "EMAIL",
          },
        })
        .then(
          (res) => {
            setStage("continue");
          },
          (err) => {
            if (err.response.status === 400) {
              setError(err.response.data);
            }
          }
        );
    }
  };

  const validateSignup = (isFirst = false) => {
    let proceed = true;
    let errorObj = {};
    if (isFirst) {
      if (!payload.email || payload.email === "") {
        errorObj.email = "Email Address is required";
        proceed = false;
      }
      if (payload.email && !validateEmail(payload.email)) {
        errorObj.email = "Enter valid Email Address";
        proceed = false;
      }
      if (!payload.full_name || payload.full_name === "") {
        errorObj.full_name = "Display Name is required";
        proceed = false;
      }
      if (payload.full_name && payload.full_name.length < 3) {
        errorObj.full_name = "Display Name should be min 3 characters";
        proceed = false;
      }
    } else {
      if (!payload.password || payload.password === "") {
        errorObj.password = "Password is required";
        proceed = false;
      }
      if (payload.password && payload.password.length < 4) {
        errorObj.password = "Password should be min 4 characters";
        proceed = false;
      }
      if (!payload.user_type || payload.user_type === "") {
        errorObj.user_type = "Please select user Type";
        proceed = false;
      }
      if (!payload.entity_type || payload.entity_type === "") {
        errorObj.entity_type = "Please select Entity Type";
        proceed = false;
      }
      if (!payload.full_name || payload.full_name === "") {
        errorObj.full_name = "Display Name is required";
        proceed = false;
      }
      if (payload.full_name && payload.full_name.length < 3) {
        errorObj.full_name = "Display Name should be min 3 characters";
        proceed = false;
      }
      if (!payload.mobile || payload.mobile === "") {
        errorObj.mobile = "Phone Number is required";
        proceed = false;
      }
      if (payload.mobile && payload.mobile.indexOf("+") === -1) {
        errorObj.mobile = "Enter valid Phone Number";
        proceed = false;
      }
      if (payload.mobile && payload.mobile.length < 7) {
        errorObj.mobile = "Enter valid Phone Number";
        proceed = false;
      }
      if (payload.mobile && payload.mobile.length > 16) {
        errorObj.mobile = "Enter valid Phone Number";
        proceed = false;
      }
      if (payload.topic.length < 1) {
        errorObj.topic = "Minimum one topic required";
        proceed = false;
      }
      if (!payload.address || payload.address === "") {
        errorObj.location = "Location is required";
        proceed = false;
      }
      if (!payload.agree) {
        errorObj.agree = "Please read and agree terms & conditions";
        proceed = false;
      }
      if (!payload.entity_type && payload.entity_type === "ACADEMY") {
        if (!payload.consultant || payload.consultant === "") {
          errorObj.consultant = "Institute/Academy is required";
          proceed = false;
        }
      }
    }
    setError(errorObj);
    console.log(proceed);
    console.log(payload.address);
    return proceed;
  };

  const Signup = () => {
    if (validateSignup()) {
      const splits = payload.full_name.split(" ");
      const first_name = splits.length > 1 ? splits.slice(0, splits.length - 1).join().replace(/,/g, ' ') : payload.full_name;
      const last_name = splits.length > 1 ? splits[splits.length -1] : " ";

      const finalPayload = {
        ...payload,
        email: payload.email.toLowerCase(),
        first_name,
        last_name,
      };
      $cs.auth.signup(finalPayload).then(
        (res) => {
          setPageMode("verify");
        },
        (err) => {
          if (err.response.status === 400) {
            setError(err.response.data);
          }
        }
      );
    }
  };

  const getErrorMessage = (field) => {
    if (error && error[field]) {
      return <span className="error">{error[field]}</span>;
    }
    return "";
  };

  const renderStage = () => {
    if (stage == "welcome") {
      return (
        <div className="margin-0">
          <div className=" col-12 text-center">
            <h4 className="bold txt-theme margin-top-15">Register</h4>
            <div className="">
              <span>
                Existing User
                {mode === "plain" ? (
                  <span
                    className="txt-theme-link c-pointer"
                    onClick={() => {
                      setPage("login");
                    }}
                  >
                    {" "}
                    Log in
                  </span>
                ) : (
                  <Link to="/login" className="txt-theme-link c-pointer">
                    {" "}
                    Log in
                  </Link>
                )}
              </span>
            </div>
          </div>
          <div className="pad-top-15">
            <IGInput
              label={
                payload.entity_type === "ACADEMY"
                  ? "Display Name/Contact Person"
                  : "Display Name"
              }
              isRequired={true}
              pattern={"^[a-zA-Z0-9]+$"}
              placeholder="Firstname Lastname"
              onKeyEnter={validatedEmail}
              value={payload.full_name}
              error={error.full_name}
              setValue={(val) => {
                setPayload({
                  ...payload,
                  full_name: val,
                });
              }}
            />
            <IGInput
              type="email"
              maxLength={100}
              isRequired={true}
              label="Email Address"
              onKeyEnter={validatedEmail}
              placeholder="Email Address"
              value={payload.email}
              error={error.email}
              setValue={(val) => {
                setPayload({
                  ...payload,
                  email: val?val.toLowerCase():val,
                });
              }}
            />
          </div>
          <div className="pad-top-15 text-center pad-bottom-20">
            <button
              onClick={() => {
                validatedEmail();
              }}
              className="btn btn-yellow w-100 btn-size-theme"
              style={{ borderRadius: "0.5rem" }}
            >
              Continue
            </button>
          </div>
          {/* <div className="pad-15 text-center">
            <div className="row margin-0">
              <div className="col pad-0">
                <hr style={{ borderColor: "#229A87" }} />
              </div>
              <div
                className="col-12 max-fit-content txt-theme"
                style={{ paddingTop: "0.25rem" }}
              >
                Or Continue with
              </div>
              <div className="col pad-0">
                <hr style={{ borderColor: "#229A87" }} />
              </div>
            </div>
          </div>
          <div className="pad-15">
            <div className="row margin-0 justify-content-center social-login-list-modal">
              <div className="col max-fit-content">
                <SocialLogins
                  app="google"
                  label="Sign in"
                  responseHandler={socialResponseHandler}
                />
              </div>
              <div className="col max-fit-content">
                <SocialLogins
                  app="facebook"
                  label="Sign in"
                  responseHandler={socialResponseHandler}
                />
              </div>
              <div className="col max-fit-content">
                <SocialLogins
                  app="linkedin"
                  label="Sign in"
                  responseHandler={socialResponseHandler}
                />
              </div>
            </div>
          </div> */}
        </div>
      );
    } else {
      return (
        <div className="row margin-0">
          <div className="col-12 pad-top-15">
            <h5 className="txt-theme">
              Complete Registration
              <span
                className="text-danger f-right c-pointer"
                onClick={() => {
                  setStage("welcome");
                }}
              >
                <span className="fa fa-arrow-left"></span> Back
              </span>
            </h5>
          </div>
          <div className="col-12 pad-top-0">
            <div className="form-group">
              <label className="ig-input-label">User Type</label>
              <div className="" style={{ display: "flex" }}>
                <div
                  className="form-check c-pointer pad-right-15"
                  style={{ display: "block" }}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios1"
                    id="exampleRadios12"
                    value="STUDENT"
                    checked={payload.user_type === "STUDENT"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setPayload({
                          ...payload,
                          user_type: "STUDENT",
                          entity_type: "INDIVIDUAL",
                        });
                      }
                    }}
                  />
                  <label
                    className="form-check-label pad-left-0"
                    htmlFor="exampleRadios12"
                  >
                    Student
                  </label>
                </div>
                <div
                  className="form-check c-pointer"
                  style={{ display: "block" }}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios1"
                    id="exampleRadios23"
                    value="TEACHER"
                    checked={payload.user_type === "TEACHER"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setPayload({
                          ...payload,
                          user_type: "TEACHER",
                          entity_type: "INDIVIDUAL",
                        });
                      }
                    }}
                  />
                  <label
                    className="form-check-label pad-left-0"
                    htmlFor="exampleRadios23"
                  >
                    Teacher
                  </label>
                </div>
              </div>
              {getErrorMessage("user_type")}
            </div>
            <div className="form-group">
              <label className="ig-input-label">Entity Type</label>
              <div className="" style={{ display: "flex" }}>
                <div
                  className="form-check c-pointer pad-right-15"
                  style={{ display: "block" }}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="INDIVIDUAL"
                    checked={payload.entity_type === "INDIVIDUAL"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setPayload({
                          ...payload,
                          entity_type: "INDIVIDUAL",
                        });
                      }
                    }}
                  />
                  <label
                    className="form-check-label pad-left-0"
                    htmlFor="exampleRadios1"
                  >
                    Individual
                  </label>
                </div>
                <div
                  className="form-check c-pointer"
                  style={{ display: "block" }}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios2"
                    value="ACADEMY"
                    checked={payload.entity_type === "ACADEMY"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setPayload({
                          ...payload,
                          entity_type: "ACADEMY",
                        });
                      }
                    }}
                  />
                  <label
                    className="form-check-label pad-left-0"
                    htmlFor="exampleRadios2"
                  >
                    {payload.user_type === "TEACHER"
                      ? "Institute/Academy"
                      : "Organization"}
                  </label>
                </div>
              </div>
              {getErrorMessage("entity_type")}
            </div>
            {payload.entity_type === "ACADEMY" && (
              <IGInput
                label={
                  payload.user_type === "TEACHER"
                    ? "Institute/Academy"
                    : "Organization"
                }
                placeholder={
                  payload.user_type === "TEACHER"
                    ? "Institute/Academy"
                    : "Organization"
                }
                value={payload.consultant}
                pattern={"^[a-zA-Z0-9]+$"}
                onKeyEnter={Signup}
                isRequired={true}
                error={error.consultant}
                setValue={(val) => {
                  setPayload({
                    ...payload,
                    consultant: val,
                  });
                }}
              />
            )}
            <IGInput
              label={
                payload.entity_type === "ACADEMY"
                  ? "Display Name/Contact Person"
                  : "Display Name"
              }
              isRequired={true}
              pattern={"^[a-zA-Z0-9]+$"}
              placeholder="Firstname Lastname"
              value={payload.full_name}
              error={error.full_name}
              setValue={(val) => {
                setPayload({
                  ...payload,
                  full_name: val,
                });
              }}
            />
            <IGInput
              type="password"
              label="Password"
              isRequired={true}
              onKeyEnter={Signup}
              placeholder="Password"
              value={payload.password}
              error={error.password}
              setValue={(val) => {
                setPayload({
                  ...payload,
                  password: val,
                });
              }}
            />
            <IGInput
              type="phone"
              label="Phone Number"
              isRequired={true}
              placeholder="Phone Number"
              value={payload.mobile}
              error={error.mobile}
              setValue={(val) => {
                setPayload({
                  ...payload,
                  mobile: val,
                });
              }}
            />
            <IGInput
              type="text"
              label="Referral Code"
              helpBox={() => {
                return (
                  <div>
                    <span>Input referral code to get bonus coins.</span>
                  </div>
                );
              }}
              onKeyEnter={Signup}
              placeholder="Referral Code"
              value={payload.referral_code}
              error={error.referral_code}
              setValue={(val) => {
                setPayload({
                  ...payload,
                  referral_code: val,
                });
              }}
            />
            <IGInput
              type="location"
              label="Location"
              isRequired={true}
              placeholder="Location"
              value={payload.address}
              error={error.location}
              setValue={(val) => {
                console.log(val);
                setPayload({
                  ...payload,
                  address: val,
                });
              }}
            />
            <InputCourse
              style={{ display: "flex" }}
              label={
                payload.user_type === "STUDENT"
                  ? "Choose Topics"
                  : "Choose Skills"
              }
              isRequired={true}
              value={payload.topic}
              maxSelections={3}
              preSelections={getPresetCourses()}
              mode={"multiselect"}
              error={error.topic}
              setValue={(c) => {
                setPayload({
                  ...payload,
                  topic: c,
                });
              }}
            />
            <div className="d-block pad-top-15">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="acceptCheck"
                  name="acceptCheck"
                  onChange={(event) => {
                    setPayload({
                      ...payload,
                      agree: event.target.checked,
                    });
                  }}
                />
                <label className="custom-control-label" htmlFor="acceptCheck">
                  I accept{" "}
                  <Link className="btn-underline" target="_blank" to="/terms">
                    Terms &amp; Conditions
                  </Link>
                </label>
              </div>
              <div className="">{getErrorMessage("agree")}</div>
            </div>
            <div className="pad-top-15 pad-bottom-30 text-center">
              <button
                onClick={Signup}
                className="btn btn-yellow w-100 btn-size-theme margin-bottom-20"
              >
                Register
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderBody = () => {
    return (
      <div className="row margin-0 justify-content-center">
        <div className="col-12">
          <div className="row margin-0">
            <div className="col text-center">
              <img src="/logo-ico.png" style={{ maxHeight: "50px" }} alt="" />
            </div>
            <div className="col-12 pad-0">
              {pageMode === "signup" && renderStage()}
              {pageMode === "verify" && (
                <div className="col-12 pad-25 text-center">
                  <h4 className="margin-bottom-15">
                    We have sent you a message to your email{" "}
                    <span className="txt-theme">
                      {payload.email || "dummy@example.com"}
                    </span>{" "}
                    to complete registration.
                  </h4>
                  <Link to="/login" className="txt-theme-link">
                    Return to the login page
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <IGModal
      visible={true}
      body={renderBody}
      close={() => {
        history.push("/");
      }}
    />
  );
};

export default SignupComponent;
