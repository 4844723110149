import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import $cs from '../services.js'

export const getBanners = createAsyncThunk(
  'user/getBanners',
  async (thunkAPI) => {
      const response = await $cs.banner.list({
        params: {}
      }).then(res => res.data);
      return response
  }
)

export const getDiscounts = createAsyncThunk(
  'user/getDiscounts',
  async (thunkAPI) => {
      const response = await $cs.discount.list({
        params: {}
      }).then(res => res.data);
      return response
  }
)

export const getCoupons = createAsyncThunk(
  'user/getCoupons',
  async (thunkAPI) => {
      const response = await $cs.coupon.list({
        params: {}
      }).then(res => res.data);
      return response
  }
)

export const getLookups = createAsyncThunk(
  'user/getLookups',
  async (thunkAPI) => {
      const response = await $cs.lookup.list({}).then(res => res.data);
      return response
  }
)

export const getSettings = createAsyncThunk(
  'user/getSettings',
  async (thunkAPI) => {
      const response = await $cs.setting.list({}).then(res => res.data);
      return response
  }
)

export const appSlice = createSlice({
    name: 'app',
    initialState: {
      loaded: true,
      plans: null,
      lookups: null,
      settings: {
        version: 1
      },
      taxes: null,
      banners: null,
      coupons: null,
      discounts: null,
      version: 1
    },
    reducers: {
        setVersion: (state, action) => {
            state.version = action.payload;
        }
    },
    extraReducers: (builder) => {
      builder
      .addCase(getBanners.fulfilled, (state, action) => {
          let bannersObj = {};
          action.payload.results.forEach(item => {
            if(bannersObj[item.target]) {
              bannersObj[item.target].push(item);
            } else {
              bannersObj[item.target] = [];
              bannersObj[item.target].push(item);
            }
          });
          state.banners = bannersObj;
      })
      .addCase(getDiscounts.fulfilled, (state, action) => {
        state.discounts = action.payload.results || [];
      })
      .addCase(getCoupons.fulfilled, (state, action) => {
        state.coupons = action.payload.results || [];
      })
      .addCase(getLookups.fulfilled, (state, action) => {
        let lookupObj = {};
        action.payload.results.forEach(item => {
          lookupObj[item.id] = item;
        });
        state.lookups = lookupObj;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        let settingObj = {};
        action.payload.results.forEach(item => {
          settingObj[item.id] = item;
        });
        state.settings = settingObj;
      })
    }
});

export const { setVersion } = appSlice.actions;

export const appVersionSelector = state => state.app.version;
export const appSettingsSelector = state => state.app.settings;
export const lookupsSelector = state => state.app.lookups;
export const bannersSelector = state => state.app.banners;
export const discountsSelector = state => state.app.discounts;
export const couponsSelector = state => state.app.coupons;
export const taxSelector = state => state.app.taxes;

export default appSlice.reducer;
