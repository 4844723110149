import { useEffect } from "react";
import { useState } from "react";
import Autocomplete from "react-google-autocomplete";

import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const InputLocationFrame = (props) => {
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GEOCODE_KEY,
  });
  const { value, setValue, placeholder, error } = props;
  const [locationLabel, setLoactionLabel] = useState("");

  useEffect(()=>{
    if(value) {
      if(typeof value === "object") {
        setLoactionLabel(value.name || '');
      } else if(typeof value === "string") {
        setLoactionLabel(value);
      }
    }
  }, [value]);

  const getFormatName = (address) => {
    if(address.address) {
      return `${address.address.locality?address.address.locality+', ':''}${address.address.administrative_area_level_1?address.address.administrative_area_level_1+', ':''}${address.address.country?address.address.country:''}`
    }
    return address.name;
  }

  const processAddress = (address) => {
    let locationObj = {
      name: address.formatted_address,
      address: {},
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng()
    };
    address.address_components.forEach(item => {
      item.types.forEach(tag => {
        if(tag !== 'political') {
          locationObj.address[tag] = item.long_name;
        }
      });
    });
    locationObj.name = getFormatName(locationObj);
    setValue(locationObj);
  };

  return (
    <Autocomplete
      apiKey={process.env.REACT_APP_GEOCODE_KEY}
      style={{ width: "100%" }}
      onPlaceSelected={(place) => {
        processAddress(place);
      }}
      options={{
        types: ["(regions)"],
        // componentRestrictions: {  },
      }}
      defaultValue={locationLabel}
      className={`form-control ig-form-input ${error?' ig-border-error':''}`}
    />
  )
};

const InputLocation = (props) => {
  const { value, setValue, placeholder, error } = props;

  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(()=>{
    setValue(selectedValue);
  }, [selectedValue]);

  return <InputLocationFrame {...props} setValue={setSelectedValue} />
};

export default InputLocation;
