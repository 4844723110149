import { useEffect, useState } from "react";

const Benfits = (props) => {
  return (
    <section id="befits-page">
      <div className="page-banner">
        <div className="inner">
          <h2 className="page-name">Benefits</h2>
          <img src="/index-block-top.png" alt="shape" className="img-fluid" />
        </div>
        <span className="slider-shape"></span>
      </div>
      <div className="page-start">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>
                Go Teacher Pro is a Web app and also available in an iOS and
                Android mobile app for finding best qualified teacher nearby
                students location; provide education to any remote areas.
              </p>
              <p>
                It's also beneficial for teachers. Now with the help of Go
                Teacher Pro platform they can reach more and more students, and
                earn more via our platform
              </p>
            </div>
          </div>
        </div>

        <div className="each-topic mt-3 student">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="content-block">
                  <h3 className="primary-title mb-4 green-c">
                    Genuine Problems
                  </h3>
                  <h4 className="secondry-heading blue-c mb-2">Students</h4>
                  <p>
                    Education is the base of any person and their personality.
                    Education helps build a child's future. It helps him to
                    become a Leader, Good person, Sports man, Business man and
                    also it develops a perspective of looking at life. It helps
                    us build opinions and have viewpoints on various things in
                    life. People often debate over the subject that is "Is
                    education the only means of imparting knowledge".
                  </p>
                  <p>
                    So after some research and interviews we have identified
                    major problems in today's life that hinders students access
                    to better education, which are
                  </p>
                </div>
              </div>
              <div className="col-lg-5 d-flex justify-content-end">
                <div className="image-block">
                  <img
                    src="/benfits/benfits_1.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <ul className="list">
                  <li>
                    <span></span>
                    <p>Finding a good tutor</p>
                  </li>
                  <li>
                    <span></span>

                    <p>Expensive Education</p>
                  </li>
                  <li>
                    <span></span>

                    <p>Poor Quality Education</p>
                  </li>
                  <li>
                    <span></span>

                    <p>Lack of Interactive Learning</p>
                  </li>
                  <li>
                    <span></span>

                    <p>Tracking student progress and so on..</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="each-topic mt-5 teacher bg-grey">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="image-block">
                  <img
                    src="/benfits/teacher.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="content-block">
                  <h4 className="secondry-heading blue-c mb-2">Teachers</h4>
                  <p>
                    In similar way we have identified some major problems for
                    teachers, as listed below
                  </p>
                  <ul className="list">
                    <li>
                      <span></span>
                      <p>Less income due to less students</p>
                    </li>
                    <li>
                      <span></span>
                      <p>Travel time is high for home classes</p>
                    </li>
                    <li>
                      <span></span>
                      <p>Sometimes unable to track student's progress</p>
                    </li>
                    <li>
                      <span></span>
                      <p>
                        Monthly expenses to run the class like Space rent,
                        Chair, Table expense, Electricity Bill
                      </p>
                    </li>
                    <li>
                      <span></span>

                      <p>Not able to reach more students offline</p>
                    </li>
                    <li>
                      <span></span>
                      <p>Difficult to maintain earning record</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="each-topic mt-5 student-suggetion">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content-block">
                  <h3 className="primary-title mb-4 green-c">
                    Suggestive Solutions
                  </h3>
                  <h4 className="secondry-heading blue-c mb-2">For Students</h4>
                  <p>
                    After doing qualitative research we have some suggestive
                    solutions for students through our platform
                  </p>
                  <ul className="list">
                    <li>
                      <span></span>
                      <p>
                        Easily find best teacher / institute via our platform,
                        with students review and feedback about the teachers
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <p>Classes will be available for 1-to-1 or 1-to-many.</p>
                    </li>
                    <li>
                      <span></span>
                      <p>
                        Online Interactive classes will save lots of travel time
                        of the students
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <p>Pocket friendly education. Not much expensive</p>
                    </li>
                    <li>
                      <span></span>
                      <p>
                        Maintain quality of education via students/parents
                        feedback
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <p>
                        Students can compete with each other in offline classes
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <p>
                        Can opt for Demo and decide with which teacher you want
                        to continue the classes
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-end">
                <div className="image-block">
                  <img
                    src="/benfits/student-s.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flow">
          <div className="container">
            <div className="row">
              <div className="co-12">
                <h4 className="secondry-heading blue-c mb-2">Student Flow</h4>
                <img
                  src="/benfits/student-flow.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="each-topic mt-5 teacher-flow bg-grey">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="image-block">
                  <img
                    src="/benfits/teacher-s.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="content-block">
                  <h4 className="secondry-heading blue-c mb-2">For Teachers</h4>
                  <ul className="list">
                    <li>
                      <span></span>
                      <p>Reaching more students will create more income</p>
                    </li>
                    <li>
                      <span></span>
                      <p>Saves lot of time</p>
                    </li>
                    <li>
                      <span></span>
                      <p>
                        Easily track all student's enquiries via app / web and
                        reach to students
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <p>
                        Able to reach more students in same time with our
                        Announcements functionality.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <p>
                        Teachers can record their videos and can upload to the
                        system. It can save their time more and can give more
                        attention to students
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flow bg-grey pb-5 pt-0">
          <div className="container">
            <div className="row">
              <div className="co-12">
                <h4 className="secondry-heading blue-c mb-2">Teacher Flow</h4>
                <img
                  src="/benfits/teacher-flow.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benfits;
