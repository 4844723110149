import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "../pages/Home.jsx";
import About from "../pages/static/About.jsx";
import Benfits from "../pages/static/Benfits.jsx";
import News from "../pages/static/News.jsx";
import Courses from "../pages/static/Courses.jsx";
import Blog from "../pages/static/Blog.jsx";
import Help from "../pages/static/Help.jsx";
import Contact from "../pages/static/Contact.jsx";
import PrivacyPolicy from "../pages/static/PrivacyPolicy.jsx";
import TermsUse from "../pages/static/TermsUse.jsx";
import Billing from "../pages/Billing.jsx";
import Profile from "../pages/Profile.jsx";
import Notifications from "../pages/Notifications.jsx";
import Dashboard from "../pages/Dashboard.jsx";
import Course from "../pages/Course.jsx";
import Membership from "../pages/Membership.jsx";
import UpcomingAnnouncements from "../pages/UpcomingAnnouncements.jsx";
import Enquiries from "../pages/Enquiries.jsx";
import Announcements from "../pages/Announcements.jsx";
import MyWallet from "../pages/MyWallet.jsx";
import Settings from "../pages/Settings.jsx";
import Authenticate from "../pages/Authenticate.jsx";
import NewEnquiry from "../pages/NewEnquiry.jsx";
import EnquiryDetail from "../pages/EnquiryDetail.jsx";
import BrowseTeachers from "../pages/BrowseTeachers.jsx";
import FindStudents from "../pages/FindStudents.jsx";
import Enrollments from "../pages/Enrollments.jsx";
import Skills from "../pages/Skills.jsx";
import Verify from "../pages/Verify.jsx";
import Inbox from "../pages/Inbox.jsx";
// import Home from "../pages/Home.jsx";
import "./Base.css";
import {
  getBanners,
  getDiscounts,
  getCoupons,
  getLookups,
  getSettings,
} from "../store/appReducer";
import {
  profileSelector,
  getActions,
  setProfile,
  getProfile,
  resetUserState,
  getEnrollments,
  getProfiles,
  getNotifications,
  getStats,
  currentProfileSelector,
  setCurrentProfile,
  getRealTimeProfile,
} from "../store/userReducer";

// split components
import StaticFrame from "./components/StaticFrame";
import UserFrame from "./components/UserFrame";

// Authenticated Blocks
const PrivateRoute = ({ children, ...rest }) => {
  const profile = useSelector(profileSelector);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        profile !== null ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const Base = (props) => {
  const appWidth = document.body.offsetWidth;
  // const valueSelector = useSelector();
  const profile = useSelector(profileSelector);
  const [isLoaded, setLoaded] = useState(false);
  const [appView, setAppView] = useState(
    appWidth > 1000 ? "desktop" : "mobile"
  );

  // const history = useHistory();
  // let { path, url } = useRouteMatch();
  // const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getBanners());
      dispatch(getDiscounts());
      dispatch(getLookups());
      dispatch(getSettings());
    }
  });

  useEffect(() => {
    if (profile) {
      dispatch(getActions());
      dispatch(getRealTimeProfile());
      dispatch(getCoupons());
      dispatch(getProfiles());
      dispatch(getNotifications());
      dispatch(getStats());
      dispatch(getEnrollments(null));
    }
  }, [profile]);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <StaticFrame childComponent={<Home />} />
        </Route>
        <Route path="/login">
          <StaticFrame childComponent={<Authenticate />} />
        </Route>
        <Route path="/signup">
          <StaticFrame childComponent={<Authenticate />} />
        </Route>
        <Route path="/forgot-password">
          <StaticFrame childComponent={<Authenticate />} />
        </Route>
        <Route path="/reset-password/:id">
          <StaticFrame childComponent={<Authenticate />} />
        </Route>
        <Route path="/about">
          <StaticFrame childComponent={<About />} />
        </Route>
        <Route path="/benfits">
          <StaticFrame childComponent={<Benfits />} />
        </Route>
        <Route path="/news">
          <StaticFrame childComponent={<News />} />
        </Route>
        <Route path="/courses">
          <StaticFrame childComponent={<Courses />} />
        </Route>
        <Route path="/blog">
          <StaticFrame childComponent={<Blog />} />
        </Route>
        <Route path="/contact">
          <StaticFrame childComponent={<Contact />} />
        </Route>
        <Route path="/privacy-policy">
          <StaticFrame childComponent={<PrivacyPolicy />} />
        </Route>
        <Route path="/terms">
          <StaticFrame childComponent={<TermsUse />} />
        </Route>
        <Route path="/upcoming-announcements">
          <StaticFrame childComponent={<UpcomingAnnouncements />} />
        </Route>
        <Route path="/course/:courseId/">
          <StaticFrame childComponent={<Course />} />
        </Route>
        <Route path="/verify/:verifyId/">
          <Verify />
        </Route>
        <PrivateRoute path="/dashboard">
          <UserFrame childComponent={<Dashboard />} />
        </PrivateRoute>
        <PrivateRoute path="/help">
          <UserFrame childComponent={<Help />} />
        </PrivateRoute>
        <PrivateRoute path="/my-account">
          <UserFrame childComponent={<Profile />} />
        </PrivateRoute>
        <PrivateRoute path="/announcements">
          <UserFrame childComponent={<Announcements />} />
        </PrivateRoute>
        <PrivateRoute path="/enquiries">
          <UserFrame childComponent={<Enquiries />} />
        </PrivateRoute>
        <PrivateRoute path="/enrollments">
          <UserFrame childComponent={<Enrollments />} />
        </PrivateRoute>
        <PrivateRoute path="/my-wallet">
          <UserFrame childComponent={<MyWallet />} />
        </PrivateRoute>
        <PrivateRoute path="/membership">
          <UserFrame childComponent={<Membership />} />
        </PrivateRoute>
        <PrivateRoute path="/notifications">
          <UserFrame childComponent={<Notifications />} />
        </PrivateRoute>
        <PrivateRoute path="/billing">
          <UserFrame childComponent={<Billing />} />
        </PrivateRoute>
        <PrivateRoute path="/settings">
          <UserFrame childComponent={<Settings />} />
        </PrivateRoute>
        <PrivateRoute path="/new-enquiry">
          <UserFrame childComponent={<NewEnquiry />} />
        </PrivateRoute>
        <PrivateRoute path="/skill-set">
          <UserFrame childComponent={<Skills />} />
        </PrivateRoute>
        <PrivateRoute path="/wishlist">
          <UserFrame childComponent={<Skills />} />
        </PrivateRoute>
        <PrivateRoute path="/inbox">
          <UserFrame childComponent={<Inbox />} />
        </PrivateRoute>
        <PrivateRoute path="/new-announcement">
          <UserFrame childComponent={<NewEnquiry />} />
        </PrivateRoute>
        <PrivateRoute path="/enquiry/:enquiryId">
          <UserFrame childComponent={<EnquiryDetail />} />
        </PrivateRoute>
        <PrivateRoute path="/announcement/:enquiryId">
          <UserFrame childComponent={<EnquiryDetail />} />
        </PrivateRoute>
        <PrivateRoute path="/demo/:enquiryId">
          <UserFrame childComponent={<EnquiryDetail />} />
        </PrivateRoute>
        <PrivateRoute path="/browse/:enquiryId">
          <UserFrame childComponent={<BrowseTeachers />} />
        </PrivateRoute>
        <PrivateRoute path="/find-teacher">
          <UserFrame childComponent={<BrowseTeachers />} />
        </PrivateRoute>
        <PrivateRoute path="/find-students">
          <UserFrame childComponent={<FindStudents />} />
        </PrivateRoute>
        <Route path="*">
          <div className="page-not-found">
            <div id="main">
              <div class="fof">
                <h1>Error 404</h1>
                <a href="/" class="nav-link">
                  Back
                </a>
              </div>
            </div>
          </div>
        </Route>
      </Switch>
    </Router>
  );
};

export default Base;
