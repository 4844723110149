import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import $cs from '../services.js';
import { useSelector, useDispatch } from 'react-redux';
import { setProfile } from '../store/userReducer';

const Verify = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { verifyId } = useParams();
    const [loading, setLoading] = useState(true);
    const [action, setAction] = useState(null);

    useEffect(()=>{
        if(loading && action===null) {
            getAction();
        }
    })

    const getAction = () => {
        $cs.action.list({
            resource: `${$cs.action.api}${verifyId}/`,
        }).then(res => {
            decideAction(res.data);
        }, err => {
            setAction(null);
            setLoading(false);
        });
    };

    const decideAction = (action) => {
        switch(action.action) {
            case "SIGNUP_SUCCESS":
                localStorage.setItem('Token', action.data.token);
                localStorage.setItem('profile', JSON.stringify(action.data.user));
                dispatch(
                    setProfile(action.data.user)
                );
                history.push("/my-account");
                break;
            default:
                setAction(action.data);
                setLoading(false);
                console.log('Note implemented');
        }
    }

    if (loading) {
        return (
            <div className="col-12 pad-30">
                <div className="text-center">
                    <span>Validating Please Wait...</span>
                </div>
            </div>
        )
    }

    return (
        <div className="col-12 pad-30">
            {
                action === null?(
                        <div className="text-center">
                            <span>Invalid Link or Expired</span>
                        </div>
                    ):(
                        <div className="text-center">
                            <span>Link verified Successfully</span>
                        </div>
                    )
            }
        </div>
    )
};

export default Verify;
