import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IGInput } from "../../ignitor-react";
import { Link, useHistory } from "react-router-dom";
import $cs from "../../services.js";
import Modal from "react-bootstrap/Modal";

const ResetPassword = (props) => {
  const { mode, handleAction, setPage, id } = props;
  const [error, setError] = useState({});
  const [stage, setStage] = useState('success');
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(true);
  const [status, setStatus] = useState(null);

  const validateLink = () => {
    $cs.action.list({
        resource: `${$cs.action.api}${id}/`
    }).then(res => {
        setStatus('valid_link');
        setStage('reset');
    }, err => {
        setStatus('invalid_link');
        setStage('invalidlink');
    });
  }

  useState(()=>{
    if(id !== null && status === null) {
        validateLink();
    }
  }, [id]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setShow(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const validateLogin = () => {
    let proceed = true;
    let errorObj = {};
    if(!email || email === "") {
        errorObj.email = 'Password is required';
        proceed = false;
    }
    setError(errorObj);
    return proceed;
  }

  const Login = () => {
    if(validateLogin()) {
        $cs.action.update({
            resource: `${$cs.action.api}${id}/`,
            data: {
              password: email
            }
        }).then(res => {
            setStatus('valid_link');
            setStage('success');
        }, err => {
            setStage('invalidlink');
        });
    }
  }

  const renderStage = () => {
    if(stage === 'invalidlink') {
        return <div className='text-center pad-15'>
            <p>Your link is expired or invalid.</p>
        </div>
    } else if(stage === 'success') {
        return <div className='text-center pad-15'>
            <p>Your password is updated successfully. Go back to login</p>
        </div>
    } else if(stage === 'validation') {
        return <div className='text-center pad-15'>
            <img src="/processing.gif" alt="" style={{maxWidth: '40px'}} />
            <p>Please wait. We are validating your request.</p>
        </div>
    } else if(stage === 'reset') {
        return <div className="pad-15">
            <IGInput
                type="password"
                label="Password"
                placeholder="Password"
                value={email}
                error={error.email}
                setValue={(val) => {
                    setEmail(val);
                }}
            />
            <div className="pad-top-15 text-center">
                <button onClick={Login} className="btn btn-yellow w-100 btn-size-theme">Reset Password</button>
            </div>
        </div>
    }
  };

  return (
    <>
      <Modal style={{ marginTop: "140px" }} show={show}>
        {/* // <Card style={{marginTop:"20px", borderRadius:"0.5rem", height:"60%"}}> */}
        <div className="row margin-0">
          <div className="col-12">
            <div className="text-center pad-top-15">
              <img
                src="/logo-ico.png"
                style={{ maxHeight: "50px" }}
                alt=""
                className="img-fluid"
              ></img>
              {/* <img src="/ico-1.png" style={{ maxHeight: '50px' }} alt="" /> */}
              <img
                src="/ic_close.png"
                style={{ width: "20px" }}
                className="float-right"
                alt=""
                onClick={() => {
                  setShow(false);
                  history.push("/");
                }}
              />
              <h4 className="bold txt-theme margin-top-15">
                Reset Password
              </h4>
            </div>
            {renderStage()}
            <div className="text-center pad-top-10 pad-bottom-20">
              <span className='d-block'>Or</span>
              <Link to="/login" className="txt-theme-link">Return to the login page</Link>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ResetPassword;
