import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import $cs from "../../services";

const HomeCourses = (props) => {
  const history = useHistory();

  const [isLoaded, setIsLoaded] = useState(false);
  const [stats, setStats] = useState({
    "Academics": "1+",
    "Certifications": "1+",
    "Fine Arts": "1+",
    "Hobbies": "1+",
    "Information Technology": "1+",
    "Languages": "1+",
    "On Demand Services": "1+",
    "Others": "1+",
    "Personality Development": "1+",
    "Professional": "1+"
  });

  useEffect(()=>{
    if(!isLoaded) {
      getEvents();
    }
  });

  const getEvents = () => {
    $cs.event.list({}).then((res)=>{
      setIsLoaded(true);
      console.log(res.data);
      setStats(res.data);
    });
  };

  return (
    <div className="row">
      <div className="col-12">
        <ul className="learn-anything-slider">
          <li
            onClick={() => {
              history.push(`/upcoming-announcements/?topic=Academics`);
            }}
          >
            <div className="each">
              <div className="image">
                <img src="/learn/image_1.svg" alt="" className="img-fluid" />
              </div>
              <div className="content">
                <div style={{ background: 'linear-gradient(320deg, rgba(9,82,121,0) 27%, rgb(212 21 0) 100%)', padding: '15px', height: '100%' }}>
                  <h3>Academics</h3>
                  <p>Events {stats.Academics || "120+"}</p>
                </div>
              </div>
            </div>
          </li>
          <li
            onClick={() => {
              history.push(`/upcoming-announcements/?topic=Certifications`);
            }}
          >
            <div className="each">
              <div className="image">
                <img src="/learn/image_2.svg" alt="" className="img-fluid" />
              </div>
              <div className="content">
                <div style={{ background: 'linear-gradient(320deg, rgba(9, 82, 121, 0) 27%, #2c53a3 100%)', padding: '15px', height: '100%' }}>
                  <h3>Certifications</h3>
                  <p>Events {stats.Certifications || "120+"}</p>
                </div>
              </div>
            </div>
          </li>
          <li
            onClick={() => {
              history.push(`/upcoming-announcements/?topic=Fine_Arts`);
            }}
          >
            <div className="each">
              <div className="image">
                <img src="/learn/image_4.svg" alt="" className="img-fluid" />
              </div>
              <div className="content">
                <div style={{ background: 'linear-gradient(320deg, rgba(9,82,121,0) 27%, rgb(212 21 0) 100%)', padding: '15px', height: '100%' }}>
                  <h3>Fine Arts</h3>
                  <p>Events {stats["Fine Arts"] || "120+"}</p>
                </div>
              </div>
            </div>
          </li>
          <li
            onClick={() => {
              history.push(`/upcoming-announcements/?topic=Hobbies`);
            }}
          >
            <div className="each">
              <div className="image">
                <img src="/learn/image_5.svg" alt="" className="img-fluid" />
              </div>
              <div className="content">
                <div style={{ background: 'linear-gradient(320deg, rgba(9, 82, 121, 0) 27%, rgb(0 178 57) 100%)', padding: '15px', height: '100%' }}>
                  <h3>Hobbies</h3>
                  <p>Events {stats.Hobbies || "120+"}</p>
                </div>
              </div>
            </div>
          </li>
          <li
            onClick={() => {
              history.push(
                `/upcoming-announcements/?topic=Information_Technology`
              );
            }}
          >
            <div className="each">
              <div className="image">
                <img src="/learn/image_6.svg" alt="" className="img-fluid" />
              </div>
              <div className="content">
                <div style={{ background: 'linear-gradient(320deg, rgba(9, 82, 121, 0) 27%, rgb(9 0 212) 100%)', padding: '15px', height: '100%' }}>
                  <h3>Information Technology</h3>
                  <p>Events {stats['Information Technology'] || "120+"}</p>
                </div>
              </div>
            </div>
          </li>
          <li
            onClick={() => {
              history.push(`/upcoming-announcements/?topic=Languages`);
            }}
          >
            <div className="each">
              <div className="image">
                <img src="/learn/image_7.svg" alt="" className="img-fluid" />
              </div>
              <div className="content">
                <div style={{ background: 'linear-gradient(320deg, rgba(9,82,121,0) 27%, rgb(212 21 0) 100%)', padding: '15px', height: '100%' }}>
                  <h3>Languages</h3>
                  <p>Events {stats.Languages || "120+"}</p>
                </div>
              </div>
            </div>
          </li>
          <li
            onClick={() => {
              history.push(`/upcoming-announcements/?topic=On_Demand_Services`);
            }}
          >
            <div className="each">
              <div className="image">
                <img src="/learn/image_8.svg" alt="" className="img-fluid" />
              </div>
              <div className="content">
                <div style={{ background: 'linear-gradient(320deg, rgba(9, 82, 121, 0) 27%, rgb(255 197 0) 100%)', padding: '15px', height: '100%' }}>
                  <h3>On Demand Services</h3>
                  <p>Events {stats["On Demand Services"] || "120+"}</p>
                </div>
              </div>
            </div>
          </li>
          <li
            onClick={() => {
              history.push(`/upcoming-announcements/?topic=Others`);
            }}
          >
            <div className="each">
              <div className="image">
                <img src="/learn/image_3.svg" alt="" className="img-fluid" />
              </div>
              <div className="content">
                <div style={{ background: 'linear-gradient(320deg, rgba(9, 82, 121, 0) 27%, rgb(0 83 212) 100%)', padding: '15px', height: '100%' }}>
                  <h3>Others</h3>
                  <p>Events {stats["Others"] || "120+"}</p>
                </div>
              </div>
            </div>
          </li>
          <li
            onClick={() => {
              history.push(`/upcoming-announcements/?topic=Personality_Development`);
            }}
          >
            <div className="each">
              <div className="image">
                <img src="/learn/image_9.svg" alt="" className="img-fluid" />
              </div>
              <div className="content">
                <div style={{ background: 'linear-gradient(320deg, rgba(9, 82, 121, 0) 27%, rgb(49 49 49) 100%)', padding: '15px', height: '100%' }}>
                  <h3>Personality Development</h3>
                  <p>Events {stats["Personality Development"] || "120+"}</p>
                </div>
              </div>
            </div>
          </li>
          <li
            onClick={() => {
              history.push(`/upcoming-announcements/?topic=Professional`);
            }}
          >
            <div className="each">
              <div className="image">
                <img src="/learn/image_10.svg" alt="" className="img-fluid" />
              </div>
              <div className="content">
                <div style={{ background: 'linear-gradient(320deg, rgba(9, 82, 121, 0) 27%, rgb(212 0 0) 100%)', padding: '15px', height: '100%' }}>
                  <h3>Professional</h3>
                  <p>Events {stats.Professional || "120+"}</p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HomeCourses;
