import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { Button, Spinner, Container, Row, Col } from 'react-bootstrap';
// import Authenticate from "./Authenticate";
// import InputRating from "./components/InputRating";
import moment from "moment";
import InputCourse from "./components/InputCourse";
import { IGInput } from "../ignitor-react";
import $cs from "../services.js";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { lookupsSelector, appSettingsSelector } from "../store/appReducer";
import {
  realTimeProfileSelector,
  setProfile,
  getProfile,
  getProfiles,
  currentProfileSelector,
  setCurrentProfile,
} from "../store/userReducer";
import EnquiryCard from "./components/EnquiryCard";
import IGModal from "../ignitor-react/IGModal";

const FindStudents = (props) => {
  const dispatch = useDispatch();
  // const valueSelector = useSelector();
  const profile = useSelector(realTimeProfileSelector);
  const lookups = useSelector(lookupsSelector);
  const settings = useSelector(appSettingsSelector);
  const history = useHistory();
  const { enquiryId } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [tab, setTab] = useState("Teacher");
  const [course, setCourse] = useState(null);
  const [teacherList, setTeacherList] = useState(null);
  const [enquiresList, setEnquiriesList] = useState(null);
  const [error, setError] = useState({});
  const [filter, setFilter] = useState({
    rating: null,
    location: null,
    favorites: false,
    mode: null,
    demo: null,
    course: null
  });
  const [popupMode, setPopupMode] = useState(null);

  const appendAction = (action) => {
    switch (action) {
      case "SUCESS_ENQUIRY":
        setPopupMode("SUCESS_ENQUIRY");
        break;
    }
  };

  const getPresetCourses = () => {
    const topic = new URLSearchParams(window.location.search).get("topic");
    if(topic) {
      return [topic];
    }
    return [];
  }

  useEffect(() => {
    document.body.className = "dashboard-page";
    if (isLoading) {
      searchTeachers();
    }
    if (isLoading && course === null && enquiryId) {
      const action = new URLSearchParams(window.location.search).get("action");
      if (action) {
        appendAction(action);
      }
      console.log(action);
      getEnquiryDetail();
    }
  });

  // useEffect(()=>{
  //     if(course !== null && enquiryId) {
  //         searchTeachers();
  //     }
  // }, []);

  useEffect(() => {
    if (course && course !== null) {
      console.log(course);
      if (tab === "Teacher") {
        searchTeachers();
      }
    }
  }, [tab, course]);

  const getEnquiryDetail = () => {
    $cs.enquiry
      .list({
        resource: `${$cs.enquiry.api}${enquiryId}/`,
      })
      .then(
        (res) => {
          setCourse(res.data);
          setLoading(false);
        },
        (err) => {}
      );
  };

  const searchTeachers = (page = null) => {
    $cs.enquiry
      .create({
        resource: page || `${$cs.enquiry.api}find/`,
        data: {
          ...filter,
          course: course?course.id: null
        },
        pageSize: 10,
      })
      .then(
        (res) => {
          setLoading(false);
          setTeacherList(res.data);
        },
        (err) => {}
      );
  };

  const renderTeachers = () => {
    if (teacherList === null) {
      return <span>{enquiryId ? "Loading..." : "Select Topic to Filter"}</span>;
    } else {
      if (teacherList.results && teacherList.results.length > 0) {
        return (
          <div className="responsive-list">
            <ul className="reponsive-enquires-list">
              {teacherList.results.map((item, idx) => (
                <EnquiryCard item={item} key={idx} profile={profile} />
              ))}
            </ul>
            {!isLoading && teacherList !== null && renderPagination()}
          </div>
        );
      } else {
        return <span>No matching results for selected criteria.</span>;
      }
    }
  };

  const renderPagination = () => {
    const dataList = teacherList.results;
    if (dataList.length > 0) {
      return (
        <div className="row margin-0">
          <div className="col text-center">
            {teacherList.previous !== null && (
              <span
                className="txt-theme margin-right-10 c-pointer"
                onClick={() => {
                  searchTeachers(teacherList.previous);
                }}
              >
                Prev
              </span>
            )}
            {teacherList.next !== null && (
              <span
                className="txt-theme c-pointer"
                onClick={() => {
                  searchTeachers(teacherList.next);
                }}
              >
                Next
              </span>
            )}
          </div>
        </div>
      );
    } else {
      return;
    }
  };

  const renderFilterBlock = () => {
    return (
      <div className="find-teacher">
        <h3 className="title">Find Students</h3>
        {enquiryId && !isLoading && (
          <h5 className="bold margin-0">
            <small>
              Topic: <br />
            </small>
            {course.course.title} <small>({course.enquiry_id})</small>
          </h5>
        )}
        <div className="input-element">
          {!enquiryId && (
            <InputCourse
              value={course}
              preSelections={getPresetCourses()}
              setValue={(c) => {
                setCourse(c);
              }}
              mode={"INPUT"}
            />
          )}
        </div>
        <div className="filters-group">
          <ul>
            <li>
              <IGInput
                type="select"
                label={
                  lookups && lookups.ENQUIRY_MODE
                    ? lookups.ENQUIRY_MODE.description || "Teaching Mode"
                    : "Teaching Mode"
                }
                placeholder={"Teaching Mode"}
                value={filter.mode}
                className="margin-0"
                options={
                  lookups && lookups.ENQUIRY_MODE
                    ? lookups.ENQUIRY_MODE.codes.map((item, index) => [
                        item.code,
                        item.description,
                      ])
                    : []
                }
                setValue={(val) => {
                  setFilter({
                    ...filter,
                    mode: val,
                  });
                }}
              />
            </li>
            {enquiryId && !isLoading && (
              <li>
                <IGInput
                  type="select"
                  label={"Filter by Best Ratings"}
                  placeholder={"Filter by Best Ratings"}
                  value={filter.rating}
                  className="margin-0"
                  options={
                    lookups && lookups.TEACHER_RATING_VALUES
                      ? [
                          ["all", "Overall Ratings"],
                          ...lookups.TEACHER_RATING_VALUES.codes.map(
                            (item, index) => [item.code, item.description]
                          ),
                        ]
                      : ["all", "Overall Ratings"]
                  }
                  setValue={(val) => {
                    setFilter({
                      ...filter,
                      rating: val,
                    });
                  }}
                />
              </li>
            )}
            {filter.location && (
              <li>
                <IGInput
                  type="location"
                  label="Location"
                  placeholder="Location"
                  value={filter.location}
                  setValue={(val) => {
                    setFilter({
                      ...filter,
                      location: val,
                    });
                  }}
                />
              </li>
            )}
          </ul>
        </div>
        <div className="view-favorites mt-2">
          <input
            type="checkbox"
            name=""
            id=""
            checked={filter.location}
            onChange={(e) => {
              if (e.target.checked) {
                setFilter({
                  ...filter,
                  location: profile.address,
                });
              } else {
                setFilter({
                  ...filter,
                  location: null,
                });
              }
            }}
          />{" "}
          Near My Location (shows results within{" "}
          {settings && settings.FIND_TEACHER_DISTANCE
            ? settings.FIND_TEACHER_DISTANCE.value || "100"
            : "100"}{" "}
          kms radius)
        </div>
        <div className="action-buttons">
          <ul>
            <li>
              <button
                className="find-button"
                onClick={() => {
                  searchTeachers();
                }}
              >
                {" "}
                <span className="fa fa-search"></span> Find
              </button>
            </li>
            <li>
              <button
                className="reset-button"
                onClick={() => {
                  setFilter({
                    rating: null,
                    location: null,
                    favorites: false,
                    mode: null,
                    demo: null,
                  });
                }}
              >
                Reset
                <span className="fa fa-close"></span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderPopupBody = () => {
    return (
      <div className="row margin-0">
        <div className="col-12 text-center">
          <span
            className="fa fa-check-circle text-success margin-bottom-20"
            style={{ fontSize: "4rem" }}
          ></span>
          <h4 className="txt-theme">Enquiry Created Successfully</h4>
          <button
            className="btn btn-sm btn-yellow margin-top-10"
            onClick={() => {
              setPopupMode(null);
            }}
          >
            Start Browsing Teachers
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="row margin-0 browse-teachers">
      <div className="col-12 pad-0">
        <div className="find-teach-card border-card pad-30 margin-bottom-30 margin-top-20">
          {renderFilterBlock()}
        </div>
      </div>
      <div className="col-12 pad-0">
        <div className="shadow-theme">
          <div className="pad-15">
            <div className="row margin-0">
              <div className="col-12 pad-0">
                {tab === "Teacher" && renderTeachers()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <IGModal
        visible={popupMode !== null}
        body={renderPopupBody}
        close={() => setPopupMode(null)}
        isLogin={false}
      />
    </div>
  );
};

export default FindStudents;
