import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import $cs from '../services.js'
import { Link, useHistory } from "react-router-dom";
import InputCourse from "./components/InputCourse";
import Modal from 'react-bootstrap/Modal';
import { realTimeProfileSelector, profilesSelector, skillsSelector, getProfiles, setProfile, userTypeSelector, getEnquires, enquiriesSelector } from '../store/userReducer';

const Skills = (props) => {
    const dispatch = useDispatch();
    const profile = useSelector(realTimeProfileSelector);
    const profiles = useSelector(profilesSelector);
    const skills = useSelector(skillsSelector);
    const [popupMode, setPopupMode] = useState(null);
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [isOpen, setIsOpen] = useState(true);
    const [error, setError] = useState({});
    const [cb, setCB] = useState(null);
    const [evente, setEvente] = useState('reset');
    const [payload, setPayload] = useState({
        action: 'addbulk',
        courses: []
    });

    useEffect(() => {
        if(skills === null) {
            dispatch(getProfiles());
        }
    });

    const deleteSkill = () => {
        $cs.profiles.create({
            resource: `${$cs.profiles.api}${profiles.results[0].id}/updatecourse/`,
            data: {
                action: 'remove',
                course: selectedSkill.id
            }
        }).then(res => {
            setPopupMode(null);
            dispatch(getProfiles());
        }, err => {
            
        });
    }

    const addSkill = () => {
        $cs.profiles.create({
            resource: `${$cs.profiles.api}${profiles.results[0].id}/updatecourse/`,
            data: payload
        }).then(res => {
            if(cb) {
                cb();
            }
            setPayload({
                ...payload,
                courses: []
            });
            setEvente('reset');
            dispatch(getProfiles());
        }, err => {
            
        });
    }

    const renderSkills = () => {
        if(skills === null) {
            return (
                <tr>
                    <td colSpan="3" className='text-center'>
                        Loading...
                    </td>
                </tr>
            )
        } else {
            if(skills.length > 0) {
                return skills.map((item, index) => (
                    <tr key={index}>
                        <td>{item.parent_detail?item.parent_detail.topic+' - ':''} {item.title||'-'}</td>
                        {/* <td>{item.sub_category?item.sub_category.title:'-'}</td> */}
                        <td>
                            {
                                skills.length > 1 && (
                                    <span className="fa fa-trash c-pointer" onClick={()=>{
                                        setSelectedSkill(item);
                                        setPopupMode('delete');
                                    }}></span>
                                )
                            }
                            </td>
                    </tr>
                ))
            } else {
                return (
                    <tr>
                        <td colSpan="3" className="text-center">No courses added</td>
                    </tr>
                )
            }
        }
    }

    const getErrorMessage = (field) => {
        if(error && error[field]) {
            return (
                <span className="error">{error[field]}</span>
            )
        }
        return '';
    };

    return (
        <div className="row margin-0">
            <div className="col-12 pad-0">
                {
                    isOpen && profile.status === 'ACTIVE' && <div className="banner-block pad-20 margin-bottom-15">
                        <div className="row">
                            <div className="col-6">
                                <label className='txt-theme bold margin-0'>Add More Skills</label>
                            </div>
                            <div className="col-6 text-right">
                                <Link className="txt-theme" style={{textDecoration: 'underline', fontSize: '14px'}} to="/inbox?action=ADD_NEW_COURSE">Request to add Course</Link>
                            </div>
                            <div className="col-12 pad-bottom-10">
                                {
                                    profile.user_type === 'TEACHER'?<span className='d-block pad-bottom-10'>Select the courses to get enquires from students.</span>:<span className='d-block pad-bottom-10'>Select the courses to get training announcements from teachers. To get a phone call post enquiry.</span>
                                }
                                <InputCourse value={payload.skill} mode={'tags'} skipCourse={profiles && profiles.results?profiles.results[0].courses:[]} evente={evente} setValue={(c)=>{
                                    setPayload({
                                        ...payload,
                                        courses: c
                                    });
                                    setEvente('running');
                                }} />
                                {getErrorMessage('course')}
                            </div>
                            <div className="col-12 text-center">
                                <button className="btn btn-yellow btn-sm" onClick={()=>{
                                    addSkill()
                                }}>Add</button>
                                <button className="btn btn-plain btn-sm margin-left-10" onClick={()=>{
                                    setPayload({
                                        action: 'addbulk',
                                        courses: []
                                    });
                                    setEvente('reset');
                                }}>Clear</button>
                            </div>
                        </div>
                    </div>
                }
                <div className="banner-block pad-20">
                    <h4 className="txt-theme bold">{profile.user_type==='STUDENT'?'Wishlist':'Skill Set'} 
                    {/* <button className="btn btn-sm btn-theme f-right" onClick={()=>{
                        setIsOpen(true);
                    }}>+ Add</button> */}
                    </h4>
                    <span className="text-secondary"></span>
                    <div className="table-responsive table-theme">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Course</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderSkills()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div>
                <Modal
                    show={popupMode !== null}
                    onHide={() => { setPopupMode(null) }}
                >
                    <Modal.Body>
                        <div className="row margin-0">
                            {
                                popupMode === 'delete' && (
                                    <div className="col-12 pad-0 text-center">
                                        <h4>Are You Sure? You want to delete the course <b>{selectedSkill?selectedSkill.title:''}</b> from {profile.user_type==='STUDENT'?'Wishlist':'Skill Set'}?</h4>
                                        <div className="text-center pad-10">
                                            <button className="btn btn-theme btn-sm margin-right-5" onClick={() => { deleteSkill() }}>Delete</button>
                                            <button className="btn btn-plain btn-sm" onClick={() => { setPopupMode(null) }}>Cancel</button>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
};

export default Skills;
