import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import $cs from "../../services.js";
import moment from "moment";
import {
  realTimeProfileSelector,
  setProfile,
  userTypeSelector,
  enquiriesSelector,
} from "../../store/userReducer";

const EnquiryCard = (props) => {
  const { item, profile } = props;
  const history = useHistory();

  const renderParticipantsCount = (item) => {
    return (
      <div className="info-block pad-top-15">
        <span className="text-right d-block">
          {item.total_enrollments} views
        </span>
        <div className="gt-progess-bar">
          <div
            className="gt-progress-bar-process"
            style={{ width: `${item.total_enrollments / item.unlocks}%` }}
          ></div>
        </div>
      </div>
    );
  };

  return (
    <li className="c-pointer">
      <div className="row margin-0">
        <div
          className="col-sm-12 col-md-7 col-lg-7 pad-10"
          onClick={() => {
            history.push(`/enquiry/${item.id}`);
          }}
        >
          <h5 className="margin-bottom-0">
            {item.course.parent_detail ? item.course.parent_detail.topic + " - " : ""}
            {item.course ? item.course.title : "-"}{" "}
            <small> ({item.enquiry_id})</small>
          </h5>
          <span>
            {item.form
              ? item.form.location
                ? item.form.location.name || item.form.location
                : "-"
              : "-"}
          </span>
        </div>
        <div className="col-sm-2 col-md-2 col-lg-2 pad-0 mb-hide">{renderParticipantsCount(item)}</div>
        <div
          className="col max-fit-content"
          onClick={() => {
            history.push(`/enquiry/${item.id}`);
          }}
        >
          <div className="info-block">
            <label htmlFor="">Posted On</label>
            <p>{moment(item.created_on).format("DD MMM, YYYY") || "-"}</p>
          </div>
        </div>
        <div className="col pad-left-10 max-fit-content">
          <div className="info-block">
            <label htmlFor="">Respond By</label>
            <p>{moment(item.archived_on).format("DD MMM, YYYY") || "-"}</p>
          </div>
        </div>
        <div className="col-12 pad-0" style={{ borderTop: "1px solid #eee" }}>
          <div className="row margin-0 enquiry-card-mb">
            <div
              className="col-md-3 pad-left-10"
              onClick={() => {
                history.push(`/enquiry/${item.id}`);
              }}
            >
              <div className="info-snip">
                <label htmlFor="">Mode</label>
                <p>{item.form && item.form.mode ? item.form.mode : "-"}</p>
              </div>
            </div>
            <div
              className="col-md-3 pad-left-10"
              onClick={() => {
                history.push(`/enquiry/${item.id}`);
              }}
            >
              <div className="info-snip">
                <label htmlFor="">Frequency</label>
                <p>
                  {item.form && item.form.frequency ? item.form.frequency : "-"}
                </p>
              </div>
            </div>
            <div
              className="col-md-3 pad-left-10"
              onClick={() => {
                history.push(`/enquiry/${item.id}`);
              }}
            >
              <div className="info-snip">
                <label htmlFor="">Language</label>
                <p>
                  {item.form && item.form.language ? item.form.language : "-"}
                </p>
              </div>
            </div>
            <div
              className="col-md-3 pad-left-10"
              onClick={() => {
                history.push(`/enquiry/${item.id}`);
              }}
            >
              <div className="info-snip">
                <label htmlFor="">#Students</label>
                <p>
                  {item.form && item.form.student_count
                    ? item.form.student_count
                    : "1"}
                </p>
              </div>
            </div>
            <div className="col-md-3 pad-left-10">
              <div className="info-snip">
                <label htmlFor="">Estimated Fee</label>
                <p>
                  {item.owner_details
                    ? item.owner_details.region || "USD"
                    : "USD"}{" "}
                  {item.form && item.form.fee ? item.form.fee : "10"} -{" "}
                  {item.form && item.form.fee_type ? item.form.fee_type : "-"} (
                  {item.form && item.form.fee_negotiable
                    ? item.form.fee_negotiable
                    : "-"}
                  )
                </p>
              </div>
            </div>
            {item.form && item.form.is_urgent && (
              <div
                className="col-md-3 pad-left-10"
                onClick={() => {
                  history.push(`/enquiry/${item.id}`);
                }}
              >
                <div className="info-snip pad-top-15">
                  <p className="text-danger">It's an Urgent Enquiry</p>
                </div>
              </div>
            )}
            <div className="col text-right pad-top-10 mb-hide">
              <button
                className="btn btn-sm btn-theme txt-theme"
                onClick={() => {
                  history.push(`/enquiry/${item.id}`);
                }}
              >
                View
              </button>
              {profile.user_type === "STUDENT" && (
                <button
                  className="btn btn-sm btn-danger margin-left-10"
                  onClick={() => {
                    history.push(`/browse/${item.id}`);
                  }}
                >
                  {" "}
                  <span className="fa fa-search"></span> Teachers
                </button>
              )}
            </div>
            <div className="col text-right pad-top-10 mb-visible">
              <button
                className="btn btn-sm btn-theme txt-theme"
                onClick={() => {
                  history.push(`/enquiry/${item.id}`);
                }}
              >
                View
              </button>
              {profile.user_type === "STUDENT" && (
                <button
                  className="btn btn-sm btn-danger margin-left-10"
                  onClick={() => {
                    history.push(`/browse/${item.id}`);
                  }}
                >
                  {" "}
                  <span className="fa fa-search"></span> Teachers
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default EnquiryCard;
